import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';

import * as serviceWorker from './serviceWorker';
import configureAmplify from './services/aws/AmplifyInitialisation';

(async () => {
  // setup amplify for usage
  await configureAmplify(
    process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
  );

  ReactDOM.render(<App />, document.getElementById('root'));
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
