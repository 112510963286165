import { Dispatch } from 'redux';
import { Auth } from 'aws-amplify';
import { message } from 'antd';
import { aggregate } from '../index';
import { ACCOUNT } from './types';
import { LooseObj } from '../../custom-types';
import UserService from '../../services/user/UserService';
import TenantService from '../../services/tenant/TenantService';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import BillingService from '../../services/billing/BillingService';

export const updateActiveAccount = (
  account: LooseObj,
  cb: Function = () => ({}),
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(ACCOUNT.UPDATE_ACTIVE, account));

    if (account) {
      LocalStorageService.setActiveAccount(account.tenantInfo.tenant_id);
      cb();
    }
  } catch (err) {
    console.error(err);
  }
};

export const putAccountDetails = (
  data: LooseObj,
  options: { showFeedback: boolean },
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(ACCOUNT.UPDATE_DETAILS));
    const putAccountDetailsResponse = await BillingService.putAccountDetails(
      data,
    );

    if (putAccountDetailsResponse) {
      dispatch(aggregate(ACCOUNT.UPDATE_DETAILS_SUCCESS, data));

      if (options.showFeedback) {
        message.success('Address updated successfully');
      }
    } else {
      dispatch(aggregate(ACCOUNT.ERROR, putAccountDetailsResponse));

      if (options.showFeedback) {
        message.success('Failed to update address');
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const setupUserProfiles = (
  identityInfoOptional?: LooseObj,
  userProfiles?: Array<any>,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(ACCOUNT.PROCESSING, identityInfoOptional));

    let cognitoUser;
    let identityInfo;

    if (!identityInfoOptional) {
      cognitoUser = await Auth.currentAuthenticatedUser();

      identityInfo = await UserService.getIdentityInfo(
        cognitoUser.attributes.email,
      );
    } else {
      identityInfo = identityInfoOptional;
    }

    if (userProfiles) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      identityInfo.user_profiles = userProfiles;
    }

    let accounts: any = await Promise.all(
      identityInfo.user_profiles.map((userProfileData: LooseObj) => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        const { tenant_id, user_id } = userProfileData;

        return new Promise((resolve, reject) => {
          Promise.all([
            UserService.getUserInfo(user_id, tenant_id),
            TenantService.getTenantInfo(tenant_id, true, true),
          ])
            .then((result) => {
              resolve({
                userInfo: result[0],
                tenantInfo: result[1].tenant,
                accountInfo: result[1].account,
              });
            })
            .catch((err) => {
              console.error(err);
              reject(err.message);
            });
        });
      }),
    );

    accounts = accounts.filter((mapping: any) => {
      if (mapping.tenantInfo && mapping.accountInfo) {
        return true;
      }
      console.warn(
        `tenant or account info  for ${mapping.tenantInfo.tenant_id
          || mapping.accountInfo.id} is missing, it is going to be ignored`,
      );
      return false;
    });

    const accountsProfiles = accounts.reduce((acc: any, profile: any) => {
      acc[profile.tenantInfo.tenant_id] = profile;
      return acc;
    }, {});

    dispatch(aggregate(ACCOUNT.SETUP_PROFILES, accountsProfiles));

    const activeAccountId = LocalStorageService.getActiveAccount(
      accountsProfiles,
    );

    await updateActiveAccount(accountsProfiles[activeAccountId])(dispatch);
  } catch (err) {
    console.error(err);
  }
};

export const modifyTenant = (
  newTenantData: LooseObj,
  cb: Function,
  isPlatformAdmin = false,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(ACCOUNT.PROCESSING));
    const resp = await TenantService.modifyTenant(newTenantData);

    if (resp.success) {
      const { newName: tenant_name, tenantId: tenant_id } = newTenantData;
      dispatch(
        aggregate(ACCOUNT.MODIFY_TENANT_NAME, {
          tenant_name,
          tenant_id,
          isPlatformAdmin,
        }),
      );
      cb({ success: true });
    } else {
      dispatch(aggregate(ACCOUNT.ERROR, resp));
      cb({ success: false });
    }
  } catch (err) {
    console.error(err);
  }
};

export const modifyUser = (
  userData: LooseObj,
  isPlatformAdmin = false,
  cb: Function,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(ACCOUNT.PROCESSING));
    const resp = await TenantService.modifyUser(userData);

    if (resp.success) {
      const { userId, newRole } = userData;

      dispatch(
        aggregate(ACCOUNT.MODIFY_USER_ROLE, {
          userId,
          newRole,
          isPlatformAdmin,
        }),
      );
      cb({ success: true });
    } else {
      dispatch(aggregate(ACCOUNT.ERROR, resp));
      cb({ success: false });
    }
  } catch (err) {
    console.error(err);
  }
};

export const deleteUser = (deleteUserData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(ACCOUNT.PROCESSING));
    const resp = await TenantService.deleteUser(deleteUserData);

    if (resp.success) {
      const { userId: id, tenantId: tenant_id } = deleteUserData;
      dispatch(aggregate(ACCOUNT.DELETE_USER, { id, tenant_id }));
    } else {
      dispatch(aggregate(ACCOUNT.ERROR, resp));
    }
    cb(resp);
  } catch (err) {
    console.error(err);
  }
};

export const leaveAccount = (deleteUserData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(ACCOUNT.PROCESSING));
    const resp = await TenantService.deleteUser(deleteUserData);

    if (resp.success) {
      const { userId: id, tenantId: tenant_id } = deleteUserData;
      dispatch(aggregate(ACCOUNT.LEAVE_ACCOUNT, { id, tenant_id }));
    } else {
      dispatch(aggregate(ACCOUNT.ERROR, resp));
    }
    cb(resp);
  } catch (err) {
    console.error(err);
  }
};
