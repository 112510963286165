const defaultRegion = 'eu-central-1';

const getStagedURL = (url: string, stage: string) => `${url}${stage}/`;

const PROD = 'prod';
const DEV = 'dev';

export const API_GATEWAYS = {
  STAGE: 'stage-api',
  BILLING: 'billing-api',
};

const config = {
  [DEV]: {
    signInUrl:
      'https://wqc15wkw5e.execute-api.eu-central-1.amazonaws.com/dev/api/user/auth/getsignin',
    region: defaultRegion,
    apiGateways: [
      {
        name: API_GATEWAYS.STAGE,
        region: defaultRegion,
        url: getStagedURL(
          'https://wqc15wkw5e.execute-api.eu-central-1.amazonaws.com/',
          DEV,
        ),
      },
      {
        name: API_GATEWAYS.BILLING,
        region: defaultRegion,
        url: getStagedURL(
          'https://jcgztkjbd8.execute-api.eu-central-1.amazonaws.com/',
          DEV,
        ),
      },
    ],
  },
  [PROD]: {
    signInUrl:
      'https://b6vlacsrw7.execute-api.eu-central-1.amazonaws.com/prod/api/user/auth/getsignin',
    region: defaultRegion,
    apiGateways: [
      {
        name: API_GATEWAYS.STAGE,
        region: defaultRegion,
        url: getStagedURL(
          'https://b6vlacsrw7.execute-api.eu-central-1.amazonaws.com/',
          PROD,
        ),
      },
      {
        name: API_GATEWAYS.BILLING,
        region: defaultRegion,
        url: getStagedURL(
          'https://rzgchbmi3c.execute-api.eu-central-1.amazonaws.com/',
          PROD,
        ),
      },
    ],
  },
};

export default config;
