import { Auth, API } from 'aws-amplify';
import { LooseObj } from '../../custom-types';
import { API_GATEWAYS } from '../aws/config';
import TenantService from '../tenant/TenantService';

class UserService {
  static async authenticate(email: string, password: string): Promise<any> {
    try {
      return await Auth.signIn(email, password);
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async deauthenticate() {
    return Auth.signOut();
  }

  static async register(userData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/user/register', {
        body: userData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async resetPassword({
    email,
    confirmationCode,
    newPassword,
  }: LooseObj): Promise<any> {
    try {
      let user;
      try {
        user = await Auth.signIn(email, confirmationCode);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return await Auth.completeNewPassword(user, newPassword, {});
        }
      } catch (e) {
        if (e.code === 'NotAuthorizedException') {
          await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
          throw new Error('authenticationRequired');
        }
      }

      if (!user) {
        throw new Error('Something went wrong.');
      }

      return user;
    } catch (err) {
      if (err.message === 'authenticationRequired') {
        throw new Error('authenticationRequired');
      }
      console.error(err);
      return err;
    }
  }

  static async getIdentityInfo(email: string): Promise<any> {
    try {
      return API.get(API_GATEWAYS.STAGE, `api/user/identity/${email}`, {});
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  /**
   * Create initial user and account
   * @param userData
   */
  static async createAccount(userData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/admin/initial', {
        body: userData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async sendInvites(inviteData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/admin/inviteUsers', {
        body: inviteData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getUserInfo(userId: string, tenantId: string): Promise<any> {
    try {
      const userInfo = await API.get(
        API_GATEWAYS.STAGE,
        `api/user/info/${userId}/${tenantId}`,
        {},
      );
      return { ...userInfo, user_id: userId };
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async setDisplayName(userData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/user/createUser', {
        body: userData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async checkAuthentication() {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      return false;
    }
  }

  static async updateIdentity(newIdentityData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/user/updateIdentity', {
        body: newIdentityData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<any> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(user, oldPassword, newPassword);
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async handleAcknowledgments(registerData: LooseObj) {
    const acknowledgmentsData = {
      email: registerData.email,
      newAcknowledgments: registerData.acknowledgments,
    };

    const updateAcknowledgments = await UserService.updateIdentity(
      acknowledgmentsData,
    );

    if (!updateAcknowledgments.success) {
      return { success: false, data: acknowledgmentsData };
    }

    return { success: true };
  }

  static async handleInvites(inviteData: LooseObj) {
    const shouldSendInvites = inviteData.invitations && inviteData.invitations.length > 0;

    if (shouldSendInvites) {
      const invites = await UserService.sendInvites(inviteData);

      if (!invites.success) {
        return { success: false, data: invites };
      }
    }

    return { success: true };
  }

  static async getNews() {
    try {
      return API.get(API_GATEWAYS.STAGE, 'api/user/getNews', {});
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getCountries() {
    try {
      return API.get(API_GATEWAYS.STAGE, 'api/user/getCountries', {});
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getMeta(email: string) {
    try {
      return API.get(API_GATEWAYS.STAGE, `api/application/meta?e=${email}`, {});
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getInvitations(email: string) {
    try {
      const invitesResp = await API.get(
        API_GATEWAYS.STAGE,
        `api/user/invites/${encodeURIComponent(email)}`,
        {},
      );

      const promises = invitesResp.invitations.map(
        async (inviteData: LooseObj) => {
          const { tenantId } = inviteData;
          const { tenant } = await TenantService.getTenantInfo(
            tenantId.toString(),
            false,
          );
          return { ...inviteData, tenantName: tenant.tenant_name };
        },
      );

      const data = await Promise.all(promises);

      return {
        success: true,
        invitations: data,
      };
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async deleteInvitation(tenantId: string, email: string) {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/user/deleteInvitation', {
        body: { tenantId, email },
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async deleteIdentity(email: string, tenantIdsWhereOwner: number[]) {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/user/deleteIdentity', {
        body: { email, tenantIdsWhereOwner },
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }
}

export default UserService;
