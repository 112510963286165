import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Tabs, Table, Input, Icon, Popconfirm,
} from 'antd';
import uuid from 'uuid/v4';
import { escapeRegExp } from 'lodash';
import { connect } from 'react-redux';
import { styled } from '../../theme/Theme';
import { LooseObj } from '../../custom-types';
import { AppState } from '../../store';
import {
  getAllTenantsInfo,
  getAllIdentitiesInfo,
  deleteIdentity,
} from '../../store/platform-admin/action';
import { PlatformAdminState } from '../../store/platform-admin/types';

const { TabPane } = Tabs;

interface DispatchProps {
  platformAdminState: PlatformAdminState;
  onGetAllTenantsInfo: () => {};
  onGetAllIdentitiesInfo: () => {};
  onDeleteIdentity: Function;
}

interface Props extends DispatchProps {
  className?: string;
}

// const operations = <Link to="">Add account & members</Link>;
const operations = undefined;

const renderTenants = (tenants: LooseObj) => tenants.map((tenant: LooseObj) => (
  <Link
    key={uuid()}
    className="tenant-link"
    to={`/platform-admin/account?i=${tenant.tenant_id}`}
  >
    {tenant.tenant_name}
  </Link>
));

const renderFullName = (
  identity_first_name: string,
  { identity_last_name }: LooseObj,
) => `${identity_first_name} ${identity_last_name}`;

// const renderEditUser = (email: string) => (
//   <Link to={`/platform-admin/user?e=${encodeURIComponent(email)}`}>Edit</Link>
// );

const renderEditAccount = (tenantId: string) => (
  <Link to={`/platform-admin/account?i=${encodeURIComponent(tenantId)}`}>
    Edit
  </Link>
);

const accountsColumns = [
  {
    title: 'Name',
    dataIndex: 'tenant_name',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Owner',
    dataIndex: 'administrator_contact',
    key: 'owner',
    width: '30%',
  },
  {
    title: 'Active license',
    dataIndex: 'address',
    key: 'license',
  },
  {
    title: 'Members',
    dataIndex: 'user_profiles_count',
    key: 'members',
  },
  {
    title: 'Action',
    dataIndex: 'tenant_id',
    key: 'action',
    render: renderEditAccount,
  },
];

const ManageAccountsUsers: React.FC<Props> = ({
  className,
  platformAdminState,
  onGetAllTenantsInfo,
  onGetAllIdentitiesInfo,
  onDeleteIdentity,
}) => {
  const { tenantsInfo, identitiesInfo } = platformAdminState;

  const [filteredTenantsInfo, setFilteredTenantsInfo] = useState<
    LooseObj[] | undefined
  >(undefined);

  const [filteredIdentitiesInfo, setFilteredIdentitiesInfo] = useState<
    LooseObj[] | undefined
  >(undefined);

  const [searchFieldValue, setSearchFieldValue] = useState('');

  useEffect(() => {
    onGetAllTenantsInfo();
    onGetAllIdentitiesInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tenantsInfo) {
      setFilteredTenantsInfo(tenantsInfo);
    }
  }, [tenantsInfo]);

  useEffect(() => {
    if (identitiesInfo) {
      setFilteredIdentitiesInfo(identitiesInfo);
    }
  }, [identitiesInfo]);

  const handleInputFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchFieldValue(event.target.value);
  };

  const searchUsers = (event: any) => {
    const value = escapeRegExp(searchFieldValue);
    const regexp = new RegExp(value, 'i');
    let matches: LooseObj[] = [];

    if (identitiesInfo) {
      matches = identitiesInfo.filter(
        // eslint-disable-next-line @typescript-eslint/camelcase,max-len
        ({ identity_first_name, identity_last_name, identity_email }) => identity_first_name.match(regexp)
          // eslint-disable-next-line @typescript-eslint/camelcase
          || identity_last_name.match(regexp)
          || identity_email.match(regexp),
      );
      setFilteredIdentitiesInfo(matches);
    }
  };

  const searchTenants = (event: any) => {
    const value = escapeRegExp(searchFieldValue);
    const regexp = new RegExp(value, 'i');
    let matches: LooseObj[] = [];

    if (tenantsInfo) {
      matches = tenantsInfo.filter(
        (tenant) => tenant.tenant_name.match(regexp)
          || tenant.administrator_contact.match(regexp),
      );
      setFilteredTenantsInfo(matches);
    }
  };

  const onDeleteIdentityClicked = async (email: string) => {
    const identityInfo = identitiesInfo
      && identitiesInfo.filter((identity) => identity.identity_email === email)[0];

    const tenantIdsWhereMember = identityInfo
      && identityInfo.tenants.map((tenantInfo: LooseObj) => tenantInfo.tenant_id);

    const tenantIdsWhereOwner = tenantIdsWhereMember.filter(
      (tenantId: string | number) => {
        if (tenantsInfo) {
          const tenantInfo = tenantsInfo.filter(
            (tenant) => tenant.tenant_id == tenantId,
          )[0];
          return tenantInfo.administrator_contact === email;
        }

        return false;
      },
    );

    onDeleteIdentity(email, tenantIdsWhereOwner);
  };

  const renderDeleteUser = (email: string) => (
    <Popconfirm
      title={`Are you sure you want to delete the identity of ${email}?`}
      okText="Yes"
      okType="danger"
      cancelText="No"
      onConfirm={() => onDeleteIdentityClicked(email)}
    >
      <span className="delete-button">Delete</span>
    </Popconfirm>
  );

  const usersColumns = [
    {
      title: 'Name',
      dataIndex: 'identity_first_name',
      key: 'name',
      width: '30%',
      render: renderFullName,
    },
    {
      title: 'Tenants',
      dataIndex: 'tenants',
      key: 'tenants',
      width: '30%',
      render: renderTenants,
    },
    {
      title: 'Email address',
      dataIndex: 'identity_email',
      key: 'email',
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'identity_email',
    //   key: 'action',
    //   render: renderEditUser,
    // },
    {
      title: 'Action',
      dataIndex: 'identity_email',
      key: 'action',
      render: renderDeleteUser,
    },
  ];

  return (
    <Tabs tabBarExtraContent={operations} className={className}>
      <TabPane tab="Users" key="1">
        <Input
          className="search"
          placeholder="Search"
          prefix={<Icon type="search" />}
          onChange={handleInputFieldChange}
          onPressEnter={searchUsers}
        />
        <Table
          className="users-table"
          dataSource={filteredIdentitiesInfo}
          rowKey={(record) => record.identity_email}
          columns={usersColumns}
          loading={filteredIdentitiesInfo === undefined}
          bordered
        />
      </TabPane>
      <TabPane tab="Accounts" key="2">
        <Input
          className="search"
          placeholder="Search"
          prefix={<Icon type="search" />}
          onChange={handleInputFieldChange}
          onPressEnter={searchTenants}
        />
        <Table
          className="accounts-table"
          dataSource={filteredTenantsInfo}
          rowKey={(record) => record.tenant_id}
          columns={accountsColumns}
          loading={filteredTenantsInfo === undefined}
          bordered
        />
      </TabPane>
    </Tabs>
  );
};

const StyledManageAccountsUsers = styled(ManageAccountsUsers)`
  .ant-tabs-extra-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 300px;
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 5px;

    a {
      font-weight: bold;
      color: ${({ theme }) => theme.color.white};
    }
  }

  .ant-tabs-top-bar {
    margin-bottom: 0;
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      margin-right: 0;
      background-color: ${({ theme }) => theme.color.inactive};
      border: 1px solid ${({ theme }) => theme.color.borderLight};
      border-radius: 5px;

      &-active {
        background-color: ${({ theme }) => theme.color.white};
      }
    }
  }

  .ant-table-wrapper {
    .ant-table {
      .ant-table-body {
        margin: 0 !important;
      }

      thead {
        th {
          background: transparent;
        }

        span {
          font-weight: 600;
          font-size: 0.9rem;
        }
      }

      tbody {
        background-color: ${({ theme }) => theme.color.white};
      }
    }

    .ant-pagination-item-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .users-table {
    .tenant-link {
      text-decoration: underline;
      color: inherit;

      :after {
        content: ",";
        margin-right: 7px;
      }

      &:last-of-type {
        :after {
          content: "";
        }
      }
    }
  }

  .search {
    height: 50px;

    .ant-input {
      height: 50px;
    }
  }
  .delete-button {
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
  }
`;

const mapStateToProps = (state: AppState) => ({
  platformAdminState: state.platformAdmin,
});

const mapDispatchToProps = {
  onGetAllTenantsInfo: getAllTenantsInfo,
  onGetAllIdentitiesInfo: getAllIdentitiesInfo,
  onDeleteIdentity: deleteIdentity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledManageAccountsUsers);
