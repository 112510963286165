import { BILLING, BillingState } from './types';
import { ActionResponse } from '../index';

const DEFAULT_STATE: BillingState = {
  token: undefined,
  client: undefined,

  processing: false,
  success: false,
  error: undefined,
};

export default (state = DEFAULT_STATE, { type, payload }: ActionResponse) => {
  switch (type) {
    case BILLING.SET_TOKEN:
      return { ...state, processing: false, token: payload };
    case BILLING.SET_CLIENT:
      return { ...state, processing: false, client: payload };
    case BILLING.PROCESSING:
      return { ...state, processing: true, error: undefined };
    case BILLING.CLEAR:
      return DEFAULT_STATE;
    case BILLING.ERROR:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    case BILLING.BUY:
      return { ...state, processing: false, error: undefined };
    case BILLING.UPGRADE:
      return { ...state, processing: false, error: undefined };
    case BILLING.CANCEL:
      return { ...state, processing: false, error: undefined };
    case BILLING.DELETE_IDENTITY:
      return { ...state, processing: false, error: undefined };
    case BILLING.PAYMENT_SUCCESS:
      return { ...state, processing: false, error: undefined };
    default:
      return state;
  }
};
