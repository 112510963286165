import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Onboarding from '../../components/onboarding/Onboarding';
import { AppState } from '../../store';

interface DispatchProps {
  account: any;
}

interface Props extends DispatchProps {
  className?: string;
}

const OnboardingPage: React.FC<Props> = ({ className, account }) => {
  const { access_level: profileType } = account.activeAccount.userInfo;

  return (
    <div className={className}>
      <Onboarding type={profileType} />
    </div>
  );
};

const StyledOnboardingPage = styled(OnboardingPage)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

export default connect(mapStateToProps)(StyledOnboardingPage);
