import React from 'react';
import { Card, Icon, Progress } from 'antd';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { styled } from '../../theme/Theme';
import { AccountState } from '../../store/account/types';
import { AppState } from '../../store';
import { LooseObj } from '../../custom-types';

const HOST_ROLES = ['TENANT_ADMIN', 'PROFESSIONAL'];

const bytesToGigabyes = (bytes: number) => bytes / (1024 * 1024 * 1024);

export interface Stat {
  value: number;
  maxValue: number;
  title: string;
}

interface DispatchProps {
  account: AccountState;
}

interface Props extends DispatchProps {
  className?: string;
}

const Statistics: React.FC<Props> = ({ className, account }: Props) => {
  const { tenantInfo } = account.activeAccount;
  const {
    max_storage: maxStorage,
    max_creators: maxNrHosts,
    max_users_per_session: maxNrUsersPerSession,
    users,
    storage_used: usedStorage,
    average_participants_per_session: averageUsersPerSession,
  } = tenantInfo;

  const currentNrHosts = users.reduce(
    // eslint-disable-next-line max-len
    (nrCreators: number, userData: LooseObj) => (HOST_ROLES.includes(userData.access_level) ? nrCreators + 1 : nrCreators),
    0,
  );
  const parsedUsedStorage = usedStorage
    ? bytesToGigabyes(usedStorage).toFixed(1)
    : undefined;
  const parsedAverageUsersPerSession = averageUsersPerSession
    ? averageUsersPerSession.toFixed(1)
    : undefined;

  const data = [
    {
      value: currentNrHosts,
      maxValue: maxNrHosts,
      title: (
        <span>
          out of
          {' '}
          <b>{maxNrHosts}</b>
          {' '}
users that can host
        </span>
      ),
    },
    {
      value: parsedAverageUsersPerSession,
      maxValue: maxNrUsersPerSession,
      title: (
        <span>
          out of
          {' '}
          <b>{maxNrUsersPerSession}</b>
          {' '}
participants per meeting
        </span>
      ),
    },
    {
      value: parsedUsedStorage,
      maxValue: maxStorage,
      title: (
        <span>
          out of
          {' '}
          <b>
            {maxStorage}
            {' '}
GB
          </b>
          {' '}
available
        </span>
      ),
    },
  ];

  return (
    <Card
      className={className}
      bordered={false}
      title={(
        <span>
          <Icon type="line-chart" />
          Statistics
        </span>
      )}
    >
      <div className="list-stats">
        {data.map((item) => {
          if (!item.value || item.maxValue === 0) {
            return null;
          }
          const percent = (item.value / item.maxValue) * 100;
          return (
            <div
              className={`stat ${percent >= 100 ? 'progress-red' : undefined}`}
              key={uuid()}
            >
              <Progress
                type="circle"
                width={80}
                percent={percent}
                format={() => `${item.value}`}
              />
              {item.title}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

const StyledStatistics = styled(Statistics)`
  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-head-title {
    display: flex;
    align-items: center;

    svg {
      fill: ${({ theme }) => theme.color.primary};
      font-size: ${({ theme }) => theme.font.size.lg};
      margin-right: ${({ theme }) => theme.space.lg};
    }

    span {
      display: inline-flex;
      font-size: 1rem;
      font-weight: bold;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .list-stats {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .stat {
      display: flex;
      flex-direction: column;
      width: 130px;
      align-items: center;

      .ant-progress-text {
        font-size: ${({ theme }) => theme.font.size.xl};
        font-weight: 800;
        color: ${({ theme }) => theme.color.primary};
      }

      .ant-progress-circle-path {
        stroke: ${({ theme }) => theme.color.primary} !important;
        animation: fill-up 1s ease-out;
        transform: rotate(-90deg) translateX(-100%);
      }

      .ant-progress-circle-trail {
        stroke-dashoffset: -100;
      }

      @keyframes fill-up {
        from {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
        }
        to {
          stroke-dashoffset: 0;
        }
      }

      & > span {
        text-align: center;
        padding-top: ${({ theme }) => theme.space.md};
        color: ${({ theme }) => theme.color.primary};
      }
    }

    .progress-red {
      .ant-progress-circle-path {
        stroke: ${({ theme }) => theme.color.redLight} !important;
      }

      .ant-progress-text,
      span {
        color: ${({ theme }) => theme.color.redLight} !important;
      }
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

export default connect(mapStateToProps)(StyledStatistics);
