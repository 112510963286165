import React from 'react';
import { LooseObj } from '../../custom-types';
import Subscription from '../../components/subscription/Subscription';
import News from '../../components/news/News';
import Statistics, { Stat } from '../../components/statistics/Statistics';
import Members from '../../components/members/Members';
import InvitationNotifierCard from '../../components/invitation-notifier-card/InvitationNotifierCard';
import { ROLE } from '../../app/constants';
import Meeting from '../../components/meeting/Meeting';

interface Props {
  stats: Stat[];
  userData: LooseObj;
  tenantData: LooseObj;
  isUserProcessing: boolean;
  isAccountProcessing: boolean;
}

const AdminDashboard: React.FC<Props> = ({
  stats,
  userData,
  tenantData,
  isUserProcessing,
  isAccountProcessing,
}) => (
  <div>
    <div className="left-col">
      <Meeting />
      <Subscription />
      <Statistics />
      <News />
    </div>
    <div className="right-col">
      <InvitationNotifierCard />
      <Members
        activeUserRole={ROLE.ADMIN}
        userData={userData}
        tenantData={tenantData}
        isUserProcessing={isUserProcessing}
        isAccountProcessing={isAccountProcessing}
      />
    </div>
  </div>
);

export default AdminDashboard;
