import React from 'react';
import styled from 'styled-components';

import media from 'styled-media-query';
import { Link } from 'react-router-dom';
import welcome from '../../assets/images/welcome.jpg';

interface Props {
  className?: string;
  content?: JSX.Element;
  transparent?: boolean;
}

const defaultLanding = (
  <div>
    <h2>Create your profile</h2>
    <h4>
      Thanks for your interest in STAGE Cloud. Please register to open your free
      profile.
    </h4>

    <p>
      You already have an account?
      <Link to="/register"> Sign in</Link>
, please!
    </p>

    {/* static welcome image */}
    <div className="video-wrapper">
      <img
        src={welcome}
        style={{
          width: '560px',
        }}
      />
    </div>
  </div>
);

const Landing: React.FC<Props> = ({
  content = defaultLanding,
  children,
  className,
}) => (
  <div className={className}>
    <div className="col">{content}</div>
    <div className="col">{children}</div>
  </div>
);

export default styled(Landing)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  .col:first-child {
    flex: 3;
    padding-right: ${({ theme }) => theme.space.xl};
    display: flex;
    flex-direction: column;

    ${media.lessThan('medium')`
      flex: 1;
    `}
  }

  .col:nth-of-type(2n) {
    flex: 2;
    display: flex;
    justify-content: space-around;
    ${({ transparent, theme }) => `
      background-color: ${transparent ? 'transparent' : theme.layer1color};
    `}

    ${media.lessThan('medium')`
       flex: 1;
       margin-top: 30px;
   `}
  }
`;
