import React, { FormEvent } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Form, Input, Popover, Icon,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { styled } from '../../theme/Theme';

import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import { createAccount } from '../../store/user/action';
import { displayNameValidator } from '../../utils/inputValidators';
import AccountCreator from '../../containers/add-account/AccountCreator';

const displayNameInfo = `The display name&apos;s length should be between 2 and 24 characters
            and it can only contain letters, numbers and symbols .!_-*/`;

interface DispatchProps {
  user: UserState;
  onCreateAccount: (formData: LooseObj, fn: () => void) => {};
}

interface Props extends FormComponentProps, RouteComponentProps, DispatchProps {
  className?: string;
}

const AccountEditor: React.FC<Props> = ({
  className,
  form,
  history,
  onCreateAccount,
}) => {
  const userStore = useSelector((state: AppState) => state.user);

  const firstName = userStore.identityInfo.first_name;
  const lastName = userStore.identityInfo.last_name;
  const fullName = `${firstName} ${lastName}`;
  const { email } = userStore.cognitoUser.challengeParam
    ? userStore.cognitoUser.challengeParam.userAttributes
    : userStore.cognitoUser.attributes;

  const { getFieldDecorator, validateFields } = form;

  const logAcceptance = (name: string, acceptedDate: any) => ({
    name,
    acceptedDate,
  });
  const acceptedDate = Date.now();
  const acknowledgments = [logAcceptance('terms_of_service', acceptedDate)];

  const callbackSubmit = (accountName: string, invitations: LooseObj[]) => {
    validateFields({ force: true }, (errors, values) => {
      if (errors) {
        return;
      }

      const registerData = {
        email,
        name: values['display-name'],
        tenant: accountName,
        fullName,
        acknowledgments,
      };

      const inviteData = {
        invitations,
        fullName,
        accountName,
      };

      onCreateAccount({ registerData, inviteData }, onSuccess);
    });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields();
  };

  const onSuccess = () => {
    history.push({
      pathname: '/onboarding',
    });
  };

  return (
    <div className={className}>
      <h2>Finalize your account setup</h2>

      <Form onSubmit={onSubmit} className="create-account-form" colon={false}>
        <h3>Set up a display name</h3>
        <Form.Item label="Display Name">
          {getFieldDecorator('display-name', {
            initialValue: (firstName + lastName)
              // eslint-disable-next-line no-useless-escape
              .replace(/[^a-zA-Z0-9\.\!\_\-]/g, '')
              .replace(/\s+/g, ''),
            validateTrigger: ['onBlur', 'onChange'],
            rules: [
              {
                required: true,
                validator: displayNameValidator,
              },
            ],
          })(<Input placeholder={firstName + lastName} />)}

          <Popover content={displayNameInfo} placement="right">
            <span className="hint">
              <Icon type="info-circle" />
            </span>
          </Popover>
        </Form.Item>

        <h3>Set up an account name</h3>
      </Form>
      <AccountCreator startingEmailFieldsCount={2} callback={callbackSubmit} />
    </div>
  );
};

const StyledAccountEditor = styled(AccountEditor)`
  background: transparent;

  & * {
    color: ${({ theme }) => theme.color.dGrey};
  }

  & > h2 {
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: ${({ theme }) => theme.space.md};
  }

  h3 {
    margin-top: calc(${({ theme }) => theme.space.xl} + 10px);
    margin-bottom: ${({ theme }) => theme.space.sm};
    font-size: 1.2rem;
  }

  .ant-form {
    .ant-form-item-control {
      .ant-form-explain {
        width: 300px;
      }
    }

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 300px;

      .ant-input {
        margin-right: ${({ theme }) => theme.space.md};
        border: 1px solid ${({ theme }) => theme.color.lGrey};
        border-radius: 5px;
      }

      .hint {
        display: inline-block;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.dGrey};
        cursor: pointer;
      }
    }

    .ant-form-item-required::before {
      display: none;
    }
  }

  p {
    color: ${({ theme }) => theme.color.lGreyDark};
  }
`;

const mapDispatchToProps = {
  onCreateAccount: createAccount,
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  withRouter, // routing history
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ), // redux
  Form.create<Props>({ name: 'create-account-tenant' }), // antd form HoC
)(StyledAccountEditor) as React.ComponentType;
