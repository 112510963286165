import { Dispatch } from 'redux';
import { aggregate } from '../index';
import { USER } from './types';
import { LooseObj } from '../../custom-types';
import UserService from '../../services/user/UserService';
import { setupUserProfiles } from '../account/action';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import { AWS_ERRORS } from '../../app/constants';
import { ACCOUNT } from '../account/types';

export const update = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(USER.UPDATE));
  } catch (err) {
    console.error(err);
  }
};

export const login = (email: string, pw: string, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(USER.PROCESSING));

    const [cognitoUser, identityInfo, invitesInfo] = await Promise.all([
      UserService.authenticate(email, pw),
      UserService.getIdentityInfo(email),
      UserService.getInvitations(email),
    ]);

    if (
      cognitoUser.code === AWS_ERRORS.UserNotFoundException
      || cognitoUser.code === AWS_ERRORS.NotAuthorizedException
    ) {
      dispatch(aggregate(USER.ERROR, { message: cognitoUser.message }));
      return;
    }

    identityInfo.invites = invitesInfo.invitations || [];

    dispatch(
      aggregate(USER.LOGIN, {
        cognitoUser,
        identityInfo,
      }),
    );

    await setupUserProfiles(identityInfo)(dispatch);

    cb();
  } catch (err) {
    dispatch(
      aggregate(
        USER.ERROR,
        err.response && (err.response.data ? err.response.data : err.response),
      ),
    );
    console.error(err);
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(USER.PROCESSING));

    await UserService.deauthenticate();
    LocalStorageService.clearActiveAccount();
    LocalStorageService.clearInviteData();

    (window as any).history.go();
    dispatch(aggregate(USER.CLEAR));
  } catch (err) {
    console.error(err);
  }
};

export const verifyLogin = (cb: () => void) => async (dispatch: Dispatch) => {
  try {
    const cognitoUser = await UserService.checkAuthentication();

    if (cognitoUser) {
      const [identityInfo, invitesInfo] = await Promise.all([
        UserService.getIdentityInfo(cognitoUser.attributes.email),
        UserService.getInvitations(cognitoUser.attributes.email),
      ]);

      identityInfo.invites = invitesInfo.invitations || [];

      dispatch(
        aggregate(USER.LOGIN, {
          cognitoUser,
          identityInfo,
        }),
      );
      await setupUserProfiles(identityInfo)(dispatch);

      cb();
    } else {
      dispatch(aggregate(USER.CLEAR));
      dispatch(aggregate(ACCOUNT.CLEAR));
    }
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
  }
};

export const register = (formData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(USER.PROCESSING));
    const identity = await UserService.register(formData);

    if (!identity.success) {
      dispatch(aggregate(USER.ERROR, identity));
    } else {
      dispatch(aggregate(USER.REGISTER));
      cb();
    }
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));

    console.error(err);
  }
};

export const clear = () => async (dispatch: Dispatch) => {
  dispatch(aggregate(USER.CLEAR));
};

export const resetPassword = (formData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    const { email } = formData;

    dispatch(aggregate(USER.PROCESSING));

    const [cognitoUser, identityInfo] = await Promise.all([
      UserService.resetPassword(formData),
      UserService.getIdentityInfo(email),
    ]);

    if (cognitoUser.code) {
      dispatch(aggregate(USER.ERROR, cognitoUser));
      return;
    }

    dispatch(
      aggregate(USER.LOGIN, {
        cognitoUser,
        identityInfo,
      }),
    );

    cb();
  } catch (err) {
    if (err.message === 'authenticationRequired') {
      cb('');
    }

    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
  }
};

export const createAccount = (
  { registerData, inviteData, existingUserProfiles = [] }: LooseObj,
  cb: Function,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(USER.PROCESSING));

    const resp = await UserService.createAccount(registerData);
    const { tenant, account } = resp;

    if (!tenant.success) {
      dispatch(aggregate(USER.ERROR, tenant.message));
      return;
    }
    const handleAcknowledgmentsResponse = await UserService.handleAcknowledgments(
      registerData,
    );
    if (!handleAcknowledgmentsResponse.success) {
      dispatch(aggregate(USER.ERROR, handleAcknowledgmentsResponse.data));
    }

    dispatch(aggregate(USER.INVITE));
    const handleInvitesResponse = await UserService.handleInvites({
      ...inviteData,
      accountId: account.account.id,
    });
    if (!handleInvitesResponse.success) {
      dispatch(aggregate(USER.ERROR, handleInvitesResponse.data));
    }

    LocalStorageService.setActiveAccount(account.account.id);

    await setupUserProfiles()(dispatch);

    dispatch(aggregate(USER.CREATE));
    cb();
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
  }
};

export const setDisplayName = (userData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(USER.PROCESSING));
    const displayNameResponse = await UserService.setDisplayName(userData);

    if (displayNameResponse.message) {
      dispatch(aggregate(USER.ERROR, displayNameResponse));
      cb(displayNameResponse.message);
    } else {
      const { accountId, userId } = displayNameResponse;

      LocalStorageService.setActiveAccount(accountId.toString());
      await setupUserProfiles()(dispatch);

      dispatch(
        aggregate(USER.UPDATE_USER_PROFILES, {
          tenant_id: accountId,
          user_id: userId,
        }),
      );

      dispatch(aggregate(USER.SET_DISPLAY_NAME, accountId));

      cb();
    }
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
    cb('Something went wrong');
  }
};

export const inviteUsers = (inviteData: LooseObj, cb: Function) => async (
  dispatch: Dispatch,
) => {
  dispatch(aggregate(USER.PROCESSING));

  const handleInvitesResponse = await UserService.handleInvites(inviteData);
  if (!handleInvitesResponse.success) {
    dispatch(aggregate(USER.ERROR, handleInvitesResponse.data));
    cb({ success: false });
  } else {
    dispatch(aggregate(USER.INVITE));
    cb({ success: true });
  }
};

export const storeInviteData = (queryParamsString: string) => {
  const queryParams = new URLSearchParams(queryParamsString);
  const tenantIdString = queryParams.get('i') || '';
  const tenantId = parseInt(tenantIdString, 10);
  const tenantName = queryParams.get('n');
  const role = queryParams.get('r');
  const inviterName = queryParams.get('s');
  const hasInviteData = tenantName && tenantId && role && inviterName;

  if (hasInviteData) {
    LocalStorageService.setInviteData({
      tenantName,
      tenantId,
      role,
      inviterName,
    });
  }
};

export const updateIdentity = (
  newIdentityData: LooseObj,
  cb: Function,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(USER.UPDATE_IDENTITY));
    const updateIdentityResponse = await UserService.updateIdentity(
      newIdentityData,
    );

    if (!updateIdentityResponse.success) {
      dispatch(aggregate(USER.ERROR, updateIdentityResponse));
      cb({ success: false });
    } else {
      dispatch(aggregate(USER.UPDATE));

      const { newFirstName, newLastName, newPhoneNumber } = newIdentityData;
      const undescoreNotation = {
        ...(newFirstName && { first_name: newFirstName }),
        ...(newLastName && { last_name: newLastName }),
        ...(newPhoneNumber && { phone_number: newPhoneNumber }),
      };

      dispatch(aggregate(USER.UPDATE_IDENTITY, undescoreNotation));
      cb({ success: true });
    }
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
  }
};

export const changePassword = (
  oldPassword: string,
  newPassword: string,
  cb: Function,
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(USER.CHANGE_PASSWORD));
    const changePasswordResponse = await UserService.changePassword(
      oldPassword,
      newPassword,
    );

    if (changePasswordResponse !== 'SUCCESS') {
      dispatch(aggregate(USER.ERROR, changePasswordResponse));
      cb({ success: false, message: changePasswordResponse.message });
    } else {
      dispatch(aggregate(USER.UPDATE));
      cb({ success: true });
    }
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    cb({ success: false, message: err.message });
    console.error(err);
  }
};

export const declineInvite = (tenantId: string, email: string) => async (
  dispatch: Dispatch,
) => {
  try {
    dispatch(aggregate(USER.PROCESSING));

    await UserService.deleteInvitation(tenantId, email);

    dispatch(aggregate(USER.DECLINE_INVITE, tenantId));
  } catch (err) {
    dispatch(aggregate(USER.ERROR, err));
    console.error(err);
  }
};
