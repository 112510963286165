import { API } from 'aws-amplify';
// import axios from 'axios';
import { API_GATEWAYS } from '../aws/config';
import { BILLING_PLANS } from '../../app/constants';
import { LooseObj } from '../../custom-types';

class BillingService {
  static async getToken(): Promise<any> {
    try {
      const { success, token } = await API.get(
        API_GATEWAYS.BILLING,
        'api/token',
        {},
      );

      // const { success, token } = await axios.get(
      //   `http://localhost:3000/api/token`,
      // ).then((res) => res.data);

      if (success) {
        return token;
      }
    } catch (e) {
      console.error(e);
      throw new Error('Token could not be refreshed');
    }
  }

  static async buy(
    paymentMethodNonce: string,
    { plan, customerId, buyer }: LooseObj,
  ) {
    let result;
    try {
      if (
        Object.values(BILLING_PLANS)
          .map((e) => e.name)
          .indexOf(plan.planDetails.name) === -1
      ) {
        throw new Error(`Invalid plan ${plan}`);
      }

      result = await API.post(API_GATEWAYS.BILLING, 'api/subscription/buy', {
        body: {
          paymentMethodNonce,
          customerId,
          plan,
          buyer,
        },
      });
      // result = await axios
      //   .post('http://localhost:3000/api/subscription/buy', {
      //     paymentMethodNonce,
      //     customerId,
      //     plan,
      //     buyer,
      //   })
      //   .then((res) => res.data);
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  static async cancel(customerId: string) {
    let result;
    try {
      result = await API.post(API_GATEWAYS.BILLING, 'api/subscription/cancel', {
        body: { customerId },
      });
      // result = await axios
      //   .post('http://localhost:3000/api/subscription/cancel', { customerId })
      //   .then((res) => res.data);
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  static async putAccountDetails(billingDetails: LooseObj) {
    let result;
    try {
      result = await API.post(API_GATEWAYS.STAGE, 'api/admin/account', {
        body: billingDetails,
      });
    } catch (e) {
      console.error(e);
    }
    return result;
  }

  static async sendEmail({ operation, ...rest }: LooseObj) {
    try {
      if (
        ![
          'cancel',
          'upgrade',
          'pay-with-invoice',
          'delete-identity-request',
          'payment-notification',
        ].includes(operation)
      ) {
        throw new Error(`Invalid operation ${operation}`);
      }
      return API.post(API_GATEWAYS.STAGE, 'api/admin/subscription', {
        body: {
          operation,
          ...rest,
        },
      });
      // const result = await axios
      //   .post('http://localhost:3006/api/admin/subscription', { operation, ...rest })
      //   .then((res) => res.data);
      // console.log(result);
      // return result;
    } catch (err) {
      console.error(err);
    }
  }
}

export default BillingService;
