/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// @ts-ignore
var _hsq = (window._hsq = window._hsq || []);

class AnalyticsListener extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  componentDidMount() {
    // @ts-ignore
    const { history } = this.props;
    this.sendPageView(history.location);
    history.listen(this.sendPageView);
  }

  sendPageView(location: any) {
    _hsq.push(["setPath", location.pathname]);
    _hsq.push(["trackPageView"]);
  }

  render() {
    return this.props.children;
  }
}

// @ts-ignore
export default withRouter(AnalyticsListener);
