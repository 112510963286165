export const displayNameValidator = (
  rule: any,
  value: string,
  callback: (msg?: string) => void,
) => {
  if (value.length < 2 || value.length > 24) {
    callback('Length does not respect constraints');
  }
  // eslint-disable-next-line no-useless-escape
  if (!/^[a-zA-Z0-9\.\!\_\-]{2,24}$/.test(value)) {
    callback('It may contain only letters, numbers and symbols .!_-');
  }

  callback();
};

export const accountNameValidator = (
  rule: any,
  value: string,
  callback: (msg?: string) => void,
) => {
  if (value.length < 4 || value.length > 64) {
    callback('Account name length should be between 4 and 64 characters');
    return;
  }
  // eslint-disable-next-line no-useless-escape
  if (!/^[a-zA-Z0-9öäüÖÄÜß\&\$\_\;\.\+\-\!]{4,64}$/.test(value)) {
    callback('Your account name can only contain letters, numbers and symbols');
    return;
  }

  callback();
};

export const phoneNumberValidator = (
  rule: any,
  value: string,
  callback: (msg?: string) => void,
) => {
  const phoneNumber = value.replace(/[^0-9]/g, '').replace(/^0{2}/g, '');

  if (value === '') {
    callback();
    return;
  }

  if (!/^[0-9]{4,15}$/.test(phoneNumber)) {
    callback('Please enter your phone number with the country code');
    return;
  }

  callback();
};

// eslint-disable-next-line no-useless-escape
export const emailValidator = (email: string) => /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
