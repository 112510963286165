import React, { useEffect } from 'react';
import { Layout } from 'antd';
import media from 'styled-media-query';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import AppRoutes from '../../routes/Routes';
import { styled } from '../../theme/Theme';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { verifyLogin, storeInviteData } from '../../store/user/action';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface Props {
  history: any;
  className?: string;
}

interface DispatchProps {
  onInit: (cb: () => void) => any;
}

const InitializedApp: React.FC<Props & DispatchProps> = ({
  className,
  onInit,
  history,
}) => {
  const queryParamsString = new URLSearchParams(
    history.location.search,
  ).toString();

  storeInviteData(queryParamsString);

  const onAlreadyLoggedIn = () => {
    const inviteData = LocalStorageService.getInviteData();
    const wasInvited = !isEmpty(inviteData);

    if (wasInvited) {
      history.push('/join');
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    onInit(onAlreadyLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className={className}>
      <Layout.Header>
        <Header />
      </Layout.Header>

      <Layout.Content>
        <AppRoutes />
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};

const StyledInitApp = styled(InitializedApp)`
  && {
    min-height: 100%;
    background-color: ${({ theme }) => theme.layer0color};
    
    & > * {
      background-color: transparent;
      
      ${media.lessThan('medium')`
         padding: 0 ${({ theme }) => theme.space.lg};
      `}
    
      ${media.between('medium', 'large')`
         padding: 0 ${({ theme }) => theme.space.xxl};
      `}
    
      ${media.greaterThan('large')`
         padding: 0 ${() => (window.innerWidth - 1170) / 2}px;
      `}
     
      &.ant-layout {
        height: 100%;
        min-height: calc(100vh - ${({ theme }) => theme.container.headerHeight + theme.container.footerHeight});
      }
  
      &.ant-layout-header {
        background-color: #fff;
        display: flex;
        align-items: center;
        height: ${({ theme }) => theme.container.headerHeight};
        box-shadow: 0px 2px 8px rgba(0, 0, -0.15, 0.15);
      }
  
      &.ant-layout-content {
        padding-top: ${({ theme }) => theme.space.xxl};
        padding-bottom: ${({ theme }) => theme.space.xxl};
      }
  
      &.ant-layout-footer {
        height: ${({ theme }) => theme.container.footerHeight};
        background-color: ${({ theme }) => theme.layer0color};
        padding-bottom: ${({ theme }) => theme.space.xl};
      }
    }
  }
`;
export default withRouter(
  connect(
    null,
    {
      onInit: verifyLogin,
    },
  )(StyledInitApp),
);
