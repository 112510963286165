import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PrivateRoute from '../components/private-route/PrivateRoute';
import Dashboard from '../containers/dashboard/Dashboard';
import NoMatch from '../components/private-route/NoMatch';
import Landing from '../containers/landing/Landing';
import NewsPage from '../containers/news/NewsPage';
import RegisterFeedback from '../components/register/RegisterFeedback';
import PasswordPage from '../containers/create-password/PasswordPage';
import AccountEditorPage from '../containers/create-account-tenant/AccountEditorPage';
import OnboardingPage from '../containers/onboarding/OnboardingPage';
import DisplayNamePage from '../containers/display-name-setup/DisplayNamePage';
import RegisterPage from '../containers/register/RegisterPage';
import LoginPage from '../containers/login/LoginPage';
import ProfileSettings from '../components/profile-settings/ProfileSettings';
import ManageAccountsUsers from '../components/manage-accounts-users/ManageAccountsUsers';
import EditAccount from '../containers/edit-account/EditAccount';
import AddAccountPage from '../containers/add-account/AddAccountPage';
import BillingPage from '../containers/billing/BillingPage';
import BillingPlans from '../containers/billing-plans/BillingPlans';
import BillingSummaryPage from '../containers/billing-summary/BillingSummaryPage';
import AccountSettings from '../containers/account-settings/AccountSettings';
import ForgotPassword from '../components/forgot-password/ForgotPassword';
import BillingSummaryUpdatePage from '../containers/billing-summary-update/BillingSummaryUpdatePage';

const AppRoutes: React.FC = () => (
  <Switch>
    <Route path="/register/success" render={(props) => renderRegisterSuccess} />
    <Route path="/login">
      <LoginPage />
    </Route>
    <Route path="/register">
      <RegisterPage />
    </Route>
    <Route path="/forgot">
      <ForgotPassword />
    </Route>
    <Route path="/reset">
      <PasswordPage />
    </Route>
    <PrivateRoute path="/onboarding" component={OnboardingPage} />
    <PrivateRoute path="/account-setup" component={AccountEditorPage} />
    <PrivateRoute path="/account-settings" component={AccountSettings} />
    <PrivateRoute path="/news" component={NewsPage} />
    <PrivateRoute path="/join" component={DisplayNamePage} />
    <PrivateRoute path="/plans" component={BillingPlans} />
    <PrivateRoute path="/summary" component={BillingSummaryPage} />
    <PrivateRoute
      path="/upgrade-summary"
      component={BillingSummaryUpdatePage}
    />
    <PrivateRoute path="/billing" component={BillingPage} />
    <PrivateRoute path="/profile-settings" component={ProfileSettings} />
    <PrivateRoute path="/new-account" component={AddAccountPage} />
    <PrivateRoute path="/platform-admin/account" component={EditAccount} />
    <PrivateRoute path="/platform-admin" component={ManageAccountsUsers} />
    <PrivateRoute path="/" component={Dashboard} />
    <Route component={NoMatch} />
  </Switch>
);

const renderRegisterSuccess = (
  <Landing transparent>
    <RegisterFeedback />
  </Landing>
);

export default AppRoutes;
