import { PlatformAdminState, PLATFORM_ADMIN } from './types';
import { ActionResponse } from '../index';

const DEFAULT_STATE: PlatformAdminState = {
  tenantsInfo: undefined,
  identitiesInfo: undefined,
  processing: false,
  error: undefined,
};

export default (state = DEFAULT_STATE, { type, payload }: ActionResponse) => {
  switch (type) {
    case PLATFORM_ADMIN.PROCESSING:
      return { ...state, processing: true };
    case PLATFORM_ADMIN.GET_ALL_TENANTS:
      return {
        ...state,
        tenantsInfo: payload,
        processing: false,
        error: undefined,
      };
    case PLATFORM_ADMIN.GET_ALL_IDENTITIES:
      return {
        ...state,
        identitiesInfo: payload,
        processing: false,
        error: undefined,
      };
    case PLATFORM_ADMIN.DELETE_IDENTITY:
      return { ...state, processing: false, error: undefined };
    case PLATFORM_ADMIN.CLEAR:
      return { ...state, processing: false, error: undefined };
    case PLATFORM_ADMIN.ERROR:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    default:
      return state;
  }
};
