import { LooseObj } from '../../custom-types';

export enum PLATFORM_ADMIN {
  GET_ALL_IDENTITIES = 'PLATFORM_ADMIN_GET_ALL_IDENTITIES_INFO',
  GET_ALL_TENANTS = 'PLATFORM_ADMIN_GET_ALL_TENANTS_INFO',
  DELETE_IDENTITY = 'PLATFORM_ADMIN_DELETE_IDENTITY',
  PROCESSING = 'PLATFORM_ADMIN_PROCESSING',
  ERROR = 'PLATFORM_ADMIN_ERROR',
  CLEAR = 'PLATFORM_ADMIN_CLEAR'
}

export interface PlatformAdminState {
  tenantsInfo: LooseObj[] | undefined;
  identitiesInfo: LooseObj[] | undefined;
  processing: boolean;
  error: LooseObj | undefined;
}
