import React from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Spin } from 'antd';
import { AppState } from '../../store';
import { UNAUTHORIZED_REDIRECT_LINK } from '../../app/constants';
import SingleCenteredContentPage from '../../containers/singleCenteredContentPage/SingleCenteredContentPage';

interface Props extends RouteComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: any;
  path: string;
  type?: string;
}

/**
 * PrivateRoute is a authentication validation method which display `Component` if user is
 * authenticated or it redirects him to the `/login` route otherwise
 * @param Component
 * @param rest
 * @constructor
 */
const PrivateRoute: React.FC<Props> = ({
  component: Component,
  history,
  ...rest
}) => {
  const userStore = useSelector((state: AppState) => state.user);
  const accountStore = useSelector((state: AppState) => state.account);
  const hasIdentity = get(userStore, 'identityInfo');
  const hasCognito = get(userStore, 'cognitoUser');
  const isProcessing = get(userStore, 'bootstrapping') || get(accountStore, 'bootstrapping');

  const isAuthenticated = hasIdentity && hasCognito;

  const renderFunction = (props: any) => {
    if (isProcessing) {
      return (
        <SingleCenteredContentPage>
          <Spin spinning />
        </SingleCenteredContentPage>
      );
    }
    if (isAuthenticated) {
      return <Component {...props} {...rest} />;
    }

    return (
      <Redirect
        to={{
          pathname: UNAUTHORIZED_REDIRECT_LINK,
          state: { from: props.location },
        }}
      />
    );
  };

  return <Route render={renderFunction} />;
};

export default withRouter(PrivateRoute);
