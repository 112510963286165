import { ACCOUNT, AccountState } from './types';
import { ActionResponse } from '../index';
import { LooseObj } from '../../custom-types';

const DEFAULT_STATE: AccountState = {
  activeAccount: {},
  accounts: {}, // is an array of profile - tenant mapping

  processing: false,
  success: false,
  error: undefined,
  bootstrapping: true,
};

export default (state = DEFAULT_STATE, { type, payload }: ActionResponse) => {
  switch (type) {
    case ACCOUNT.UPDATE_ACTIVE:
      return { ...state, activeAccount: payload };
    case ACCOUNT.PROCESSING:
      return { ...state, processing: !state.processing, error: undefined };
    case ACCOUNT.SETUP_PROFILES:
      return {
        ...state,
        processing: false,
        bootstrapping: false,
        accounts: payload,
      };
    case ACCOUNT.MODIFY_TENANT_NAME:
      if (payload.isPlatformAdmin) {
        return { ...state, processing: false, error: undefined };
      }
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          tenantInfo: { ...state.activeAccount.tenantInfo, ...payload },
        },
        accounts: {
          ...state.accounts,
          [payload.tenant_id]: {
            ...state.accounts[payload.tenant_id],
            tenantInfo: {
              ...state.accounts[payload.tenant_id].tenantInfo,
              ...payload,
            },
          },
        },
        processing: false,
        error: undefined,
      };
    case ACCOUNT.MODIFY_ACCOUNT:
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          accountInfo: { ...state.activeAccount.accountInfo, ...payload },
        },
        processing: false,
      };
    case ACCOUNT.MODIFY_USER_ROLE:
      if (payload.isPlatformAdmin) {
        return { ...state, processing: false };
      }
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          tenantInfo: {
            ...state.activeAccount.tenantInfo,
            users: state.activeAccount.tenantInfo.users.map(
              (user: LooseObj) => {
                if (user.id === payload.userId) {
                  return { ...user, access_level: payload.newRole };
                }
                return user;
              },
            ),
          },
        },
        processing: false,
      };
    case ACCOUNT.UPDATE_DETAILS:
      return { ...state, processing: true };
    case ACCOUNT.UPDATE_DETAILS_SUCCESS:
      return {
        ...state,
        processing: false,
        activeAccount: {
          ...state.activeAccount,
          accountInfo: {
            ...state.activeAccount.accountInfo,
            ...payload,
          },
        },
      };
    case ACCOUNT.DELETE_USER:
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          tenantInfo: {
            ...state.activeAccount.tenantInfo,
            users: state.activeAccount.tenantInfo.users.filter(
              (user: LooseObj) => user.id !== payload.id,
            ),
          },
        },
        processing: false,
        error: undefined,
      };
    case ACCOUNT.LEAVE_ACCOUNT:
      return {
        ...state,
        activeAccount: {
          ...state.activeAccount,
          tenantInfo: {
            ...state.activeAccount.tenantInfo,
            users: state.activeAccount.tenantInfo.users.filter(
              (user: LooseObj) => user.id !== payload.id,
            ),
          },
        },
        accounts: Object.keys(state.accounts)
          .filter((key) => key !== payload.tenant_id.toString())
          .reduce(
            (obj, key) => ({
              ...obj,
              [key]: state.accounts[key],
            }),
            {},
          ),
        processing: false,
        error: undefined,
      };
    case ACCOUNT.ERROR:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    case ACCOUNT.CLEAR:
      return {
        ...state,
        processing: false,
        error: payload,
        bootstrapping: false,
      };
    default:
      return state;
  }
};
