import { PASSWORD } from '../app/constants';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PasswordValidator = require('password-validator');

export const passwordRequirements = {
  digits: 'at least one digit',
  uppercase: 'at least one uppercase character',
  lowercase: 'at least one lowercase character',
  symbols: 'at least one symbol',
  min: `at least ${PASSWORD.MIN_LENGTH} characters`,
  max: `at most ${PASSWORD.MAX_LENGTH} characters`,
};

export const errorMessages = {
  digits: 'Your password must contain at least one digit',
  uppercase: 'Your password must contain at least one uppercase character',
  lowercase: 'Your password must contain at least one lowercase character',
  symbols: 'Your password must contain at least one symbol',
  min: `Your password must be at least ${PASSWORD.MIN_LENGTH} characters long`,
  max: `Your password must be maximum ${PASSWORD.MAX_LENGTH} characters long`,
};

const schema = new PasswordValidator()
  .is()
  .min(PASSWORD.MIN_LENGTH) // Minimum length 8
  .is()
  .max(PASSWORD.MAX_LENGTH) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits()
  .has()
  .symbols(); // Must have digits

const passwordValidator = (rule: any, value: any, asObject = false) => {
  const reqMatch = {
    digits: true,
    uppercase: true,
    lowercase: true,
    symbols: true,
    min: true,
    max: true,
  };

  const errors = schema.validate(value, { list: true }).map((error: string) => {
    let match;

    switch (error) {
      case 'digits':
        match = errorMessages.digits;
        reqMatch.digits = false;
        break;
      case 'uppercase':
        match = errorMessages.uppercase;
        reqMatch.uppercase = false;
        break;
      case 'lowercase':
        match = errorMessages.lowercase;
        reqMatch.lowercase = false;
        break;
      case 'symbols':
        match = errorMessages.symbols;
        reqMatch.symbols = false;
        break;
      case 'min':
        match = errorMessages.min;
        reqMatch.min = false;
        break;
      case 'max':
        match = errorMessages.max;
        reqMatch.max = false;
        break;
      default:
        console.warn('Unhandled password validation');
    }
    return match;
  });

  return asObject ? reqMatch : [errors[0]];
};

export { passwordValidator };
