import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import Landing from '../landing/Landing';
import BasicCardTitleOnly from '../../components/basic-card/BasicCardTitleOnly';
import Register from '../../components/register/Register';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import { styled } from '../../theme/Theme';
import welcome from '../../assets/images/welcome.jpg';

interface Props extends RouteComponentProps {
  className?: string;
}

const RegisterPage: React.FC<Props> = ({ className }) => {
  const { tenantName, inviterName } = LocalStorageService.getInviteData();

  const leftCol = (
    <div className={className}>
      {tenantName ? (
        <BasicCardTitleOnly
          title={`You were invited to join the ${tenantName} account${
            inviterName ? ` by ${inviterName}` : ''
          }`}
          marginBottom="30px"
        />
      ) : null}
      <h2>Create your profile</h2>
      <h4>
        Thanks for your interest in STAGE Cloud. Please register to open your
        free profile.
      </h4>

      <h4>
        You already have an account?
        <Link to="/login"> Sign in</Link>
, please!
      </h4>

      {/* static welcome image */}
      <div className="video-wrapper">
        <img
          src={welcome}
          style={{
            width: '560px',
          }}
        />
      </div>
    </div>
  );

  return (
    <Landing content={leftCol}>
      <Register />
    </Landing>
  );
};

const StyledRegisterPage = styled(RegisterPage)`
  h2 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
    font-size: 1.6rem;
    width: 75%;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: ${({ theme }) => theme.space.xl};
    width: 60%;
  }

  h4:last-of-type {
    margin-bottom: ${({ theme }) => theme.space.xxl};
  }
`;

export default withRouter(StyledRegisterPage);
