import Amplify from 'aws-amplify';
import axios from 'axios';
import config from './config';
import { LooseObj } from '../../custom-types';

const configureAmplify = async (stage: string) => {
  const stageTemp = process.env.REACT_APP_STAGE === 'production' ? 'prod' : 'dev';
  const envConf = (config as any)[stageTemp];

  await axios.get(envConf.signInUrl).then(({ data }: LooseObj) => {
    Amplify.configure({
      Auth: {
        region: envConf.region,
        userPoolId: data.cognito_pool,
        userPoolWebClientId: data.cognito_web_client_id,
      },
      API: {
        endpoints: envConf.apiGateways.map((api: any) => ({
          name: api.name,
          endpoint: api.url,
          region: envConf.region,
        })),
      },
    });
  });
};

export default configureAmplify;
