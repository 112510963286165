import { LooseObj } from '../custom-types';

export const UNAUTHORIZED_REDIRECT_LINK = '/login';

export const PASSWORD = {
  MIN_LENGTH: 6,
  MAX_LENGTH: 64,
};

export const ROLE = {
  ADMIN: 'TENANT_ADMIN',
  PROFESSIONAL: 'PROFESSIONAL',
  GUEST: 'GUEST',
  NO_PROFILE: 'NO_PROFILE',
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  translate: (role: string): string => {
    if (role === ROLE.ADMIN) {
      return 'Admin';
    }
    if (role === ROLE.PROFESSIONAL) {
      return 'Professional';
    }
    return 'Guest';
  },
};

export const AWS_ERRORS = {
  UserNotFoundException: 'UserNotFoundException',
  NotAuthorizedException: 'NotAuthorizedException',
};

export const BILLING_PLANS: LooseObj = {
  STARTER: {
    name: 'starter',
    description: 'For individuals, freelancers, start-ups!',
    priceWithYearly: 149,
    priceWithMonthly: Math.ceil(149 * 1.2),
    currency: '€',
    minHostUsers: 1,
    maxHostUsers: 3,
    storageSize: 5,
    storageMetric: 'GB',
    participants: 3,
    guestInvitation: 'unlimited',
    solutions: ['STAGE for Unity', 'STAGE for Unreal'],
    customerSupport: ['Web helpdesk'],
  },
  PROFESSIONAL: {
    name: 'professional',
    description: 'For companies and agencies',
    priceWithYearly: 149,
    priceWithMonthly: Math.ceil(149 * 1.2),
    currency: '€',
    minHostUsers: 4,
    maxHostUsers: 9,
    storageSize: 30,
    storageMetric: 'GB',
    participants: 6,
    guestInvitation: 'unlimited',
    solutions: [
      'STAGE for Unity',
      'STAGE for Unreal',
      'STAGE for Visual Components',
    ],
    customerSupport: ['Web helpdesk'],
  },
  ENTERPRISE: {
    name: 'enterprise',
    description: 'For large enterprises',
    priceWithYearly: 149,
    priceWithMonthly: Math.ceil(149 * 1.2),
    currency: '€',
    minHostUsers: 10,
    maxHostUsers: undefined,
    storageSize: 80,
    storageMetric: 'GB',
    participants: 12,
    guestInvitation: 'unlimited',
    solutions: [
      'STAGE for Unity',
      'STAGE for Unreal',
      'STAGE for Visual Components',
    ],
    customerSupport: ['Web helpdesk', 'Dedicated support'],
  },
};

export const EXTERNAL_LINKS = {
  IMPRINT: 'https://vr-on.com/imprint/',
  TERMS_OF_SERVICE: 'https://vr-on.com/terms-of-service/',
  PRIVACY_POLICY: 'https://vr-on.com/privacy-policy/',
  STAGE_CLIENT: 'https://www.vr-on.com/download-client.html',
  UNITY_CREATOR: 'https://www.vr-on.com/download-unity-creator.html',
  UNREAL_CREATOR: 'https://www.vr-on.com/download-unreal-creator.html',
  DATA_PROCESSING_AGREEMENT: 'https://www.vr-on.com/legal-AV-EN.html',
  FAQ: 'https://stage-documentation.refined.site/space/FAQ',
};

export const EMAIL = {
  SUPPORT: 'support@vr-on.com',
};
