import React, { useEffect, useState } from 'react';
import {
  Dropdown, Menu, Avatar, Badge,
} from 'antd';
import { get, isEmpty } from 'lodash';
import uuid from 'uuid/v4';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { styled } from '../../theme/Theme';
// import FlagIcon from '../flag/FlagIcon';
import logo from '../../assets/images/logo.png';
import { AppState } from '../../store';
import { logout } from '../../store/user/action';
import { updateActiveAccount } from '../../store/account/action';
import { LooseObj } from '../../custom-types';
import { ROLE, EXTERNAL_LINKS } from '../../app/constants';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import UserService from '../../services/user/UserService';
import { UserState } from '../../store/user/types';
import { AccountState } from '../../store/account/types';

const { SubMenu } = Menu;

interface Props {
  className?: string;
}

interface DispatchProps {
  user: UserState;
  account: AccountState;
  onLogout: () => {};
  onAccountChange: (key: LooseObj, cb: Function) => {};
}

const Header: React.FC<Props & DispatchProps & RouteComponentProps> = ({
  user,
  className,
  account,
  onLogout,
  onAccountChange,
  history,
}) => {
  // const languages = ['gb', 'de'];
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isPlatformAdmin, setIsPlatformAdmin] = useState(false);

  const onIsAuthenticatedChange: Function = (userData: LooseObj) => {
    if (userData.cognitoUser && userData.identityInfo) {
      setIsAuthenticated(true);
      getMeta();
    }
  };

  const getMeta = async () => {
    const { email } = user.identityInfo;
    const { isPA, success: metaSuccess } = await UserService.getMeta(email);

    if (metaSuccess) {
      setIsPlatformAdmin(isPA);
    }
  };

  useEffect(() => {
    onIsAuthenticatedChange(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, user.cognitoUser, user.identityInfo]); //  TODO account.activeAccount

  const getFullName = () => get(user, 'identityInfo')
    && `${user.identityInfo.first_name} ${user.identityInfo.last_name}`;

  const getDisplayName = () => get(account, 'activeAccount.userInfo.name')
    && `@${account.activeAccount.userInfo.name}`;

  const getAccountName = () => get(account, 'activeAccount.tenantInfo.tenant_name')
    && `${account.activeAccount.tenantInfo.tenant_name}`;

  const getRole = () => {
    const role = get(account, 'activeAccount.userInfo.access_level');
    const roleString = ROLE.translate(role);
    return `${roleString}`;
  };

  const onActiveAccountChange = (selectedAccount: LooseObj) => (
    clickParam: any,
  ) => {
    onAccountChange(selectedAccount, () => history.push('/'));
  };

  const onInviteClick = ({ tenantId, tenantName, role }: LooseObj) => {
    LocalStorageService.setInviteData({
      tenantName,
      tenantId,
      role,
      inviterName: null,
    });
    history.push('/join');
  };

  const handleLogoOnClick = () => {
    if (isEmpty(account.accounts)) {
      LocalStorageService.clearActiveAccount();
      LocalStorageService.clearInviteData();
      onLogout();
    } else {
      LocalStorageService.clearInviteData();
      history.push('/');
    }
  };

  // const languageMenu = (
  //   <Menu>
  //     {languages.map((lang) => (
  //       <Menu.Item key={`menu-lang-${uuid()}`}>
  //         <FlagIcon code={lang} />
  //       </Menu.Item>
  //     ))}
  //   </Menu>
  // );

  const profileMenu = (
    <Menu className="displaced-dd-menu">
      <Menu.Item key="menu-profile-item">
        <Link to="/profile-settings">Profile</Link>
      </Menu.Item>

      <SubMenu key="menu-downloads-item" title="Downloads">
        <Menu.Item key="stage-client">
          <a
            href={EXTERNAL_LINKS.STAGE_CLIENT}
            target="_blank"
            rel="noopener noreferrer"
          >
            STAGE Client
          </a>
        </Menu.Item>

        <Menu.Item key="unity-creator">
          <a
            href={EXTERNAL_LINKS.UNITY_CREATOR}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unity Creator
          </a>
        </Menu.Item>

        <Menu.Item key="unreal-creator">
          <a
            href={EXTERNAL_LINKS.UNREAL_CREATOR}
            target="_blank"
            rel="noopener noreferrer"
          >
            Unreal Creator
          </a>
        </Menu.Item>
      </SubMenu>

      {isPlatformAdmin && (
        <Menu.Item key="menu-platform-admin-item">
          <Link to="/platform-admin">Platform admin</Link>
        </Menu.Item>
      )}
      <Menu.Divider style={{ margin: '2px 0' }} />
      <Menu.Item key="menu-logout-item" onClick={onLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const accountMenu = () => {
    if (get(account, 'accounts')) {
      const { accounts } = account;
      const { invites } = user.identityInfo;

      if (!Object.keys(accounts).length) {
        return null;
      }

      return (
        <Menu className="displaced-dd-menu">
          {Object.keys(accounts).map((key: string) => (
            <Menu.Item
              key={`menu-acc-${uuid()}`}
              className="header-menu-item"
              onClick={onActiveAccountChange(accounts[key])}
            >
              <Badge count={0} dot>
                <Avatar icon="team" style={{ backgroundColor: '#5C6466' }} />
              </Badge>
              <span style={{ marginLeft: '6px' }}>
                {accounts[key].tenantInfo.tenant_name}
              </span>
            </Menu.Item>
          ))}

          {(invites || []).map(({ tenantId, tenantName, role }: LooseObj) => (
            <Menu.Item
              key={`menu-acc-${uuid()}`}
              className="header-menu-item"
              onClick={() => onInviteClick({ tenantId, tenantName, role })}
            >
              <Badge dot>
                <Avatar icon="team" style={{ backgroundColor: '#5C6466' }} />
              </Badge>

              <span style={{ marginLeft: '6px' }}>{tenantName}</span>
            </Menu.Item>
          ))}
          <Menu.Item key="menu-acc-new" className="header-menu-item">
            <Link to="/new-account">
              <Avatar
                icon="usergroup-add"
                style={{
                  color: '#5C6466',
                  backgroundColor: '#fff',
                  border: '1px solid #5C6466',
                }}
              />
              <span style={{ marginLeft: '6px' }}>New account</span>
            </Link>
          </Menu.Item>
        </Menu>
      );
    }
    return null;
  };

  return (
    <div className={className}>
      <Link to="" onClick={handleLogoOnClick}>
        <img src={logo} alt="logo" />
      </Link>
      {isAuthenticated && !account.processing && (
        <div className="right">
          {account.accounts && Object.keys(account.accounts).length !== 0 && (
            <Dropdown overlay={accountMenu}>
              <div className="row account-dd">
                <Badge count={(user.identityInfo.invites || []).length}>
                  <Avatar icon="team" style={{ backgroundColor: '#5C6466' }} />
                </Badge>
                <div>
                  <div>
                    <div className="bold">{getAccountName()}</div>
                    <div className="grey">{getRole()}</div>
                  </div>
                </div>
              </div>
            </Dropdown>
          )}

          <Dropdown overlay={profileMenu}>
            <div className="row user-dd">
              <Avatar icon="user" style={{ backgroundColor: '#5C6466' }} />
              <div>
                <div className="bold">{getFullName()}</div>
                <div className="grey">{getDisplayName()}</div>
              </div>
            </div>
          </Dropdown>

          {/* Language dropdown */}
          {/* <Dropdown overlay={languageMenu}> */}
          {/*  <Button className="flag-dd"> */}
          {/*    <FlagIcon code={languages[0]} /> */}
          {/*  </Button> */}
          {/* /!*</Dropdown>*!/ */}
        </div>
      )}
    </div>
  );
};

const StyledHeader = styled(Header)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    cursor: pointer;
  }

  .right {
    height: 100px;
    display: flex;

    & > div {
      cursor: default;
      height: 94px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 20px;
      margin-left: ${({ theme }) => theme.space.xl};
      margin-top: ${({ theme }) => theme.space.sm};
    }

    .ant-dropdown-trigger {
      line-height: 1;
    }

    .ant-dropdown-open {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    }

    .column,
    .row {
      display: flex;
      & > {
        flex: 1;
      }
    }

    .bold,
    .grey {
      margin-left: ${({ theme }) => theme.space.sm};
    }

    .bold {
      color: ${({ theme }) => theme.color.dGrey};
      font-weight: 400;
      font-size: 16px;
    }

    .grey {
      color: ${({ theme }) => theme.color.grey};
      font-weight: 100;
      font-size: 13px;
      margin-top: 1px;
    }

    .flag-dd {
      align-self: center;
      margin-left: ${({ theme }) => theme.space.xxl};
    }

    .header-menu-item {
      padding: 5px 20px;
    }
  }
`;

const mapDispatchToProps = {
  onAccountChange: updateActiveAccount,
  onLogout: logout,
};

const mapStateToProps = (state: AppState) => ({
  account: state.account,
  user: state.user,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(StyledHeader) as React.ComponentType;
