import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AccountCreator from './AccountCreator';
import { styled } from '../../theme/Theme';
import { AccountState } from '../../store/account/types';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import { createAccount } from '../../store/user/action';
import { AppState } from '../../store';

interface DispatchProps {
  account: AccountState;
  user: UserState;
  onCreateAccount: (formData: LooseObj, fn: () => void) => {};
}

interface Props extends DispatchProps, RouteComponentProps {
  className?: string;
}

const AddAccountPage: React.FC<Props> = ({
  className,
  history,
  user,
  account,
  onCreateAccount,
}) => {
  const onSubmit = (accountName: string, invitations: LooseObj[]) => {
    const { email, name, full_name: fullName } = account.activeAccount.userInfo;

    const registerData = {
      email,
      name,
      tenant: accountName,
      fullName,
    };

    const inviteData = {
      invitations,
      fullName,
      accountName,
    };

    const existingUserProfiles = user.identityInfo.user_profiles;
    onCreateAccount(
      { registerData, inviteData, existingUserProfiles },
      onSuccess,
    );
  };

  const onSuccess = () => {
    history.push({
      pathname: '/onboarding',
    });
  };

  return (
    <div className={className}>
      <h2>Add account & members</h2>
      <AccountCreator
        startingEmailFieldsCount={5}
        callback={onSubmit}
        acknowledgeTOS={false}
      />
    </div>
  );
};

const StyledAddAccountPage = styled(AddAccountPage)`
  & > h2 {
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary};
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
  user: state.user,
});

const mapDispatchToProps = {
  onCreateAccount: createAccount,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StyledAddAccountPage) as React.ComponentType;
