import React, { FormEvent, useState, useEffect } from 'react';
import {
  Form, Input, Button, message,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { accountNameValidator } from '../../utils/inputValidators';
import { styled } from '../../theme/Theme';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { AccountState } from '../../store/account/types';
import { modifyTenant } from '../../store/account/action';
import { LooseObj } from '../../custom-types';
import TenantService from '../../services/tenant/TenantService';
import EditAccountForm from '../../components/edit-account-form/EditAccountForm';
import Members from '../../components/members/Members';
import { ROLE } from '../../app/constants';

interface DispatchProps {
  user: UserState;
  account: AccountState;
  onModifyTenant: (
    newTenantData: LooseObj,
    cb: (resp: LooseObj) => void,
    isPlatformAdmin: boolean
  ) => void;
}

interface Props extends FormComponentProps, DispatchProps, RouteComponentProps {
  className?: string;
}

const onAccountNameUpdateResponse = ({ success }: LooseObj) => {
  if (success) {
    message.success('Name changed successfully');
  } else {
    message.error('Failed to update name');
  }
};

const EditAccount: React.FC<Props> = ({
  className,
  form,
  user,
  account,
  onModifyTenant,
  history,
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldValue,
  } = form;
  const queryParams = new URLSearchParams(window.location.search);
  const tenantIdString = queryParams.get('i') || '';
  const tenantId = parseInt(tenantIdString, 10);

  const [tenantData, setTenantData] = useState<LooseObj | undefined>(undefined);

  const [membersTenantData, setMembersTenantData] = useState<
    LooseObj | undefined
  >(undefined);

  const userData = {
    email: user.identityInfo.email,
    full_name: `${user.identityInfo.first_name} ${user.identityInfo.last_name}`,
  };

  const getTenantInfoEffect = async () => {
    const {
      success,
      tenant,
      account: accountData,
    } = await TenantService.getTenantInfo(tenantIdString, true);

    if (success) {
      setTenantData({ ...tenant, ...accountData });
      setMembersTenantData({
        tenantId: tenant.tenant_id,
        tenantName: tenant.tenant_name,
        ownerEmail: tenant.administrator_contact,
        maxCreators: tenant.max_creators,
        users: tenant.users,
      });
    }
  };

  useEffect(() => {
    getTenantInfoEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMaxCreators = (newMaxCreators: number) => {
    setTenantData({ ...tenantData, max_creators: newMaxCreators });
    setMembersTenantData({ ...membersTenantData, maxCreators: newMaxCreators });
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );
    if (errorsCount.length > 0) {
      return;
    }
    const accountName = getFieldValue('account-name');

    if (accountName && tenantData && accountName !== tenantData.tenant_name) {
      onModifyTenant(
        {
          tenantId,
          newName: accountName,
        },
        onAccountNameUpdateResponse,
        true,
      );
    }
  };

  return (
    <div className={className}>
      <div className="title-container">
        <h2>Edit account</h2>
        <Button className="back-button" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>

      <div className="cols-container">
        <div className="col-1">
          <Form onSubmit={onSubmit}>
            <Form.Item label="Account Name">
              {getFieldDecorator('account-name', {
                validateTrigger: ['onBlur'],
                rules: [
                  {
                    required: true,
                    validator: accountNameValidator,
                  },
                ],
                initialValue: tenantData && tenantData.tenant_name,
              })(<Input placeholder="Account Name" />)}
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                loading={account.processing}
              >
                Save
              </Button>
            </Form.Item>
          </Form>

          {membersTenantData ? (
            <div className="members-wrapper">
              <Members
                activeUserRole={ROLE.PLATFORM_ADMIN}
                tenantData={membersTenantData}
                userData={userData}
                showAccountName={false}
              />
            </div>
          ) : null}
        </div>

        <div className="col-2">
          <EditAccountForm
            tenantInfo={tenantData}
            updateMaxCreators={updateMaxCreators}
          />
        </div>
      </div>
    </div>
  );
};

const StyledEditAccount = styled(EditAccount)`
  h2 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
    margin-left: ${({ theme }) => theme.space.xl};
  }

  .cols-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .col-1,
    .col-2 {
      width: 40%;

      .ant-form-item-control-wrapper,
      .ant-input-number,
      .ant-calendar-picker {
        width: 300px;
      }
    }

    .col-2 {
      .submit-button {
        margin-left: auto;
      }
    }
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .back-button {
    background-color: transparent;
    width: 80px;
    border: 3px solid ${({ theme }) => theme.color.primary};
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
    margin-right: ${({ theme }) => theme.space.xxxl};
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  account: state.account,
});

const mapDispatchToProps = {
  onModifyTenant: modifyTenant,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  Form.create({ name: 'edit-account-name' }),
)(StyledEditAccount) as React.ComponentType;
