import React from 'react';
import { styled } from '../../theme/Theme';

interface Props {
  className?: string;
}

const SingleCenteredContentPage: React.FC<Props> = ({
  className,
  children,
}) => <div className={className}>{children}</div>;

export default styled(SingleCenteredContentPage)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
