import { ERROR_TYPES } from '../../custom-types';

export enum BILLING {
  PROCESSING = 'BILLING_PROCESSING',
  SET_CLIENT = 'BILLING_SET_CLIENT',
  SET_TOKEN = 'BILLING_SET_TOKEN',
  ERROR = 'BILLING_ERROR',
  CLEAR = 'BILLING_CLEAR',
  CANCEL = 'BILLING_CANCEL',
  UPGRADE = 'BILLING_UPGRADE',
  DELETE_IDENTITY = 'BILLING_DELETE_IDENTITY',
  PAYMENT_SUCCESS = 'BILLING_PAYMENT_SUCCESS',
  BUY = 'BILLING_BUY'
}

export interface BillingState {
  token: string | undefined;
  client: any;

  processing: boolean;
  success: false;
  error:
    | {
        message: string;
        code: ERROR_TYPES.unknown;
      }
    | undefined;
}
