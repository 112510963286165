import React from 'react';
import { Card, Icon } from 'antd';
import { styled } from '../../theme/Theme';

interface Props {
  className?: string;
  title: string | JSX.Element;
  body: string | JSX.Element;
  iconName?: string;
  iconColor?: string;
  iconTheme?: 'outlined' | 'filled' | 'twoTone' | undefined;
  height?: string;
}

const BasicCard: React.FC<Props> = ({
  className,
  title,
  body,
  iconName = 'info-circle',
  iconTheme = 'outlined',
  height,
}: Props) => (
  <Card
    className={className}
    title={(
      <span className="icon-and-text">
        <Icon type={iconName} theme={iconTheme} />
        {' '}
        {title}
      </span>
    )}
  >
    <span className="card-body-text">{body}</span>
  </Card>
);

export default styled(BasicCard)`
  padding-right: 10px;
  .ant-card-head {
    border: none;

    .ant-card-head-title {
      display: flex;
      align-items: center;

      .icon-and-text {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;

        svg {
          fill: ${({ iconColor, theme }) => iconColor || theme.color.primary};
          font-size: ${({ theme }) => theme.font.size.lg};
          margin-right: ${({ theme }) => theme.space.lg};
        }
      }
    }
  }

  .ant-card-body {
    padding-top: 0;
    margin-left: 45px;
    display: flex;
    flex-direction: column;

    .card-body-text {
      display: inline-block;
    }
  }

  ${({ height }) => height
    && `
    height: ${height};
  `}
`;
