import React from 'react';

interface Props {
  location: Location;
}

const NoMatch: React.FC<Props> = ({ location }) => (
  <div>
    <h3>
      No match for
      {' '}
      <code>{location.pathname}</code>
    </h3>
  </div>
);

export default NoMatch;
