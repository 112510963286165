import React, { FormEvent, useEffect } from 'react';
import { Button, Input, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { styled } from '../../theme/Theme';
import { setDisplayName } from '../../store/user/action';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import { displayNameValidator } from '../../utils/inputValidators';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface OwnProps extends FormComponentProps, RouteComponentProps {
  className?: string;
}

interface DispatchProps {
  user: UserState;
  onSetDisplayName: (userData: LooseObj, fn: () => void) => void;
}

type Props = OwnProps & DispatchProps;

let tenantId: number;

const DisplayNameSetup: React.FC<Props> = ({
  className,
  form,
  history,
  onSetDisplayName,
}: Props) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
  } = form;

  const userStore = useSelector((state: AppState) => state.user);
  const firstName = userStore.identityInfo.first_name;
  const lastName = userStore.identityInfo.last_name;
  const fullName = `${firstName} ${lastName}`;
  const { cognitoUser } = userStore;
  const { email } = cognitoUser.attributes
    ? cognitoUser.attributes
    : cognitoUser.challengeParam.userAttributes;

  useEffect(() => {
    const inviteData = LocalStorageService.getInviteData();
    tenantId = inviteData.tenantId;
  }, []);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [displayName] = Object.values(getFieldsValue());

    onSetDisplayName(
      {
        tenantId,
        displayName,
        fullName,
        email,
      },
      (errorMessage?: string) => {
        if (!errorMessage) {
          LocalStorageService.clearInviteData();
          history.push('/onboarding');
        }
      },
    );
  };

  return (
    <div className={className}>
      <h1>
        Set up a display name
        <br />
        for this account
      </h1>
      <Form onSubmit={onSubmit} colon={false}>
        <Form.Item label="Display Name">
          {getFieldDecorator('display-name', {
            initialValue: (firstName + lastName)
              // eslint-disable-next-line no-useless-escape
              .replace(/[^a-zA-Z0-9.!_\-]/g, '')
              .replace(/\s+/g, ''),
            validateTrigger: ['onBlur', 'onChange'],
            rules: [
              {
                required: true,
                validator: displayNameValidator,
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item>
          <div>
            The display name&apos;s length should be between 2 and 24 characters
            and it can only contain letters, numbers and symbols .!_-
          </div>
          <Button
            type="primary"
            htmlType="submit"
            loading={userStore.processing}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const StyledDisplayNameSetup = styled(DisplayNameSetup)`
  color: ${({ theme }) => theme.color.grey};

  & > h1 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: bold;
  }

  form {
    width: 300px;

    label::before {
      display: none;
    }

    p {
      line-height: 1.6;
      margin-bottom: ${({ theme }) => theme.space.xl};
    }
  }
`;

const mapDispatchToProps = {
  onSetDisplayName: setDisplayName,
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter, // routing history
  Form.create({ name: 'set-display-name' }), // antd form HoC
)(StyledDisplayNameSetup) as React.ComponentType;
