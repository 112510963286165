import { Action, combineReducers } from 'redux';
import user from './user/reducer';
import account from './account/reducer';
import billing from './billing/reducer';
import platformAdmin from './platform-admin/reducer';

export interface ActionResponse extends Action {
  payload: any;
}

export const aggregate = (type: string, payload?: any) => ({
  type,
  payload,
});

const combinedReducers = combineReducers({
  user,
  account,
  billing,
  platformAdmin,
});

export type AppState = ReturnType<typeof combinedReducers>;

export default combinedReducers;
