import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid/v4';
import BasicCard from '../basic-card/BasicCard';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import { styled } from '../../theme/Theme';
import { declineInvite } from '../../store/user/action';

interface DispatchProps {
  user: UserState;
  onDeclineInvite: Function;
}

interface Props extends DispatchProps, RouteComponentProps {
  className?: string;
}

const InvitationNotifierCard: React.FC<Props> = ({
  className,
  user,
  history,
  onDeclineInvite,
}) => {
  const { invites } = user.identityInfo;

  const onAcceptInvite = ({ tenantId, tenantName, role }: LooseObj) => {
    LocalStorageService.setInviteData({
      tenantName,
      tenantId,
      role,
      inviterName: null,
    });
    history.push('/join');
  };

  const renderBody = (data: LooseObj) => {
    const { tenantId, tenantName } = data;
    const { email } = user.identityInfo;

    return (
      <div>
        <p>{`You were invited to join the ${tenantName} account`}</p>
        <div>
          <Button
            type="primary"
            size="small"
            onClick={() => onAcceptInvite(data)}
          >
            Accept
          </Button>
          <Button size="small" onClick={() => onDeclineInvite(tenantId, email)}>
            Decline
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={className}>
      {(invites || []).map((inviteData: LooseObj) => (
        <BasicCard
          key={uuid()}
          iconName="exclamation-circle"
          iconColor="#76B82A"
          title="New account available"
          body={renderBody(inviteData)}
        />
      ))}
    </div>
  );
};

const StyledInvitationNotifierCard = styled(InvitationNotifierCard)`
  display: flex;
  flex-direction: column;
  background-color: transparent;

  & > .ant-card {
    margin-bottom: ${({ theme }) => theme.space.lg};
    ${({ theme }) => theme.shadow};

    button {
      margin-right: ${({ theme }) => theme.space.lg};
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onDeclineInvite: declineInvite,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
)(StyledInvitationNotifierCard) as React.ElementType;
