import React, { FormEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Alert, Button, Form, Icon, Input,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { isEmpty } from 'lodash';
import { styled } from '../../theme/Theme';
import { clear, login } from '../../store/user/action';
import logo from '../../assets/images/logo.png';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface DispatchProps {
  user: UserState;
  onLogin: (email: string, pass: string, fn: () => void) => {};
  onClear: () => void;
}

interface OwnProps extends FormComponentProps {
  className: string;
}

type Props = OwnProps & DispatchProps & RouteComponentProps;

const Login: React.FC<Props> = ({
  user,
  history,
  className,
  form,
  onClear,
  onLogin,
}) => {
  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    onClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let redirectOnSuccess = '/';
  const redirectRegister = '/register';
  const redirectResetPassword = '/forgot';

  const inviteData = LocalStorageService.getInviteData();
  const wasInvited = !isEmpty(inviteData);

  if (wasInvited) {
    redirectOnSuccess = '/join';
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields({ force: true }, (errors, values) => {
      if (errors) {
        return;
      }

      const [email, password] = Object.values(values);
      const lowercaseEmail = email.toLowerCase();

      onLogin(lowercaseEmail, password, onSuccess);
    });
  };

  const onSuccess = () => {
    history.push(redirectOnSuccess);
  };

  return (
    <div className={className}>
      <div>
        <Form onSubmit={onSubmit} className="login-form" colon={false}>
          <div className="img-wrapper">
            <img src={logo} alt="logo" />
          </div>

          <Form.Item label="Email">
            {getFieldDecorator('username', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: 'Please input your username!' },
                { type: 'email', message: 'Please enter a valid Email!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />,
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: 'Please input your Password!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {user && user.error && user.error.message && (
              <Alert
                message={user && user.error && user.error.message}
                type="error"
              />
            )}
          </Form.Item>
          <Form.Item>
            <div className="no-select form-forget">
              Forgot password?
              {' '}
              <Link className="login-form-forgot" to={redirectResetPassword}>
                Reset Password
              </Link>
            </div>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={user.processing}
            >
              Log in
            </Button>

            <div className="no-select">
              Don&apos;t have a profile yet?
              {' '}
              <Link className="login-form-forgot" to={redirectRegister}>
                Sign up for free!
              </Link>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const StyledLogin = styled(Login)`
  padding: ${({ theme }) => theme.space.xl} 0;
  display: flex;
  flex-direction: column;

  .img-wrapper {
    margin-top: ${({ theme }) => theme.space.md};
    margin-bottom: ${({ theme }) => theme.space.md};
    display: flex;
    justify-content: center;
  }

  form {
    width: 300px;

    .ant-input-affix-wrapper {
      display: flex;
    }

    .login-form-button {
      margin-bottom: ${({ theme }) => theme.space.xxl};
    }

    .form-forget {
      margin-bottom: ${({ theme }) => theme.space.xl};
    }

    .ant-form-item-required::before {
      display: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer0color} inset !important;
    }

    .ant-alert {
      font-size: 13px;
    }
  }
`;

const mapDispatchToProps = {
  onLogin: login,
  onClear: clear,
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ), // redux
  withRouter, // routing history
  Form.create({ name: 'login' }), // antd form HoC
)(StyledLogin) as React.ComponentType;
