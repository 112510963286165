import React, { FC } from 'react';
import { styled } from '../../theme/Theme';
import logo from '../../assets/images/logo.png';
import BasicCard from '../basic-card/BasicCard';

interface Props {
  className?: string;
}

const RegisterFeedback: FC<Props> = ({ className }) => (
  <div className={className}>
    <div className="img-wrapper">
      <img src={logo} alt="logo" />
    </div>
    <BasicCard
      height="100px"
      title="Your registration was successful"
      body="We have sent you an email to activate your profile."
    />
  </div>
);

export default styled(RegisterFeedback)`
  padding: ${({ theme }) => theme.space.xl} 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .img-wrapper {
    margin-top: ${({ theme }) => theme.space.md};
    margin-bottom: ${({ theme }) => theme.space.md};
    display: flex;
    justify-content: center;
  }
`;
