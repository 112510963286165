import React from 'react';
import { Icon, Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { styled } from '../../theme/Theme';

interface Props extends RouteComponentProps {
  className?: string;
}

const BackButton: React.FC<Props> = ({ className, history }) => (
  <span className={className}>
    <Button className="back" onClick={() => history.goBack()}>
      <Icon type="left" />
      Back
    </Button>
  </span>
);

const StyledBackButton = styled(BackButton)`
  display: inline-block;

  button {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.color.grey};
    font-size: 1rem;
    background: none;
    transition: none;
    border: none;
    box-shadow: none;

    ::before,
    ::after {
      display: none;
    }
    :hover {
      color: ${({ theme }) => theme.color.grey};
      border: none;
      background: none;
    }

    svg {
      fill: ${({ theme }) => theme.color.grey};
      font-size: 1rem;
      margin-right: ${({ theme }) => theme.space.sm};
      padding-bottom: 1.5px;
    }
  }
`;

export default withRouter(StyledBackButton);
