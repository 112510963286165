import React, { FormEvent, useEffect, ChangeEvent } from 'react';
import {
  Form, Input, Icon, Popover, Button, message,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateIdentity, clear } from '../../store/user/action';
import { LooseObj } from '../../custom-types';
import { phoneNumberValidator } from '../../utils/inputValidators';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';

const firstNameInfo = 'Please enter your new first name';
const lastNameInfo = 'Please enter your new last name';
const phoneNumberInfo = 'Please enter your phone number with the country code';

interface DispatchProps {
  user: UserState;
  onClear: Function;
  onUpdateIdentity: (newIdentityData: LooseObj, cb: (resp: any) => void) => any;
}

interface OwnProps {
  email: any;
}

type Props = OwnProps & FormComponentProps & DispatchProps;

const ProfileSettingsForm: React.FC<Props> = ({
  user,
  email,
  form,
  onClear,
  onUpdateIdentity,
}) => {
  useEffect(() => {
    onClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
  } = form;

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const formattedVal = inputVal && inputVal.replace(/[^0-9]/g, '');
    return formattedVal;
  };

  const onSubmitProfile = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [firstName, phoneNumber, lastName] = Object.values(getFieldsValue());
    const noneDefined = !(firstName || lastName || phoneNumber);

    if (noneDefined) {
      return;
    }

    const formattedPhoneNumber = phoneNumber
      ? '+'.concat(phoneNumber.replace(/[^0-9]/g, ''))
      : null;
    const newIdentityData = {
      email,
      ...(firstName && { newFirstName: firstName }),
      ...(lastName && { newLastName: lastName }),
      ...(phoneNumber && { newPhoneNumber: formattedPhoneNumber }),
    };

    onUpdateIdentity(newIdentityData, onSuccess);
  };

  const onSuccess = (resp: any) => {
    if (resp.success) {
      message.success('Update successful');
    } else {
      const errorMessage = resp.message || 'Update failed';
      message.error(errorMessage);
    }
  };

  return (
    <Form onSubmit={onSubmitProfile} colon={false}>
      <Form.Item label="First name">
        {getFieldDecorator('firstName', {
          initialValue: user.identityInfo.first_name,
          rules: [{ required: true, message: 'Please input your first name' }],
        })(<Input placeholder="John" />)}

        <Popover content={firstNameInfo} placement="right">
          <span className="hint">
            <Icon type="info-circle" />
          </span>
        </Popover>
      </Form.Item>

      <Form.Item label="Phone number">
        {getFieldDecorator('phoneNumber', {
          validateTrigger: ['onBlur'],
          rules: [{ validator: phoneNumberValidator }],
          getValueFromEvent: onPhoneNumberChange,
          initialValue: user.identityInfo.phone_number,
        })(<Input placeholder="0045 0756 0768" />)}

        <Popover content={phoneNumberInfo} placement="right">
          <span className="hint">
            <Icon type="info-circle" />
          </span>
        </Popover>
      </Form.Item>

      <Form.Item label="Last name">
        {getFieldDecorator('lastName', {
          initialValue: user.identityInfo.last_name,
          rules: [{ required: true, message: 'Please input your last name' }],
        })(<Input placeholder="Hunter" />)}

        <Popover content={lastNameInfo} placement="right">
          <span className="hint">
            <Icon type="info-circle" />
          </span>
        </Popover>
      </Form.Item>

      <div className="button-and-suggestion">
        <p>
          If you want to change your phone number, also add the country code
        </p>
        <Button type="primary" htmlType="submit" loading={user.processing}>
          Save
        </Button>
      </div>
    </Form>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onClear: clear,
  onUpdateIdentity: updateIdentity,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  Form.create({ name: 'profile-settings' }),
)(ProfileSettingsForm) as React.ComponentType<OwnProps>;
