import { Dispatch } from 'redux';
import { message } from 'antd';
import PlatformAdminService from '../../services/platform-admin/PlatformAdminService';
import { aggregate } from '../index';
import { PLATFORM_ADMIN } from './types';
import UserService from '../../services/user/UserService';

export const getAllIdentitiesInfo = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(PLATFORM_ADMIN.PROCESSING));

    const resp = await PlatformAdminService.getAllIdentitiesInfo();

    if (!resp.success) {
      dispatch(aggregate(PLATFORM_ADMIN.ERROR, resp));
    } else {
      dispatch(
        aggregate(PLATFORM_ADMIN.GET_ALL_IDENTITIES, resp.allIdentitiesInfo),
      );
    }
  } catch (err) {
    dispatch(aggregate(PLATFORM_ADMIN.ERROR, err));
    console.error(err);
  }
};

export const getAllTenantsInfo = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(PLATFORM_ADMIN.PROCESSING));

    const resp = await PlatformAdminService.getAllTenantsInfo();

    if (!resp.success) {
      dispatch(aggregate(PLATFORM_ADMIN.ERROR, resp));
    } else {
      dispatch(aggregate(PLATFORM_ADMIN.GET_ALL_TENANTS, resp.allTenantsInfo));
    }
  } catch (err) {
    dispatch(aggregate(PLATFORM_ADMIN.ERROR, err));
    console.error(err);
  }
};

export const deleteIdentity = (
  email: string,
  tenantIdsWhereOwner: number[],
) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(PLATFORM_ADMIN.PROCESSING));

    const resp = await UserService.deleteIdentity(email, tenantIdsWhereOwner);

    if (!resp.success) {
      dispatch(aggregate(PLATFORM_ADMIN.ERROR, resp));
      message.error(`${resp.message}`);
    } else {
      dispatch(aggregate(PLATFORM_ADMIN.DELETE_IDENTITY));
      message.success(`Identity of ${email} successfully deleted.`);
      getAllIdentitiesInfo()(dispatch);
      getAllTenantsInfo()(dispatch);
    }
  } catch (err) {
    dispatch(aggregate(PLATFORM_ADMIN.ERROR, err));
    console.error(err);
  }
};
