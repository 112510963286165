import { ERROR_TYPES, LooseObj } from '../../custom-types';

export enum USER {
  GET = 'USER_GET',
  UPDATE = 'USER_UPDATE',
  PROCESSING = 'USER_PROCESSING',
  ERROR = 'USER_ERROR',
  LOGIN = 'USER_LOGIN',
  REGISTER = 'USER_REGISTER',
  CREATE = 'USER_CREATE',
  LOGOUT = 'USER_LOGOUT',
  INVITE = 'USER_INVITE',
  SET_DISPLAY_NAME = 'USER_SET_DISPLAY_NAME',
  UPDATE_IDENTITY = 'USER_UPDATE_IDENTITY',
  CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD',
  UPDATE_USER_PROFILES = 'USER_UPDATE_USER_PROFILES',
  DECLINE_INVITE = 'USER_DECLINE_INVITE',
  PUT_ID = 'USER_PUT_ID',
  CLEAR = 'USER_CLEAR'
}

export interface UserState {
  identityInfo: LooseObj;
  cognitoUser: {} | undefined;

  processing: boolean;
  success: false;
  error:
    | {
        message: string;
        code:
          | ERROR_TYPES.UserNotFoundException
          | ERROR_TYPES.NotAuthorizedException
          | ERROR_TYPES.unknown;
      }
    | undefined;
  bootstrapping: boolean;
}
