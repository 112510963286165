import React from 'react';
import BasicCard from '../basic-card/BasicCard';

const AccountEditor: React.FC = () => {
  const body = (
    <span>
      1. Give your account a name
      <br />
      2. Enter the registration data of the person you want to invite. We will
      inform the user about your invitation.
      <br />
      3. After the invited person accepted your invitation and registered, you
      will be able to create meetings with them in the Stage VR world.
      <br />
    </span>
  );

  return <BasicCard title="Set up an account" body={body} />;
};

export default AccountEditor;
