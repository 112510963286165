import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import { Spin, Button, notification } from 'antd';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../store';
import { BillingState } from '../../store/billing/types';
import {
  clear,
  init,
  buy,
  setClient,
  notifyPaymentSuccess,
} from '../../store/billing/action';
import SingleCenteredContentPage from '../singleCenteredContentPage/SingleCenteredContentPage';
import { LooseObj } from '../../custom-types';
import { styled } from '../../theme/Theme';
import { UserState } from '../../store/user/types';

interface DispatchProps {
  user: UserState;
  billing: BillingState;
  initialize: () => any;
  clearAll: () => any;
  onBuy: (planData: LooseObj) => any;
  onUpgrade: () => any;
  onCancel: () => any;
  onSetClient: (client: any) => any;
  onNotifyPaymentSuccess: (data: LooseObj) => any;
}

interface Props extends DispatchProps, RouteComponentProps {
  className?: string;
}

const BillingPage: React.FC<Props> = ({
  className,
  billing,
  user,
  initialize,
  onSetClient,
  onNotifyPaymentSuccess,
  clearAll,
  onBuy,
  location,
  history,
}) => {
  const [pending, setPending] = useState<boolean>();

  if (!pending) {
    initialize();
    setPending(true);
  }

  // braintree
  useEffect(
    () => () => {
      clearAll();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const prepareNotificationData = () => {
    const { email } = user.identityInfo;
    const { first_name: firstName, last_name: lastName } = user.identityInfo;

    const {
      companyName,
      billingCity,
      billingCountry,
      billingPostalCode,
      billingStreet,
      billingStreetNr,
      vatId,
      vatPercent,
      numberOfUsers,
      totalPrice,
      planDetails,
      hideVatId,
      hideVatPercent,
      commitment,
    } = location.state;
    const { name, storageSize, storageMetric } = planDetails;

    return {
      planName: name,
      companyName,
      ownerFullName: `${firstName} ${lastName}`,
      ownerEmail: email,
      address: {
        billingStreet,
        billingStreetNr,
        billingCity,
        billingCountry,
        billingPostalCode,
      },
      vatId,
      vatPercent,
      numberOfUsers,
      totalPrice,
      commitment,
      hideVatId,
      hideVatPercent,
      storageSize,
      storageMetric,
    };
  };

  const doBuy = () => {
    onBuy(({ success }: LooseObj) => {
      history.push('/');

      if (success) {
        notification.success({
          message: 'Payment successful',
          description:
            'We have sent you a confirmation email. To accesss your subscription information, please visit the account settings.',
          duration: 15,
        });

        const notificationData = prepareNotificationData();
        onNotifyPaymentSuccess(notificationData);
      } else {
        notification.error({
          message: 'Payment failed',
          description:
            'Something went wrong with the payment. Please try again later or, if the problem persists, contact support.',
          duration: 15,
        });
      }
    });
  };

  return (
    <Spin spinning={billing.processing}>
      <SingleCenteredContentPage>
        Payments
        <br />
        <div className={className}>
          {billing.token && (
            <>
              <DropIn
                options={{ authorization: billing.token }}
                onInstance={(instance: any) => onSetClient(instance)}
              />
              <Button className="pay-button" type="primary" onClick={doBuy}>
                Pay
              </Button>
            </>
          )}
        </div>
      </SingleCenteredContentPage>
    </Spin>
  );
};

const StyledBillingPage = styled(BillingPage)`
  .pay-button {
    display: block;
    width: 150px;
    height: 35px;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 24px auto;
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  billing: state.billing,
});

const mapDispatchToProps = {
  initialize: init,
  clearAll: clear,
  onBuy: buy,
  onSetClient: setClient,
  onNotifyPaymentSuccess: notifyPaymentSuccess,
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StyledBillingPage) as React.ComponentType;
