import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Card, Icon, List } from 'antd';
import { styled } from '../../theme/Theme';
import ExpandableText from './ExpandableText';
import UserService from '../../services/user/UserService';

interface Article {
  title: string;
  body: string;
  date: string;
}

interface NewsInput extends RouteComponentProps {
  className?: string;
  isFullPage?: boolean;
  numberOfArticlesToShow?: number;
}

const returnNullIfInputEmpty = (toCheck: string, toReturn: JSX.Element) => (toCheck !== '' ? toReturn : null);

const News: React.FC<NewsInput> = ({
  className,
  isFullPage = false,
  numberOfArticlesToShow = 2,
  history,
}: NewsInput) => {
  const [articles, setArticles] = useState<undefined | Article[]>(undefined);

  const queryParams = new URLSearchParams(history.location.search);
  const articleToExpandString = queryParams.get('i');
  const articleToExpandIndex = articleToExpandString && parseInt(articleToExpandString, 10);

  const getNews = async () => {
    const { success, news } = await UserService.getNews();

    if (success) {
      if (isFullPage) {
        setArticles(news);
      } else {
        setArticles(news.slice(0, numberOfArticlesToShow));
      }
    } else {
      setArticles([]);
    }
  };

  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      className={className}
      bordered={false}
      title={(
        <div className="card-title-container">
          <span className="icon-and-text">
            <Icon type="info-circle" />
            News
          </span>
          {isFullPage ? (
            <Link className="back" to="/">
              <Icon type="left" />
              Back
            </Link>
          ) : (
            ''
          )}
        </div>
      )}
    >
      <List
        itemLayout="vertical"
        dataSource={articles}
        loading={articles === undefined}
        renderItem={(item, index) => (
          <List.Item>
            <div className="article">
              <span
                className="article-title"
                dangerouslySetInnerHTML={{ __html: item.title }} // eslint-disable-line
              />
              {isFullPage
                ? returnNullIfInputEmpty(
                  item.body,
                  <ExpandableText
                    text={item.body}
                    className="read-more"
                    isInitiallyExpanded={index === articleToExpandIndex}
                  />,
                )
                : returnNullIfInputEmpty(
                  item.body,
                  <Link to={`/news?i=${index}`} className="read-more-link">
                      Read more
                  </Link>,
                )}
            </div>
            <span className="news-date">{item.date}</span>
          </List.Item>
        )}
      />
      {!isFullPage ? (
        <Link to="/news" className="all-news-link">
          Show all news
        </Link>
      ) : null}
    </Card>
  );
};

const StyledNews = styled(News)`
  .ant-card-head {
    border: none;

    .ant-card-head-title {
      display: flex;
      align-items: center;

      .card-title-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-right: ${({ theme }) => theme.space.lg};

        .icon-and-text {
          display: inline-flex;
          align-items: center;
          font-size: 1rem;
          font-weight: bold;
          color: ${({ theme }) => theme.color.primary};
        }

        .back {
          display: flex;
          align-items: center;
          color: ${({ theme }) => theme.color.grey};
          font-size: 1rem;

          svg {
            fill: ${({ theme }) => theme.color.grey};
            font-size: 1rem;
            margin-right: ${({ theme }) => theme.space.sm};
            padding-bottom: 1.5px;
          }
        }
      }

      svg {
        fill: ${({ theme }) => theme.color.primary};
        font-size: ${({ theme }) => theme.font.size.lg};
        margin-right: ${({ theme }) => theme.space.lg};
      }
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .ant-list-item {
      display: flex;
      flex-direction: row;
      background: ${({ theme }) => theme.color.backgroundLightGrey};
      border: 1px solid ${({ theme }) => theme.color.borderLightPrimary} !important;
      border-radius: 4px;
      margin: ${({ theme }) => theme.space.lg};
      padding: ${({ theme }) => theme.space.lg};
      position: relative;

      .article {
        padding-right: 100px;

        & > span {
          display: block;
        }
      }

      .read-more-link {
        color: ${({ theme }) => theme.color.primary};
        text-decoration: underline;
        margin-top: ${({ theme }) => theme.space.sm};
      }

      .news-date {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: ${({ theme }) => theme.space.md};
        color: ${({ theme }) => theme.color.grey};
      }

      .article-title {
        margin-bottom: ${({ theme }) => theme.space.sm};
      }
    }
  }

  .all-news-link {
    display: inline-block;
    align-self: flex-end;
    margin-right: ${({ theme }) => theme.space.lg};
    font-weight: bold;
  }
`;

export default withRouter(StyledNews);
