import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import News from '../../components/news/News';

interface Props extends RouteComponentProps {
  className?: string;
}

const NewsPage: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <News isFullPage />
  </div>
);

export default styled(NewsPage)`
  width: 100%;

  .ant-card {
    .article {
      width: 100%;
    }

    min-width: 400px;
  }
`;
