import { API } from 'aws-amplify';
// import axios from 'axios';
import { LooseObj } from '../../custom-types';
import { API_GATEWAYS } from '../aws/config';

class TenantService {
  static async getTenantInfo(
    tenantId: string,
    allMembers = false,
    requestStorage = false,
  ): Promise<any> {
    try {
      return API.get(
        API_GATEWAYS.STAGE,
        `api/admin/getTenantInfo/${tenantId}?allUsers=${allMembers}&requestStorage=${requestStorage}`,
        {},
      );
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async modifyTenant(newTenantData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/admin/modifyTenant', {
        body: newTenantData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async modifyUser(userData: LooseObj): Promise<any> {
    try {
      return API.post(API_GATEWAYS.STAGE, 'api/admin/modifyUser', {
        body: userData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getMembers(tenantId: string): Promise<any> {
    // unnecessary, since getTenantInfo now returns data about users, not only id
    try {
      const { tenant, success } = await this.getTenantInfo(tenantId, true);

      if (!success) {
        return { success };
      }

      const { users } = tenant;
      return { success: true, users };
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async deleteUser(deleteUserData: LooseObj): Promise<any> {
    try {
      // return await axios
      // .post('http://localhost:3000/api/user/deleteUser', deleteUserData)
      // .then((res) => res.data);
      return API.post(API_GATEWAYS.STAGE, 'api/user/deleteUser', {
        body: deleteUserData,
      });
    } catch (err) {
      console.error(err);
      return err;
    }
  }
}

export default TenantService;
