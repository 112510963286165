import React, { FormEvent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Form, Select, Button, notification, Icon,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { range } from 'lodash';
import uuid from 'uuid/v4';
import { styled } from '../../theme/Theme';
import { AppState } from '../../store';
import { LooseObj } from '../../custom-types';
import { notifyPlanChange } from '../../store/billing/action';
import { BILLING_PLANS } from '../../app/constants';
import { AccountState } from '../../store/account/types';
import { BillingState } from '../../store/billing/types';

const vatPercent = 19;

const { Option, OptGroup } = Select;

const plansConstantKeys = ['STARTER', 'PROFESSIONAL', 'ENTERPRISE'];

const getVat = (pricePerHost: number) => Number(((pricePerHost * 19) / 100).toFixed(2));

const getTotalNet = (pricePerHost: number, numberOfUsers: number) => pricePerHost * numberOfUsers;

const getGross = (priceNet: number, vat: number) => Number((priceNet + vat).toFixed(2));

const updatePricingDetails = (
  commitment: string,
  numberOfUsers: number,
  planData: LooseObj,
  setPricingDetails: Function,
) => {
  const multiplyIfYearly = commitment === 'yearly' ? 12 : 1;
  // commitment === 'yearly' ? planData.priceWithYearly : planData.priceWithMonthly;
  const newPricePerHost = planData.priceWithYearly;
  const totalPriceNet = getTotalNet(newPricePerHost, numberOfUsers) * multiplyIfYearly;
  const totalVat = getVat(totalPriceNet);
  const totalPriceGross = getGross(totalPriceNet, totalVat);

  setPricingDetails({
    pricePerHost: newPricePerHost,
    totalVat,
    totalPriceNet,
    totalPriceGross,
  });
};

interface DispatchProps {
  account: AccountState;
  billing: BillingState;
  onNotifyPlanChange: (
    operation: 'cancel' | 'upgrade',
    data: LooseObj,
    cb: Function
  ) => void;
}

interface Props extends RouteComponentProps, FormComponentProps, DispatchProps {
  className?: string;
}

const BillingSummaryUpdatePage: React.FC<Props> = ({
  className,
  history,
  form,
  account,
  billing,
  onNotifyPlanChange,
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
    getFieldValue,
  } = form;

  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);

  // old plan data
  const accInfo = account.activeAccount.accountInfo;
  const oldPlanName = accInfo.braintreePlanName;
  const oldPlanDetails = Object.values(BILLING_PLANS).filter(
    (plan) => plan.name === oldPlanName,
  )[0];

  const regExp = /.*_yearly/;

  let oldCommitment;
  const oldMultiplyIfYearly = oldCommitment === 'yearly' ? 12 : 1;
  const { max_creators: oldNumberOfUsers } = account.activeAccount.tenantInfo;
  let oldPricePerHost;
  let oldTotalNet;
  let oldTotalVat;
  let oldTotalGross;
  let oldCurrency;
  let oldStorageSize;
  let oldStorageMetric;

  if (oldPlanName === 'custom') {
    oldCommitment = 'Custom';
    oldPricePerHost = 'Custom';
    oldTotalNet = 'Custom';
    oldTotalVat = 'Custom';
    oldTotalGross = 'Custom';
    oldCurrency = '';
    oldStorageSize = 'Custom';
    oldStorageMetric = '';
  } else {
    oldCommitment = oldPlanName.match(regExp) ? 'yearly' : 'monthly';
    //  oldCommitment === 'yearly' ? oldPlanDetails.priceWithYearly : oldPlanDetails.priceWithMonthly;
    oldPricePerHost = oldPlanDetails.priceWithYearly;
    oldTotalNet = getTotalNet(oldPricePerHost, oldNumberOfUsers) * oldMultiplyIfYearly;
    oldTotalVat = getVat(oldTotalNet);
    oldTotalGross = getGross(oldTotalNet, oldTotalVat);
    oldCurrency = oldPlanDetails.currency;
    oldStorageSize = oldPlanDetails.storageSize;
    oldStorageMetric = oldPlanDetails.storageMetric;
  }

  // desired plan data
  const [desiredPlan, setDesiredPlan] = useState<LooseObj>(
    history.location.state.plan,
  );
  const [commitment, setCommitment] = useState(desiredPlan.commitment);
  const [additionalNrOfUsers, setAdditionalNrOfUsers] = useState<number[]>([]);

  const multiplyIfYearly = commitment === 'yearly' ? 12 : 1;
  const initialNumberOfUsers = history.location.state.plan.minHostUsers;

  const initialPricePerHost = commitment === 'yearly'
    ? desiredPlan.priceWithYearly
    : desiredPlan.priceWithMonthly;
  // eslint-disable-next-line
  const initialTotalPriceNet =
    getTotalNet(initialPricePerHost, initialNumberOfUsers) * multiplyIfYearly;
  const initialTotalVat = getVat(initialTotalPriceNet);
  const initialTotalPriceGross = getGross(
    initialTotalPriceNet,
    initialTotalVat,
  );
  const [desiredPricingDetails, setDesiredPricingDetails] = useState<LooseObj>({
    pricePerHost: initialPricePerHost,
    totalVat: initialTotalVat,
    totalPriceNet: initialTotalPriceNet,
    totalPriceGross: initialTotalPriceGross,
  });

  const handleCommitmentChange = (commitmentFieldValue: string) => {
    const numberOfUsers = getFieldValue('users');

    setCommitment(commitmentFieldValue);
    updatePricingDetails(
      commitmentFieldValue,
      numberOfUsers,
      desiredPlan,
      setDesiredPricingDetails,
    );
  };

  const handleNrUsersChange = (selectedNumberOfUsers: number) => {
    let newPlan;

    if (selectedNumberOfUsers <= BILLING_PLANS.STARTER.maxHostUsers) {
      newPlan = BILLING_PLANS.STARTER;
    } else if (
      selectedNumberOfUsers <= BILLING_PLANS.PROFESSIONAL.maxHostUsers
    ) {
      newPlan = BILLING_PLANS.PROFESSIONAL;
    } else {
      newPlan = BILLING_PLANS.ENTERPRISE;
    }

    setDesiredPlan(newPlan);
    updatePricingDetails(
      commitment,
      selectedNumberOfUsers,
      newPlan,
      setDesiredPricingDetails,
    );
  };

  const onNrUsersSearch = (val: any) => {
    const convertedValue = Number(val);

    if (isNaN(convertedValue)) {
      return;
    }

    if (
      convertedValue > BILLING_PLANS.ENTERPRISE.minHostUsers
      && !additionalNrOfUsers.includes(convertedValue)
    ) {
      setAdditionalNrOfUsers(additionalNrOfUsers.concat([convertedValue]));
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [users, commitmentFieldValue] = Object.values(
      getFieldsValue(['users', 'commitment']),
    );
    const desiredPlanName = commitmentFieldValue === 'yearly'
      ? desiredPlan.name.concat('_yearly')
      : desiredPlan.name;

    onNotifyPlanChange(
      'upgrade',
      {
        oldPlan: {
          name: oldPlanDetails ? oldPlanDetails.name : oldPlanName,
          nrHosts: account.activeAccount.tenantInfo.max_creators,
        },
        desiredPlan: {
          name: desiredPlanName,
          nrHosts: users,
        },
        totalPrice: desiredPricingDetails.totalPriceGross,
      },
      () => {
        notification.open({
          message: 'Thanks for upgrading!',
          description:
            "We will process your request within one working day.\nYou will receive a notification when it's done",
          icon: <Icon type="info-circle" style={{ color: '76B82A' }} />,
          duration: 0,
        });
        setSuccessfullySubmitted(true);
      },
    );
  };

  return (
    <div className={className}>
      <div className="intro-text">
        <h2>Purchase a STAGE license</h2>
        <p>
          The trial period for this account has ended.
          <br />
          You must activate a license plan to continue using STAGE.
        </p>
      </div>

      <div className="order">
        <Form onSubmit={onSubmit} colon={false}>
          <div className="order-content">
            <div className="col-1-container">
              <div className="col-1">
                <h4 className="plan-details">Current license</h4>
                <h3>{`${oldPlanName} STAGE license`}</h3>
                <p className="plan-details">{`up to ${oldNumberOfUsers} participants / session`}</p>
                {oldPlanName !== 'custom' ? (
                  <>
                    <p className="plan-details">{`${oldStorageSize}${oldStorageMetric} total`}</p>
                    <p className="plan-details">{`${oldPricePerHost} ${oldCurrency} per host user`}</p>
                  </>
                ) : null}

                <div className="margined">
                  <h4>Summary</h4>
                  <div>
                    <Form.Item label="Number of Hosts" className="users-input">
                      {getFieldDecorator('users-old', {
                        rules: [
                          {
                            required: true,
                            type: 'number',
                          },
                        ],
                        initialValue: oldNumberOfUsers,
                      })(<Select disabled />)}
                    </Form.Item>

                    <Form.Item label="Commitment">
                      {getFieldDecorator('commitment-old', {
                        initialValue: oldCommitment,
                      })(
                        <Select disabled>
                          <Select.Option value="monthly">
                            Monthly payment
                          </Select.Option>
                          <Select.Option value="yearly">
                            Yearly payment
                          </Select.Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>

                  <div className="price-calc-container">
                    <div className="price-calc">
                      <div className="left">Total NET</div>
                      <div className="right">
                        <span>{[oldTotalNet, oldCurrency].join(' ')}</span>
                      </div>
                    </div>

                    <div className="price-calc">
                      <div className="left">
                        VAT (
                        {vatPercent}
                        %)
                      </div>
                      <div className="right">
                        <span>{[oldTotalVat, oldCurrency].join(' ')}</span>
                      </div>
                    </div>

                    <hr />

                    <div className="price-calc-big">
                      <span>Total Gross</span>
                      <span>{[oldTotalGross, oldCurrency].join(' ')}</span>
                    </div>
                    <span className="price-calc-big-to-right">
                      {oldCommitment}
                    </span>
                  </div>
                </div>

                <Button
                  className="bordered-empty-button back-button"
                  onClick={() => history.goBack()}
                  hidden={successfullySubmitted}
                >
                  Back
                </Button>
              </div>
            </div>

            <div className="col-2-container">
              <div className="col-2">
                <h4 className="plan-details">Change your license to</h4>
                <h3>{`${desiredPlan.name} STAGE license`}</h3>
                <p className="plan-details">{`up to ${desiredPlan.participants} participants / session`}</p>
                <p className="plan-details">{`${desiredPlan.storageSize}${desiredPlan.storageMetric} total`}</p>
                <p className="plan-details">
                  {`${desiredPricingDetails.storageSize
                    / desiredPricingDetails.minHostUsers}\
                  ${desiredPricingDetails.storageMetric} per host user`}
                </p>

                <div className="margined">
                  <h4>Summary</h4>
                  <div>
                    <Form.Item label="Number of Hosts" className="users-input">
                      {getFieldDecorator('users', {
                        rules: [
                          {
                            required: true,
                            type: 'number',
                          },
                        ],
                        initialValue: desiredPlan.minHostUsers,
                      })(
                        <Select
                          showSearch
                          onSearch={onNrUsersSearch}
                          onChange={handleNrUsersChange}
                        >
                          {plansConstantKeys.map((planName: string) => {
                            const {
                              name,
                              minHostUsers,
                              maxHostUsers,
                            } = BILLING_PLANS[planName];
                            const upperUserLimit = maxHostUsers
                              ? maxHostUsers + 1
                              : minHostUsers + 1;

                            let numbersToDisplay;
                            if (name === BILLING_PLANS.ENTERPRISE.name) {
                              numbersToDisplay = [
                                ...range(minHostUsers, upperUserLimit),
                                ...additionalNrOfUsers,
                              ].sort();
                            } else {
                              numbersToDisplay = range(
                                minHostUsers,
                                upperUserLimit,
                              );
                            }

                            return (
                              <OptGroup key={uuid()} label={name}>
                                {numbersToDisplay.map((nr) => (
                                  <Option key={uuid()} value={nr}>
                                    {nr}
                                  </Option>
                                ))}
                              </OptGroup>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>

                    <Form.Item label="Commitment">
                      {getFieldDecorator('commitment', {
                        initialValue: commitment,
                      })(
                        <Select onChange={handleCommitmentChange}>
                          <Select.Option value="monthly">
                            Monthly payment
                          </Select.Option>
                          <Select.Option value="yearly">
                            Yearly payment
                          </Select.Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </div>

                  <div className="price-calc-container">
                    <div className="price-calc">
                      <div className="left">Total NET</div>
                      <div className="right">
                        <span>
                          {[
                            desiredPricingDetails.totalPriceNet,
                            desiredPlan.currency,
                          ].join(' ')}
                        </span>
                      </div>
                    </div>

                    <div className="price-calc">
                      <div className="left">
                        VAT (
                        {vatPercent}
                        %)
                      </div>
                      <div className="right">
                        <span>
                          {[
                            desiredPricingDetails.totalVat,
                            desiredPlan.currency,
                          ].join(' ')}
                        </span>
                      </div>
                    </div>

                    <hr />

                    <div className="price-calc-big">
                      <span>Total Gross</span>
                      <span>
                        {[
                          desiredPricingDetails.totalPriceGross,
                          desiredPlan.currency,
                        ].join(' ')}
                      </span>
                    </div>
                    <span className="price-calc-big-to-right">
                      {commitment}
                    </span>
                  </div>
                </div>

                <Form.Item className="submit-button-container">
                  {successfullySubmitted ? (
                    <Button
                      className="bordered-empty-button back-button to-dashboard-button"
                      onClick={() => history.push('/')}
                    >
                      Go to dashboard
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit-button"
                      loading={billing.processing}
                    >
                      Upgrade now
                    </Button>
                  )}
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

const StyledBillingSummaryPage = styled(BillingSummaryUpdatePage)`
  .intro-text {
    h2 {
      color: ${({ theme }) => theme.color.primary};
      font-size: 24px;
      font-weight: bold;
      margin-bottom: ${({ theme }) => theme.space.lg};
    }

    p {
      font-size: 14px;
    }
  }

  .order {
    .ant-form {
      background-color: ${({ theme }) => theme.color.white};
      ${({ theme }) => theme.shadow};

      .ant-form-item-required::before {
        display: none;
      }

      .order-content {
        display: flex;
        flex-direction: row;

        h4 {
          font-size: 20px;
          font-weight: bold;
        }

        .col-1,
        .col-2 {
          height: 100%;

          .space-between {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80%;
          }

          .ant-input,
          .ant-select {
            width: 225px;
          }

          .users-input {
            /* height: 120px; */
            margin-bottom: 5px;
          }

          .price-calc-container {
            margin-top: ${({ theme }) => theme.space.xxl};
            width: 250px;

            .price-calc {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .left {
                color: ${({ theme }) => theme.color.grey};
              }

              .right {
                span:first-of-type {
                  font-size: 1.2rem;
                  text-align: right;
                  margin-right: 10px;
                }

                span:nth-of-type(2) {
                  color: ${({ theme }) => theme.color.grey};
                }
              }
            }
          }

          .price-calc-big {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            span {
              display: inline-block;

              &:nth-of-type(2) {
                font-size: 1.6rem;
              }
            }
          }

          .price-calc-big-to-right {
            display: block;
            width: 100px;
            text-align: right;
            margin-left: auto;
          }

          h3 {
            font-size: 30px;
            text-transform: capitalize;
            margin: ${({ theme }) => theme.space.lg} 0;
          }

          .plan-details {
            line-height: 0.5;
            font-weight: bold;
          }

          padding: 40px;
        }

        /* .col-1 {
          width: 45%;
        } */

        .col-2 {
          height: 100%;
          ${({ theme }) => theme.shadow};
        }

        .col-1-container {
          width: 45%;
          background-color: ${({ theme }) => theme.borderColor};
        }

        .col-2-container {
          width: 55%;
        }
      }

      button {
        height: 40px;
        font-weight: bold;
      }

      .submit-button-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        /* align-items: flex-end; */
        margin: 0;

        .submit-button {
          width: 180px;

          span {
            color: ${({ theme }) => theme.color.white};
          }
        }
      }

      .bordered-empty-button {
        background-color: transparent;
        border: 3px solid ${({ theme }) => theme.color.primary};

        span {
          color: ${({ theme }) => theme.color.primary};
        }
      }

      .back-button {
        width: 80px;
      }

      .to-dashboard-button {
        width: 180px;
      }
    }
  }

  .margined {
    margin-top: ${({ theme }) => theme.space.xxl};
    margin-bottom: 24px;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.color.lGrey};
    margin: 5px;
    padding: 0;
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
  billing: state.billing,
});

const mapDispatchToProps = {
  onNotifyPlanChange: notifyPlanChange,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  Form.create({ name: 'billing-info' }),
)(StyledBillingSummaryPage) as React.ComponentType;
