import React, { useState } from 'react';
import { styled } from '../../theme/Theme';

interface Props {
  text: string;
  className?: string;
  isInitiallyExpanded?: boolean;
}

const ExpandableText: React.FC<Props> = ({
  text,
  className,
  isInitiallyExpanded = false,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (isExpanded) {
    return (
      <p className={className}>
        <span>{text}</span>
        <br />
        <button type="button" onClick={handleClick}>
          Read less
        </button>
      </p>
    );
  }
  return (
    <p className={className}>
      <button type="button" onClick={handleClick}>
        Read more
      </button>
    </p>
  );
};

export default styled(ExpandableText)`
  text-decoration: none;

  button {
    text-decoration: underline;
    color: ${({ theme }) => theme.color.primary};
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 0;
  }

  span {
    display: block;
    margin: ${({ theme }) => theme.space.lg} 0 0
      ${({ theme }) => theme.space.lg};
  }
`;
