import React, { FormEvent, useEffect, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Alert, Button, Checkbox, Form, Icon, Input,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { styled } from '../../theme/Theme';
import { clear, register } from '../../store/user/action';
import logo from '../../assets/images/logo.png';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import { phoneNumberValidator } from '../../utils/inputValidators';
import { EXTERNAL_LINKS } from '../../app/constants';

interface DispatchProps {
  user: UserState;
  onRegister: (formData: LooseObj, fn: () => void) => {};
  onClear: () => void;
}

interface OwnProps extends FormComponentProps {
  className: string;
}

type Props = OwnProps & DispatchProps & RouteComponentProps;

const Register: React.FC<Props> = ({
  user,
  className,
  form,
  history,
  onRegister,
  onClear,
}) => {
  const { getFieldDecorator, validateFields } = form;

  useEffect(() => {
    onClear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputVal = e.target.value;
    const formattedVal = inputVal && inputVal.replace(/[^0-9]/g, '');
    return formattedVal;
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields(
      ['username', 'firstName', 'lastName', 'acknowledge'],
      { force: true },
      (errors, values) => {
        if (errors) {
          return;
        }

        const formData: LooseObj = values;
        formData.username = formData.username.toLowerCase();

        if (formData.phoneNumber) {
          formData.phoneNumber = '+'.concat(
            formData.phoneNumber.replace(/[^0-9]/g, ''),
          );
        }

        const logAcceptance = (name: string, acceptedDate: any) => ({
          name,
          acceptedDate,
        });
        const acceptedDate = Date.now();
        const acknowledgments = [logAcceptance('privacy_policy', acceptedDate)];

        onRegister({ ...formData, acknowledgments }, onSuccess);
      },
    );
  };

  const onSuccess = () => {
    history.push({
      pathname: '/register/success',
    });
  };

  return (
    <div className={className}>
      <div>
        <Form onSubmit={onSubmit} className="register-form" colon={false}>
          <div className="img-wrapper">
            <img src={logo} alt="logo" />
          </div>

          <Form.Item label="Work email">
            {getFieldDecorator('username', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: 'Please input your username!' },
                { type: 'email', message: 'Please enter a valid Email!' },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />,
            )}
          </Form.Item>

          <Form.Item label="First name">
            {getFieldDecorator('firstName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: 'Please input your first name!' },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Last name">
            {getFieldDecorator('lastName', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [
                { required: true, message: 'Please input your last name!' },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label="Phone number">
            {getFieldDecorator('phoneNumber', {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [{ validator: phoneNumberValidator }],
              getValueFromEvent: onPhoneNumberChange,
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
              />,
            )}
          </Form.Item>

          <Form.Item>
            <div className="no-select form-acknowledge">
              {getFieldDecorator('acknowledge', {
                validateTrigger: ['onBlur', 'onChange'],
                valuePropName: 'checked',
                initialValue: false,
                rules: [
                  {
                    required: true,
                    transform: (value) => value || undefined,
                    type: 'boolean',
                    message:
                      'You must acknowledge having read the document in order to proceed',
                  },
                ],
              })(<Checkbox />)}
              <div>
                I agree to the
                <a
                  href={EXTERNAL_LINKS.PRIVACY_POLICY}
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Form.Item>

          <Form.Item>
            {user && user.error && user.error.message && (
              <Alert
                message={user && user.error && user.error.message}
                type="error"
              />
            )}
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="register-form-button"
            loading={user.processing}
          >
            Register
          </Button>
        </Form>
      </div>
    </div>
  );
};

const StyledRegister = styled(Register)`
  padding: ${({ theme }) => theme.space.md} 0;
  display: flex;
  flex-direction: column;

  .img-wrapper {
    margin-top: ${({ theme }) => theme.space.md};
    margin-bottom: ${({ theme }) => theme.space.md};
    display: flex;
    justify-content: center;
  }

  form {
    width: 300px;

    .form-item {
      margin-bottom: 18px;
    }

    .ant-input-affix-wrapper {
      display: flex;
    }

    .register-form-button {
      margin-bottom: ${({ theme }) => theme.space.xxl};
    }

    .form-acknowledge {
      display: flex;

      & > label {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > div {
        flex: 7;
        line-height: 1.4;

        > a {
          margin-left: ${({ theme }) => theme.space.sm};
        }
      }
    }

    .ant-form-item-required::before {
      display: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer0color} inset !important;
    }

    .ant-alert {
      font-size: 13px;
    }

    & > button[type="submit"] {
      margin-top: ${({ theme }) => theme.space.md};
    }
  }
`;

const mapDispatchToProps = {
  onRegister: register,
  onClear: clear,
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  withRouter, // routing history
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ), // redux
  Form.create({ name: 'register' }), // antd form HoC
)(StyledRegister) as React.ComponentType;
