import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Card,
  List,
  Icon,
  Popconfirm,
  message,
  Modal,
  Select,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import uuid from 'uuid/v4';
import { styled } from '../../theme/Theme';
import { LooseObj } from '../../custom-types';
import { ROLE } from '../../app/constants';
import {
  deleteUser,
  leaveAccount,
  updateActiveAccount,
  modifyUser,
  setupUserProfiles,
} from '../../store/account/action';
import InviteEditor from '../invite-editor/InviteEditor';
import { inviteUsers } from '../../store/user/action';
import { emailValidator } from '../../utils/inputValidators';

const accountTypes = [ROLE.ADMIN, ROLE.PROFESSIONAL, ROLE.GUEST];

interface Member {
  userId: number;
  email: string;
  avatar: string;
  fullName: string;
  displayName: string;
  role: string;
}

interface DispatchProps {
  onDeleteUser: (deleteUserData: LooseObj, cb: Function) => {};
  onLeaveAccount: Function;
  onUpdateActiveAccount: Function;
  onInviteUsers: Function;
  onSetupUserProfiles: Function;
  onModifyUser: (
    userData: LooseObj,
    isPlatformAdmin: boolean,
    cb: Function
  ) => void;
}

const possibleActiveUserRoles = [
  ROLE.ADMIN,
  ROLE.PROFESSIONAL,
  ROLE.GUEST,
  ROLE.PLATFORM_ADMIN,
] as const;
type PossibleActiveUserRoles = typeof possibleActiveUserRoles[number];

interface OwnProps {
  activeUserRole: PossibleActiveUserRoles;
  showAccountName?: boolean;
  className?: string;
  userData: LooseObj;
  tenantData: LooseObj;
  isUserProcessing?: boolean;
  isAccountProcessing?: boolean;
}

type Props = OwnProps & DispatchProps;

const ClickableSpanWithIcon = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  color: rgba(0, 0, 0, 0.65);

  svg {
    margin-right: ${({ theme }) => theme.space.md};
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`;

const StyledOuterCard = styled(Card)`
  .admin-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
  }

  .ant-card-body {
    padding: ${({ theme }) => theme.space.lg};
    padding-top: 1px;
  }

  .text-guest {
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: ${({ theme }) => theme.space.lg};
    margin-left: ${({ theme }) => theme.space.md};
  }

  .main-actions {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 1rem;
    border-top: 1px solid #e8e8e8;
    padding-top: ${({ theme }) => theme.space.lg};
  }
`;

const Members: React.FC<Props> = ({
  activeUserRole,
  showAccountName = true,
  className,
  onDeleteUser,
  onLeaveAccount,
  onUpdateActiveAccount,
  onSetupUserProfiles,
  onInviteUsers,
  onModifyUser,
  userData,
  tenantData,
  isUserProcessing = false,
  isAccountProcessing = false,
}) => {
  const [members, setMembers] = useState<LooseObj[]>([]);
  const [inviteModalVisible, setInviteModalVisible] = useState<boolean>(false);
  const [inviteData, setInviteData] = useState<LooseObj>({});
  const [roleSelectorMapper, setRoleSelectorMapper] = useState<LooseObj>({});
  const {
    user_id: activeUserId,
    email: activeUserEmail,
    full_name: activeUserFullName,
  } = userData;

  const isAdmin = activeUserRole === ROLE.ADMIN;
  const isProfessional = activeUserRole === ROLE.PROFESSIONAL;
  const isPlatformAdmin = activeUserRole === ROLE.PLATFORM_ADMIN;

  useEffect(() => {
    if (!isEmpty(tenantData)) {
      const membersData = tenantData.users.map((usersData: LooseObj) => ({
        userId: usersData.id,
        email: usersData.email,
        avatar: '',
        displayName: usersData.name,
        fullName: usersData.full_name,
        role: ROLE.translate(usersData.access_level),
      }));

      const filteredMembersData = activeUserRole === ROLE.GUEST
        ? membersData.filter(
          (member: LooseObj) => member.email === tenantData.ownerEmail
                || member.userId === userData.user_id,
        )
        : membersData;

      setMembers(filteredMembersData);
      setRoleSelectorMapper(
        Object.fromEntries(
          membersData.map((member: LooseObj) => [member.userId, false]),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantData]);

  const isOwner = (email: string) => email === tenantData.ownerEmail;

  const isTenantAdmin = (role: string) => role === ROLE.translate(ROLE.ADMIN);

  const isSelf = (userId: number) => userId === activeUserId;

  const shouldHideEditDelete = (
    userId: number,
    role: string,
    email: string,
  ) => {
    if (isSelf(userId)) {
      return true;
    }
    if (isPlatformAdmin) {
      if (isOwner(email)) {
        return true;
      }
      return false;
    }
    if (isOwner(activeUserEmail)) {
      return false;
    }
    if (isTenantAdmin(role)) {
      return true;
    }

    return false;
  };

  const showInviteMembers = () => {
    setInviteModalVisible(true);
  };

  const toggleRoleSelector = (userId: number) => {
    setRoleSelectorMapper({
      ...roleSelectorMapper,
      [userId]: !roleSelectorMapper[userId],
    });
  };

  const handleRoleChange = (userId: number, newRole: string) => {
    const HOST_ROLES = ['Admin', 'Professional'];

    const [modifiedUser] = members.filter(
      (usersData: LooseObj) => usersData.userId === userId,
    );
    const currentRole = modifiedUser.role;

    if (currentRole === ROLE.translate(newRole)) {
      message.warning('New role is same as the old one');
      toggleRoleSelector(userId);
    } else {
      const currentNrCreators = members.reduce(
        // eslint-disable-next-line max-len
        (nrCreators: number, usersData: LooseObj) => (HOST_ROLES.includes(usersData.role) ? nrCreators + 1 : nrCreators),
        0,
      );

      if (
        currentNrCreators < tenantData.maxCreators
        || HOST_ROLES.includes(currentRole)
      ) {
        onModifyUser(
          { tenantId: tenantData.tenantId, userId, newRole },
          isPlatformAdmin,
          ({ success }: LooseObj) => {
            if (success) {
              message.success('Role updated successfully');

              if (isPlatformAdmin) {
                const updatedMembers = members.map((user: LooseObj) => {
                  if (user.userId === userId) {
                    return { ...user, role: ROLE.translate(newRole) };
                  }
                  return { ...user };
                });

                setMembers(updatedMembers);
              }
            } else {
              message.error('Error modifying role');
            }
            toggleRoleSelector(userId);
          },
        );
      } else {
        message.error('Cannot update role, maximum number of hosts reached.');
        toggleRoleSelector(userId);
      }
    }
  };

  const inviteResponseHandler = (resp: LooseObj) => {
    if (resp.success) {
      setInviteModalVisible(false);
      message.success('Invitations sent successfully.');
    } else {
      const errorMessage = resp.message || 'Sending invitations failed.';
      message.error(errorMessage);
    }
  };

  const onSubmit = () => {
    const everyEmailIsValid = Object.values(inviteData)
      .map((pair) => emailValidator(pair.email))
      .every((value) => value === true);

    if (everyEmailIsValid) {
      const data = {
        accountId: tenantData.tenantId,
        accountName: tenantData.tenantName,
        fullName: activeUserFullName,
        invitations: Object.values(inviteData),
      };

      onInviteUsers(data, inviteResponseHandler);
    }
  };

  const handleLeaveAccount = () => {
    if (activeUserEmail === tenantData.ownerEmail) {
      message.error('The owner cannot leave the account');
    } else {
      onLeaveAccount(
        { userId: activeUserId, tenantId: tenantData.tenantId },
        onLeaveSuccess,
      );
    }
  };

  const onLeaveSuccess = ({ success, message: errorDescription }: LooseObj) => {
    if (success) {
      message.success('Successfully left the account.');

      const remainingAccountsKeys = Object.keys(userData.accounts).filter(
        (key) => key !== tenantData.tenantId.toString(),
      );
      const newActiveAccountId = remainingAccountsKeys.length > 0 ? remainingAccountsKeys[0] : null;

      if (newActiveAccountId) {
        onUpdateActiveAccount(userData.accounts[newActiveAccountId]);
      } else {
        onSetupUserProfiles();
      }
    } else {
      const errorMessage = errorDescription
        ? 'Failed to leave the account: '.concat(errorDescription)
        : 'Failed to leave the account';
      message.error(errorMessage);
    }
  };

  const handleDeleteUser = ({ userId }: LooseObj) => {
    onDeleteUser({ userId, tenantId: tenantData.tenantId }, onDeleteSuccess);
  };

  const onDeleteSuccess = ({
    success,
    message: errorDescription,
  }: LooseObj) => {
    if (success) {
      message.success('User deleted successfully');
    } else {
      const errorMessage = errorDescription
        ? 'Failed to delete user: '.concat(errorDescription)
        : 'Failed to delete user';
      message.error(errorMessage);
    }
  };

  const card = (
    <Card
      className={className}
      bordered={false}
      title={`Members (${members.length})`}
    >
      <List
        itemLayout="vertical"
        dataSource={members}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              // avatar={<Avatar src={item.avatar} />}
              avatar={
                <Avatar icon="user" style={{ backgroundColor: '#5C6466' }} />
              }
              title={item.fullName}
              description={'@'.concat(item.displayName)}
            />
            <div className="user-type-edit-delete">
              {roleSelectorMapper[item.userId] ? (
                <Select
                  onChange={(value: string) => handleRoleChange(item.userId, value)}
                  showArrow={false}
                  defaultValue={item.role}
                  disabled={isAccountProcessing}
                  onBlur={() => toggleRoleSelector(item.userId)}
                  open
                >
                  {accountTypes.map((accountType) => (
                    <Select.Option key={uuid()} value={accountType}>
                      {ROLE.translate(accountType)}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <span className="user-type">{item.role}</span>
              )}

              {isAdmin || isPlatformAdmin ? (
                <span
                  className={
                    shouldHideEditDelete(item.userId, item.role, item.email)
                      ? 'hide'
                      : ''
                  }
                >
                  <span>
                    <Icon
                      type="edit"
                      theme="filled"
                      onClick={() => toggleRoleSelector(item.userId)}
                    />
                  </span>
                  <Popconfirm
                    title={(
                      <span>
                        Are you sure you want to
                        <b>{` delete ${item.displayName} `}</b>
                        from
                        <b>{` ${tenantData.tenantName}?`}</b>
                      </span>
                    )}
                    onConfirm={() => handleDeleteUser(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span>
                      <Icon type="close-circle" theme="filled" />
                    </span>
                  </Popconfirm>
                </span>
              ) : (
                ''
              )}
            </div>
          </List.Item>
        )}
      />
      <div className="main-actions">
        {isAdmin || isProfessional ? (
          <ClickableSpanWithIcon
            className="action-proceed"
            onClick={showInviteMembers}
          >
            <Icon type="user-add" />
            Invite members
          </ClickableSpanWithIcon>
        ) : (
          <span />
        )}

        {!isPlatformAdmin ? (
          <Popconfirm
            title={(
              <span>
                Are you sure you want to
                <b>{` leave ${tenantData.tenantName}?`}</b>
              </span>
            )}
            onConfirm={handleLeaveAccount}
            okText="Yes"
            cancelText="No"
          >
            <ClickableSpanWithIcon>
              <Icon type="logout" />
              Leave account
            </ClickableSpanWithIcon>
          </Popconfirm>
        ) : null}
      </div>

      <Modal
        className={className}
        title="Invite members"
        centered
        visible={inviteModalVisible}
        okText="Send invitations"
        onOk={onSubmit}
        cancelText="Cancel"
        onCancel={() => setInviteModalVisible(false)}
        confirmLoading={isUserProcessing}
        width="600px"
        destroyOnClose
      >
        <InviteEditor
          onChange={setInviteData}
          isInModal
          onlyAllowGuests={isProfessional}
        />
      </Modal>
    </Card>
  );

  const contentForGuest = (
    <div>
      <p className="text-guest">You are a guest of the account.</p>
      <div className="main-actions">
        <span />
        <Popconfirm
          title={(
            <span>
              Are you sure you want to
              <b>{` leave ${tenantData.tenantName}?`}</b>
            </span>
          )}
          onConfirm={handleLeaveAccount}
          okText="Yes"
          cancelText="No"
        >
          <ClickableSpanWithIcon>
            <Icon type="logout" />
            Leave account
          </ClickableSpanWithIcon>
        </Popconfirm>
      </div>
    </div>
  );

  if (!showAccountName) {
    return card;
  }

  return (
    <StyledOuterCard
      title={(
        <div className="admin-card-title">
          <span>{tenantData.tenantName}</span>
          {isAdmin ? (
            <Link to="/account-settings">
              <ClickableSpanWithIcon>
                <Icon type="setting" />
                Account settings
              </ClickableSpanWithIcon>
            </Link>
          ) : (
            ''
          )}
        </div>
      )}
    >
      {activeUserRole === ROLE.GUEST ? contentForGuest : card}
    </StyledOuterCard>
  );
};

const StyledMembers = styled(Members)`
  ${({ showAccountName }) => !showAccountName
    && `
  background: transparent !important;
  
  .ant-card-head, .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }`}

  .ant-select {
    .ant-select-selection__rendered {
      width: 90px;
    }
  }

  .ant-card-body {
    padding-top: 0;
  }

  .ant-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ant-list-item-meta {
      justify-content: center;
      display: flex;
      align-items: center;
      margin: ${({ theme }) => theme.space.sm};

      .ant-list-item-meta-title {
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    .user-type-edit-delete {
      width: 200px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: ${({ theme }) => theme.color.grey};

      .user-type {
        text-transform: lowercase;
        ${({ activeUserRole, theme }) => !(activeUserRole === ROLE.ADMIN)
          && `margin-right: ${theme.space.lg};`}
      }

      & > .anticon {
        margin-left: ${({ theme }) => theme.space.md};
        cursor: pointer;
      }

      svg {
        margin-left: ${({ theme }) => theme.space.md};
        cursor: pointer;
      }

      a {
        color: ${({ theme }) => theme.color.grey};
      }
    }
  }

  .main-actions {
    .action-proceed {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .hide {
    visibility: hidden;
  }

  .ant-modal-body {
    height: 70vh;
    overflow: scroll;
  }

  .ant-modal-close-x {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      display: block;
    }
  }
`;

const mapDispatchToProps = {
  onDeleteUser: deleteUser,
  onLeaveAccount: leaveAccount,
  onUpdateActiveAccount: updateActiveAccount,
  onInviteUsers: inviteUsers,
  onModifyUser: modifyUser,
  onSetupUserProfiles: setupUserProfiles,
};

export default connect(
  null,
  mapDispatchToProps,
)(StyledMembers);
