import baseStyled, { ThemedStyledInterface } from 'styled-components';
import { darken, lighten } from 'polished';

const primary = '#76B82A';
const grey = '#7D8384';
const dGrey = '#5C6466';
const lGrey = '#BFC7C9';
const backgroundLightGrey = '#F9FBF4';
const borderLightPrimary = '#DCF0BC';
const white = '#FFFFFF';
const red = '#F5222D';
const inactive = '#E9EEF0';
const borderLight = '#DADFE0';
const green = '#2ECC40';

export const theme = {
  color: {
    primary,
    primaryLight: lighten(0.1, primary),
    primaryDark: darken(0.1, primary),
    dGrey,
    dGreyLight: lighten(0.1, dGrey),
    dGreyDark: darken(0.1, dGrey),
    lGrey,
    lGreyLight: lighten(0.1, lGrey),
    lGreyDark: darken(0.1, lGrey),
    white,
    grey,
    backgroundLightGrey,
    borderLightPrimary,
    red,
    redLight: lighten(0.1, red),
    redDark: darken(0.1, red),
    inactive,
    borderLight,
    green,
    greenLight: lighten(0.1, green),
    greenDark: darken(0.1, green),
  },
  container: {
    sidePadding: '100px',
    headerHeight: '100px',
    footerHeight: '50px',
  },
  layer0color: '#F5F8F9',
  layer1color: '#fcfcfc',
  font: {
    default: 'system-ui, sans-serif;',
    size: {
      xs: '.5rem',
      sm: '.7rem',
      md: '.8rem',
      lg: '25px',
      xl: '30px',
    },
  },
  space: {
    xs: '3px',
    sm: '6px',
    md: '10px',
    lg: '20px',
    xl: '30px',
    xxl: '50px',
    xxxl: '100px',
  },
  shadow: 'box-shadow: 0 2px 7px rgba(0, 0, 0, 0.15);',
  borderColor: '#E9EEF0',
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
