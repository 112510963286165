import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { AppState } from '../../store';
import { ROLE } from '../../app/constants';
import { UserState } from '../../store/user/types';
import { AccountState } from '../../store/account/types';
import { LooseObj } from '../../custom-types';
import AdminDashboard from './AdminDashboard';
import ProfessionalDashboard from './ProfessionalDashboard';
import GuestDashboard from './GuestDashboard';

const testStats = [
  {
    value: 3,
    maxValue: 20,
    title: 'Participants / session',
  },
  {
    value: 0,
    maxValue: 20,
    title: 'Out of 1 available project created',
  },
  {
    value: 0,
    maxValue: 20,
    title: 'Out of 1 available session held',
  },
];

interface DispatchProps {
  user: UserState;
  account: AccountState;
}

interface Props extends DispatchProps {
  className?: string;
}

const Dashboard: React.FC<Props> = ({ className, user, account }) => {
  let profileType;

  if (!isEmpty(account.activeAccount)) {
    profileType = account.activeAccount.userInfo.access_level;
  } else {
    profileType = ROLE.NO_PROFILE;
  }

  const [tenantData, setTenantData] = useState<LooseObj>({});
  const [userData, setUserData] = useState<LooseObj>({});

  useEffect(() => {
    if (!isEmpty(account.activeAccount)) {
      setTenantData({
        tenantId: account.activeAccount.tenantInfo.tenant_id,
        tenantName: account.activeAccount.tenantInfo.tenant_name,
        ownerEmail: account.activeAccount.tenantInfo.administrator_contact,
        maxCreators: account.activeAccount.tenantInfo.max_creators,
        users: account.activeAccount.tenantInfo.users,
      });
    }
  }, [account]);

  useEffect(() => {
    if (!isEmpty(account.activeAccount) && !isEmpty(user.identityInfo)) {
      setUserData({
        ...(account.activeAccount && {
          user_id:
            account.activeAccount.userInfo
            && account.activeAccount.userInfo.user_id,
          email: user.identityInfo.email,
          full_name: `${user.identityInfo.first_name} ${user.identityInfo.last_name}`,
          accounts: account.accounts,
        }),
      });
    }
  }, [account, user]);

  const noProfileDashboard = (
    <div className="no-profile">
      <h1>You do not have any profiles.</h1>
      <h2>To create an account,</h2>
      <Link to="account-setup">Click here</Link>
      <p>
        Or, if you&apos;ve received an invitation, please use the link in the
        email to create your profile.
      </p>
    </div>
  );

  const switchAccountType = (type: string) => {
    switch (type) {
      case ROLE.ADMIN:
        return (
          <AdminDashboard
            stats={testStats}
            userData={userData}
            tenantData={tenantData}
            isUserProcessing={user.processing}
            isAccountProcessing={account.processing}
          />
        );
      case ROLE.PROFESSIONAL:
        return (
          <ProfessionalDashboard
            userData={userData}
            tenantData={tenantData}
            isUserProcessing={user.processing}
            isAccountProcessing={account.processing}
          />
        );
      case ROLE.GUEST:
        return (
          <GuestDashboard
            userData={userData}
            tenantData={tenantData}
            isUserProcessing={user.processing}
            isAccountProcessing={account.processing}
          />
        );
      case ROLE.NO_PROFILE:
        return noProfileDashboard;
      default:
        return noProfileDashboard;
    }
  };

  return <div className={className}>{switchAccountType(profileType)}</div>;
};

const StyledDashboard = styled(Dashboard)`
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-col {
      width: 50%;

      & > div {
        margin-bottom: ${({ theme }) => theme.space.lg};
        ${({ theme }) => theme.shadow};
      }
    }

    .right-col {
      width: 40%;
      background-color: transparent;

      & > div:last-of-type {
        ${({ theme }) => theme.shadow};
      }
    }

    @media (max-width: 1150px) {
      flex-direction: column;
      align-items: center;

      .left-col {
        width: 70%;
      }

      .right-col {
        width: 70%;
      }

      & > div > .ant-card {
        min-width: 400px;
      }
    }
  }

  .no-profile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;

    & > h1,
    h2 {
      color: ${({ theme }) => theme.color.primary};
      font-weight: bold;
    }

    & > h1 {
      font-size: 2.5rem;
    }

    & > h2 {
      font-size: 2rem;
    }

    & > a {
      background-color: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};
      font-weight: bold;
      font-size: 1.1rem;
      padding: ${({ theme }) => theme.space.md} ${({ theme }) => theme.space.lg};
      border-radius: 5px;
    }

    & > p {
      font-size: 1rem;
      margin-top: ${({ theme }) => theme.space.xxl};
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  account: state.account,
});

export default connect(mapStateToProps)(StyledDashboard);
