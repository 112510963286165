import { API } from 'aws-amplify';
import { API_GATEWAYS } from '../aws/config';

class TenantService {
  static async getAllTenantsInfo(): Promise<any> {
    try {
      return API.get(
        API_GATEWAYS.STAGE,
        'api/platform_admin/getAllTenantsInfo',
        {},
      );
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  static async getAllIdentitiesInfo(): Promise<any> {
    try {
      return API.get(
        API_GATEWAYS.STAGE,
        'api/platform_admin/getAllIdentitiesInfo',
        {},
      );
    } catch (err) {
      console.error(err);
      return err;
    }
  }
}

export default TenantService;
