import React, { useState } from 'react';
import {
  Form, Input, Select, Popover, Icon,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import uuid from 'uuid/v4';
import { ROLE } from '../../app/constants';
import { styled } from '../../theme/Theme';
import { LooseObj } from '../../custom-types';

const RoleMapper: { [key: string]: string } = {
  Guest: ROLE.GUEST,
  Professional: ROLE.PROFESSIONAL,
  Admin: ROLE.ADMIN,
};
const invitedMembersAccountTypes = ['Admin', 'Professional', 'Guest'];
const invitedMemberInfo = 'Other members that will be invited';

interface Props extends FormComponentProps {
  className?: string;
  isInModal?: boolean;
  onlyAllowGuests?: boolean;
  startingEmailFieldsCount?: number;
  onChange: Function;
}

const InviteEditor: React.FC<Props> = ({
  className,
  startingEmailFieldsCount = 2,
  form,
  onlyAllowGuests = false,
  onChange,
}: Props) => {
  const { getFieldDecorator, getFieldValue } = form;

  const [id, setId] = useState(startingEmailFieldsCount);
  const [keys, setKeys] = useState<number[]>(
    Array.from(new Array(startingEmailFieldsCount), (val, index) => index),
  );
  const [emptyEmailFieldsCount, setEmptyEmailFieldsCount] = useState(
    startingEmailFieldsCount,
  );
  const [emailRolePairs, setEmailRolePairs] = useState<LooseObj>({});

  const initiallySelectedRole = invitedMembersAccountTypes.indexOf(
    onlyAllowGuests ? 'Guest' : 'Professional',
  );

  const addEmailField = () => {
    keys.push(id);
    setId((prevId) => prevId + 1);
    setEmptyEmailFieldsCount((prevCount) => prevCount + 1);
  };

  const removeEmailField = (k: number) => {
    if (keys.length === startingEmailFieldsCount) {
      return;
    }

    setKeys((prevKeys) => prevKeys.filter((key) => key !== k));
    setEmptyEmailFieldsCount((prevCount) => prevCount - 1);

    const updatedEmailRolePairs = { ...emailRolePairs };
    delete updatedEmailRolePairs[k];
    setEmailRolePairs(updatedEmailRolePairs);
  };

  const onEmailFieldChange = (e: React.FormEvent) => {
    const element = e.target as HTMLInputElement;
    const matches = element.id.match(/\d+$/);
    const componentId = matches ? parseInt(matches[0], 10) : -1;
    const emailFieldValue = element.value.toLowerCase();
    let updatedEmailRolePairs = emailRolePairs;

    if (emailFieldValue === '') {
      setEmptyEmailFieldsCount((prevCount) => prevCount + 1);
      removeEmailField(componentId);
    } else if (element.defaultValue === '') {
      setEmptyEmailFieldsCount((prevCount) => prevCount - 1);

      const role = getFieldValue(`role-${componentId}`).substring(1)
        || RoleMapper.Professional;

      updatedEmailRolePairs = {
        ...emailRolePairs,
        [componentId]: {
          email: emailFieldValue,
          role: RoleMapper[role],
        },
      };
      setEmailRolePairs(updatedEmailRolePairs);

      if (emptyEmailFieldsCount === 1) {
        addEmailField();
      }
    } else {
      const role = emailRolePairs[componentId].role || RoleMapper.Professional;
      updatedEmailRolePairs = {
        ...emailRolePairs,
        [componentId]: { email: emailFieldValue, role },
      };
      setEmailRolePairs(updatedEmailRolePairs);
    }

    onChange(updatedEmailRolePairs);
  };

  const onRoleChange = (value: string) => {
    const componentId = parseInt(value[0], 10);
    const role = value.substring(1);

    if (emailRolePairs[componentId]) {
      const updatedEmailRolePairs = {
        ...emailRolePairs,
        [componentId]: {
          ...emailRolePairs[componentId],
          role: RoleMapper[role],
        },
      };
      setEmailRolePairs(updatedEmailRolePairs);
      onChange(updatedEmailRolePairs);
    }
  };

  return (
    <div className={className}>
      {keys.map((key, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="email-inputs" key={`em-${index}`}>
          <Form.Item label="Email" key={key}>
            {getFieldDecorator(`email-${key}`, {
              validateTrigger: ['onBlur', 'onChange'],
              rules: [{ type: 'email', message: 'Please enter a valid Email!' }],
            })(
              <Input
                placeholder="Email"
                onChange={onEmailFieldChange}
                autoComplete="new-password"
              />,
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator(`role-${key}`, {
              initialValue: key
                .toString()
                .concat(invitedMembersAccountTypes[initiallySelectedRole]),
            })(
              <Select
                onChange={onRoleChange}
                showArrow={false}
                disabled={onlyAllowGuests}
              >
                {invitedMembersAccountTypes.map((accountType) => (
                  <Select.Option key={uuid()} value={`${key}${accountType}`}>
                    {accountType}
                  </Select.Option>
                ))}
              </Select>,
            )}
            <Popover content={invitedMemberInfo} placement="right">
              <span className="hint">
                <Icon type="info-circle" />
              </span>
            </Popover>
          </Form.Item>
        </div>
      ))}
    </div>
  );
};

const StyledInviteEditor = styled(InviteEditor)`
  .email-inputs {
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    & > div:nth-of-type(1) {
      width: 270px;
      margin-right: ${({ theme }) => theme.space.md};

      .ant-input {
        width: 270px;
        border: 1px solid ${({ theme }) => theme.color.lGrey};
        border-radius: 5px;
      }

      .ant-form-item-label {
        text-align: left;
      }
    }

    & > div:nth-of-type(2) {
      width: 130px;

      .ant-select {
        width: 110px;
        margin-right: ${({ theme }) => theme.space.md};
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

        ${({ isInModal }) => isInModal && 'position: relative;'}
      }
    }

    .hint {
      font-size: 1.1rem;
      color: ${({ theme }) => theme.color.dGrey};
      cursor: pointer;

      ${({ isInModal }) => isInModal
        && `
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      `}
    }
  }
`;

export default Form.create<Props>()(StyledInviteEditor);
