import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Landing from '../landing/Landing';
import BasicCardTitleOnly from '../../components/basic-card/BasicCardTitleOnly';
import Login from '../../components/login/Login';
import LocalStorageService from '../../services/local-storge/LocalStorageService';
import { styled } from '../../theme/Theme';
import welcome from '../../assets/images/welcome.jpg';

interface Props extends RouteComponentProps {
  className?: string;
}

const LoginPage: React.FC<Props> = ({ className }) => {
  const { tenantName, inviterName } = LocalStorageService.getInviteData();

  const leftCol = (
    <div className={className}>
      {tenantName ? (
        <BasicCardTitleOnly
          title={`You were invited to join the ${tenantName} account${
            inviterName ? ` by ${inviterName}` : ''
          }`}
          marginBottom="30px"
        />
      ) : null}

      <h2>Welcome to STAGE, the real-time 3D conferencing for professionals</h2>

      <h4>{/* subtitle content with black text style */}</h4>

      {/* static welcome image */}
      <div className="video-wrapper">
        <img
          src={welcome}
          style={{
            width: '560px',
          }}
        />
      </div>
    </div>
  );

  return (
    <Landing content={leftCol}>
      <Login />
    </Landing>
  );
};

const StyledLoginPage = styled(LoginPage)`
  h2 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: bold;
    font-size: 1.6rem;
    width: 75%;
  }

  h4 {
    font-size: 1.2rem;
    margin-top: ${({ theme }) => theme.space.xl};
    margin-bottom: ${({ theme }) => theme.space.xxl};
    width: 75%;
  }
`;

export default withRouter(StyledLoginPage);
