import React, { useState } from 'react';
import { Card, Icon, Steps } from 'antd';
import { Link } from 'react-router-dom';
import uuid from 'uuid/v4';
import { styled } from '../../theme/Theme';

interface Props {
  className?: string;
  steps: { title: string; body: JSX.Element }[];
}

const CardWithSteps: React.FC<Props> = ({ className, steps }: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const { Step } = Steps;

  return (
    <Card
      className={className}
      title={(
        <div>
          <span className="icon-and-text">
            <Icon type="question-circle" />
            {steps[currentPage].title}
          </span>
          {currentPage < steps.length - 1 ? (
            <Link className="skip-onboarding" to="/">
              Skip onboarding
            </Link>
          ) : (
            ''
          )}
        </div>
      )}
    >
      <span className="card-body-text">{steps[currentPage].body}</span>

      <div className="steps-container">
        <Steps progressDot current={currentPage}>
          {steps.map((step, index) => (
            <Step key={uuid()} title={`${index + 1} / ${steps.length}`} />
          ))}
        </Steps>

        {currentPage < steps.length - 1 ? (
          <Link
            to="#"
            className="next-button"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
            {' '}
            <Icon type="double-right" />
          </Link>
        ) : (
          <Link to="/" className="done-button">
            Done
          </Link>
        )}
      </div>
    </Card>
  );
};

export default styled(CardWithSteps)`
  .ant-card-head {
    border: none;

    .ant-card-head-title {
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .icon-and-text {
          display: flex;
          align-items: center;
          font-size: 1rem;

          svg {
            fill: ${({ theme }) => theme.color.primary};
            font-size: ${({ theme }) => theme.font.size.lg};
            margin-right: ${({ theme }) => theme.space.lg};
          }
        }
      }
    }
  }

  .ant-card-body {
    padding-top: 0;
    display: flex;
    flex-direction: column;

    .card-body-text {
      display: inline-block;
      margin-left: 45px;
      height: 180px;

      > p {
        display: flex;
        flex-direction: column;
        font-size: ${({ theme }) => theme.font.size.md};

        > span {
          display: inline-flex;
          align-items: center;
          margin-bottom: ${({ theme }) => theme.space.lg};
        }

        .download {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: ${({ theme }) => theme.color.white};
            font-size: 0.9rem;
            font-weight: bold;
            background-color: ${({ theme }) => theme.color.primary};
            border-radius: 5px;
            width: 140px;
            height: 40px;
            margin-top: ${({ theme }) => theme.space.sm};

            svg {
              font-size: 0.9rem;
              margin-right: ${({ theme }) => theme.space.md};
            }
          }
        }

        .icon-and-text {
          display: inline-flex;
          align-items: center;
          cursor: default;
          font-size: 1rem;
          font-weight: bold;

          svg {
            font-size: ${({ theme }) => theme.font.size.lg};
            margin: 0 ${({ theme }) => theme.space.md};
          }
        }

        .icon-and-text-green {
          color: ${({ theme }) => theme.color.primary};
          svg {
            fill: ${({ theme }) => theme.color.primary};
          }
        }

        .icon-and-text-grey {
          color: ${({ theme }) => theme.color.dGreyLight};
          svg {
            fill: ${({ theme }) => theme.color.dGreyLight};
          }
        }

        .two-links {
          .get-started-link {
            width: auto;
            padding: ${({ theme }) => theme.space.xs}
              ${({ theme }) => theme.space.sm};
            margin-right: ${({ theme }) => theme.space.lg};
            font-weight: bold;
            color: ${({ theme }) => theme.color.dGrey};
            border-radius: 5px;
            background-color: ${({ theme }) => theme.color.lGreyLight};
          }
        }
      }
    }

    .steps-container {
      .ant-steps {
        margin-top: ${({ theme }) => theme.space.md};
        ${({ steps }) => steps.length === 2 && 'margin-left: 30px;'}

        .ant-steps-item-content {
          width: 40px;
          text-align: left;

          .ant-steps-item-title {
            margin-left: 3px;
            font-size: ${({ theme }) => theme.font.size.sm};

            :nth-child(1) {
              margin-left: 5px;
            }
          }
        }

        .ant-steps-item-tail {
          margin-left: 8px;
          width: 100%;

          ${({ steps }) => steps.length === 4
            && `
            ::after {
            width: 93%;
            height: 1.5px;
            margin-top: 2.5px;
          }`}

          ${({ steps }) => steps.length === 2
            && `
            ::after {
            width: 98%;
            height: 1.5px;
            margin-top: 2.8px;
          }`}
        }

        .ant-steps-item-icon {
          margin-left: 10px;
        }

        .ant-steps-item:nth-child(4) {
          .ant-steps-item-content {
            width: 40px;
          }
        }

        .ant-steps-icon-dot {
          left: 0;
          top: 0;
        }

        .ant-steps-item-finish {
          .ant-steps-item-title {
            color: ${({ theme }) => theme.color.white};
          }

          .ant-steps-icon-dot {
            background-color: transparent;
            border: 1px solid ${({ theme }) => theme.color.primary};
            width: 10px;
            height: 10px;
          }

          .ant-steps-item-container {
            margin-top: 2px;

            .ant-steps-item-content {
              margin-top: 10px;
            }
          }
        }

        .ant-steps-item-wait {
          .ant-steps-item-title {
            color: ${({ theme }) => theme.color.white};
          }

          .ant-steps-icon-dot {
            background-color: transparent;
            border: 1px solid ${({ theme }) => theme.color.lGrey};
            width: 10px;
            height: 10px;
          }

          .ant-steps-item-container {
            margin-top: 4px;
          }

          .ant-steps-icon-dot {
            top: -2;
          }
        }

        .ant-steps-item-active {
          .ant-steps-item-title {
            color: ${({ theme }) => theme.color.primary};
          }

          .ant-steps-icon-dot {
            width: 10px;
            height: 10px;
          }

          .ant-steps-item-container {
            margin-top: 2px;
          }
        }
      }
    }

    .next-button,
    .done-button {
      display: inline-flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      width: 80px;
      height: 25px;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.primary};
      font-weight: bold;
      padding: ${({ theme }) => theme.space.xs} ${({ theme }) => theme.space.sm};
      border-radius: 5px;
      margin-top: 2.5px;
      ${({ steps, theme }) => (steps.length === 2
    ? 'margin-left: 5px;'
    : `margin-left: ${theme.space.xl};`)}
    }
  }

  .skip-onboarding {
    display: inline-flex;
    text-decoration: underline;
    font-size: ${({ theme }) => theme.font.size.md};
  }
`;
