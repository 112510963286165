import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Icon } from 'antd';
import { connect } from 'react-redux';
import { styled } from '../../theme/Theme';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';

const getDistanceInDays = (firstDate: any, secondDate: any) => {
  const oneDay = 24 * 60 * 60 * 1000;

  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

interface DispatchProps {
  account: AccountState;
}

interface Props extends DispatchProps {
  className?: string;
}

const Subscription: React.FC<Props> = ({ className, account }) => {
  let iconClass;
  let title;
  let body;

  const {
    braintreeSubscriptionEnd: subscriptionEnd,
  } = account.activeAccount.accountInfo;

  if (subscriptionEnd === undefined) {
    return null;
  }

  const subscriptionEndDate = new Date(subscriptionEnd);
  const currentDate = new Date();

  if (currentDate < subscriptionEndDate) {
    const daysLeft = getDistanceInDays(subscriptionEndDate, currentDate);

    title = (
      <span>
        This account has
        {' '}
        <b>
          {daysLeft}
          {' '}
more days
        </b>
        {' '}
until its license ends
      </span>
    );
    body = `You can use the STAGE Client for ${daysLeft} more days before your license expires. After this time period, you must activate a license plan to continue using STAGE.`;
    iconClass = 'positive';
  } else {
    title = <b>License expired</b>;
    body = 'You must activate a license plan to continue using STAGE.';
    iconClass = 'negative';
  }

  return (
    <Card
      className={className}
      bordered={false}
      title={(
        <span className="icon-and-text">
          <Icon type="info-circle" className={iconClass} />
          {' '}
          {title}
        </span>
      )}
    >
      <span className="card-body-text">{body}</span>
      <span>
        <Link to="/plans" className="btn-upgrade">
          Upgrade plan
        </Link>
      </span>
    </Card>
  );
};

const StyledSubscription = styled(Subscription)`
  .ant-card-head {
    border: none;

    .ant-card-head-title {
      display: flex;
      align-items: center;

      .icon-and-text {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;

        svg {
          font-size: ${({ theme }) => theme.font.size.lg};
          margin-right: ${({ theme }) => theme.space.lg};
        }
      }

      .positive {
        svg {
          fill: ${({ theme }) => theme.color.primary};
        }
      }

      .negative {
        svg {
          fill: ${({ theme }) => theme.color.red};
        }
      }
    }
  }

  .ant-card-body {
    padding-top: 0;
    margin-left: 44px;
    display: flex;
    flex-direction: column;

    .card-body-text {
      display: inline-block;
    }

    .btn-upgrade {
      display: inline-block;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.primary};
      padding: ${({ theme }) => theme.space.md} ${({ theme }) => theme.space.lg};
      border-radius: ${({ theme }) => theme.space.sm};
      font-weight: bold;
      font-size: 1rem;
      margin-top: ${({ theme }) => theme.space.xl};
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

export default connect(mapStateToProps)(StyledSubscription);
