import React from 'react';
import styled from 'styled-components';
import { useSelector, connect } from 'react-redux';
import CreateAccountInfoCard from '../../components/create-account-tenant/AccountEditorInfoCard';
import AccountEditor from '../../components/create-account-tenant/AccountEditor';
import BasicCardTitleOnly from '../../components/basic-card/BasicCardTitleOnly';
import { AppState } from '../../store';

interface Props {
  className?: string;
}

const CreateAccountTenantPage: React.FC<Props> = ({ className }) => {
  const userStore = useSelector((state: AppState) => state.user);

  return (
    <div className={className}>
      <div className="info-cards">
        <CreateAccountInfoCard />
        <BasicCardTitleOnly
          title={userStore.error && userStore.error.message}
          iconName="exclamation-circle"
          iconColor="red"
          marginBottom="30px"
          visible
        />
      </div>
      <AccountEditor />
    </div>
  );
};

const StyledCreateAccountTenantPage = styled(CreateAccountTenantPage)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80%;

  .info-cards {
    display: flex;
    flex-direction: column;

    & > .ant-card {
      width: 80%;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
      margin-bottom: ${({ theme }) => theme.space.lg};
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    .ant-card {
      width: 62%;
      min-width: 400px;
      margin-bottom: ${({ theme }) => theme.space.lg};
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(StyledCreateAccountTenantPage);
