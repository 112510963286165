import React, { FormEvent, useState } from 'react';
import {
  Form, Popover, Input, Icon, Button, Checkbox,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  accountNameValidator,
  emailValidator,
} from '../../utils/inputValidators';
import { styled } from '../../theme/Theme';
import { LooseObj } from '../../custom-types';
import { AccountState } from '../../store/account/types';
import { UserState } from '../../store/user/types';
import InviteEditor from '../../components/invite-editor/InviteEditor';
import { AppState } from '../../store';
import { EXTERNAL_LINKS } from '../../app/constants';

interface DispatchProps {
  account: AccountState;
  user: UserState;
}

interface OwnProps {
  className?: string;
  startingEmailFieldsCount?: number;
  acknowledgeTOS?: boolean;
  callback(accountName: string, invitations: LooseObj[]): void;
}

type Props = OwnProps &
  FormComponentProps &
  RouteComponentProps &
  DispatchProps;

const accountNameInfo = 'Your account name can only contain letters, numbers and symbols .!_-*/';

const AccountCreator: React.FC<Props> = ({
  className,
  form,
  history,
  user,
  account,
  callback,
  startingEmailFieldsCount = 2,
  acknowledgeTOS = true,
}) => {
  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const [inviteData, setInviteData] = useState<LooseObj>({});
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [wasSubmitted, setWasSubmitted] = useState<boolean>(false);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setWasSubmitted(true);

    validateFields({ force: true }, (errors, values) => {
      if (errors) {
        return;
      }

      const everyEmailIsValid = Object.values(inviteData)
        .map((pair) => emailValidator(pair.email))
        .every((value) => value === true);

      if (everyEmailIsValid) {
        const accountName = getFieldValue('account-name');
        const invitations = Object.values(inviteData);

        callback(accountName, invitations);
      }
    });
  };

  return (
    <div className={className}>
      <Form onSubmit={onSubmit} className="create-account-form" colon={false}>
        <Form.Item
          label="Account Name"
          {...(isPristine
            && wasSubmitted && {
            help: 'Account name length should be between 4 and 64 characters',
            validateStatus: 'error',
          })}
          {...(user.error && {
            help: user.error,
            validateStatus: 'error',
          })}
        >
          {getFieldDecorator('account-name', {
            validateTrigger: ['onBlur', 'onChange'],
            rules: [
              {
                required: true,
                validator: accountNameValidator,
              },
            ],
          })(
            <Input
              placeholder="Account Name"
              onChange={() => setIsPristine(false)}
            />,
          )}

          <Popover content={accountNameInfo} placement="right">
            <span className="hint">
              <Icon type="info-circle" />
            </span>
          </Popover>
        </Form.Item>

        <h3>Invite account members</h3>
        <InviteEditor
          onChange={setInviteData}
          startingEmailFieldsCount={startingEmailFieldsCount}
        />

        {acknowledgeTOS ? (
          <Form.Item>
            <div className="no-select form-acknowledge">
              {getFieldDecorator('acknowledge', {
                validateTrigger: ['onBlur', 'onChange'],
                valuePropName: 'checked',
                initialValue: false,
                rules: [
                  {
                    required: true,
                    transform: (value) => value || undefined,
                    type: 'boolean',
                    message:
                      'You must acknowledge having read the document in order to proceed',
                  },
                ],
              })(<Checkbox />)}
              <div className="acknowledge-text">
                I agree to the
                <a
                  href={EXTERNAL_LINKS.TERMS_OF_SERVICE}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
              </div>
            </div>
          </Form.Item>
        ) : null}

        <div className="buttons-container">
          <Button className="back-button" onClick={() => history.goBack()}>
            Back
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              loading={account.processing || user.processing}
            >
              Create Account
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

const StyledAccountCreator = styled(AccountCreator)`
  width: 50%;

  & * {
    color: ${({ theme }) => theme.color.dGrey};
  }

  h3 {
    margin-top: calc(${({ theme }) => theme.space.xl} + 10px);
    margin-bottom: ${({ theme }) => theme.space.sm};
    font-size: 1.2rem;
  }

  .ant-form {
    .ant-form-item-control {
      .ant-form-explain {
        width: 300px;
      }
    }

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ant-input {
        width: 300px;
        margin-right: ${({ theme }) => theme.space.md};
        border: 1px solid ${({ theme }) => theme.color.lGrey};
        border-radius: 5px;
      }

      .ant-select-selection--single {
        width: 110px;
        margin-right: ${({ theme }) => theme.space.md};
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      }

      .hint {
        display: inline-block;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.dGrey};
        cursor: pointer;
      }
    }

    .ant-form-item-required::before {
      display: none;
    }

    .buttons-container {
      display: flex;
      flex-direction: row;

      button {
        height: 40px;
        font-weight: bold;
      }

      .submit-button {
        width: 180px;
        ${({ acknowledgeTOS }) => acknowledgeTOS && 'margin-left: 170px;'}

        span {
          color: ${({ theme }) => theme.color.white};
        }
      }

      .back-button {
        ${({ acknowledgeTOS }) => !acknowledgeTOS && 'display: none;'}
        background-color: transparent;
        width: 80px;
        border: 3px solid ${({ theme }) => theme.color.primary};
      }

      span {
        color: ${({ theme }) => theme.color.primary};
      }
    }

    .form-acknowledge {
      display: flex;
      width: 300px;

      & > label {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > div {
        flex: 7;
        line-height: 1.4;
      }

      .acknowledge-text {
        a {
          margin-left: ${({ theme }) => theme.space.sm};
          color: ${({ theme }) => theme.color.primary};
        }
      }
    }
  }

  p {
    color: ${({ theme }) => theme.color.lGreyDark};
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
  account: state.account,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
  Form.create<Props>({ name: 'create-new-account' }),
)(StyledAccountCreator) as React.ComponentType<OwnProps>;
