import React from 'react';
import { Icon } from 'antd';
import ThumbsUp from '../../thumbs-up.svg';
import { styled } from '../../theme/Theme';
import CardWithSteps from './CardWithSteps';
import { ROLE, EXTERNAL_LINKS } from '../../app/constants';

interface Props {
  className?: string;
  type: string;
}

const page1 = (
  <p>
    <span className="download">
      1. Download the STAGE client.
      <a
        href={EXTERNAL_LINKS.STAGE_CLIENT}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Icon type="download" />
        Download
      </a>
    </span>
    <span>2. Login to client with email and password.</span>
    <span>
      3. Click on the project &quot;Welcome to STAGE&quot; and start your first
      session or access existing VR sessions.
    </span>
  </p>
);

const page2 = (
  <p>
    <span>1. Go to your Dashboard</span>
    <span>
      2. Select
      {' '}
      <span className="icon-and-text icon-and-text-green">
        <Icon type="user-add" />
        {' '}
Invite members
      </span>
    </span>
    <span>
      3. Enter the registration data of the person you want to invite. We will
      inform the user about your invitation
    </span>
  </p>
);

const page3 = (
  <p>
    <span>1. Go to your Dashboard</span>
    <span>
      2. Select
      {' '}
      <span className="icon-and-text icon-and-text-grey">
        <Icon type="setting" />
        {' '}
Account settings
      </span>
    </span>
    <span>
      3. Complete or edit your company and billing information, buy a license or
      upgrade a purchased license
    </span>
  </p>
);

const page4 = (
  <p>
    <span>
      You can create your own project with Unity or Unreal. Please visit the
      &quot;Get started&quot; sections for Unity or Unreal below, depending on
      the game engine you want to use.
    </span>
    <span className="two-links">
      <a
        href={EXTERNAL_LINKS.UNITY_CREATOR}
        target="_blank"
        rel="noopener noreferrer"
        className="get-started-link"
      >
        Get started with Unity
        <Icon type="double-right" />
      </a>
      <a
        href={EXTERNAL_LINKS.UNREAL_CREATOR}
        target="_blank"
        rel="noopener noreferrer"
        className="get-started-link"
      >
        Get started with Unreal
        <Icon type="double-right" />
      </a>
    </span>
  </p>
);

const Onboarding: React.FC<Props> = ({ className, type }) => {
  const numberOfPagesToDisplay = ((t: string) => {
    let pagesToDisplay;
    switch (t) {
      case ROLE.ADMIN:
        pagesToDisplay = 4;
        break;
      case ROLE.PROFESSIONAL:
        pagesToDisplay = 2;
        break;
      case ROLE.GUEST:
        pagesToDisplay = 1;
        break;
    }
    return pagesToDisplay;
  })(type);

  const steps = [
    {
      title: 'How to get started?',
      body: page1,
    },
    {
      title: 'How to invite account members?',
      body: page2,
    },
    {
      title: 'How to manage your account?',
      body: page3,
    },
    {
      title: 'How do you create your own content?',
      body: page4,
    },
  ].slice(0, numberOfPagesToDisplay);

  return (
    <div className={className}>
      <div className="description">
        <img src={ThumbsUp} alt="thumbs up illustration" />
        <h2>
          Welcome to the STAGE cloud, your end-to-end solution for multi user VR
          enterprise applications.
        </h2>
      </div>

      <CardWithSteps steps={steps} />
    </div>
  );
};

export default styled(Onboarding)`
  width: 600px;
  height: 400px;

  .description {
    display: flex;
    flex-direction: row;
    align-items: center;

    > h2 {
      font-weight: bold;
      font-size: calc(${({ theme }) => theme.font.size.lg} + 5px);
      color: ${({ theme }) => theme.color.primary};
      margin: 0 0 0 ${({ theme }) => theme.space.xxl};
    }
  }

  .ant-card {
    .ant-card-body {
      .steps-container {
        display: flex;
        flex-direction: row;
      }
    }
  }
`;
