import React from 'react';
import uuid from 'uuid/v4';
import { Avatar, Button, Card } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '../../theme/Theme';
import SingleCenteredContentPage from '../singleCenteredContentPage/SingleCenteredContentPage';
import { BILLING_PLANS } from '../../app/constants';
import { AppState } from '../../store';

interface Props extends RouteComponentProps {
  className?: string;
}

const formatter = Intl && Intl.NumberFormat('de-DE');

const BillingPlans: React.FC<Props> = ({ className, history }) => {
  const braintreePlanName = useSelector(
    (state: AppState) => state.account.activeAccount.accountInfo.braintreePlanName,
  );
  const isYearlySelected = true;
  // const [isYearlySelected, setIsYearlySelected] = useState<boolean>(true);
  // const onSwitchChange = () => setIsYearlySelected(!isYearlySelected);

  const PRICES = {
    STARTER:
      BILLING_PLANS.STARTER[
        isYearlySelected ? 'priceWithYearly' : 'priceWithMonthly'
      ],
    PROFESSIONAL:
      BILLING_PLANS.PROFESSIONAL[
        isYearlySelected ? 'priceWithYearly' : 'priceWithMonthly'
      ],
    ENTERPRISE:
      BILLING_PLANS.ENTERPRISE[
        isYearlySelected ? 'priceWithYearly' : 'priceWithMonthly'
      ],
  };

  const buyPlan = (event: any) => {
    const selectedPlan: string = event.target.getAttribute('data-plan');
    const planData = (BILLING_PLANS as any)[selectedPlan];
    planData.commitment = /* isYearlySelected ? */ 'yearly';

    const doUpgrade = [
      'custom',
      'starter',
      'professional',
      'enterprise',
    ].includes(braintreePlanName);
    const nextPath = `/${doUpgrade ? 'upgrade-' : ''}summary`;

    history.push(nextPath, {
      plan: planData,
    });
  };

  const renderValue = (value: any) => {
    if (typeof value == 'string') {
      return <div>{value}</div>;
    }

    if (typeof value == 'boolean') {
      return (
        <Avatar
          icon="check"
          size="small"
          style={
            value
              ? { backgroundColor: '#76B82A' }
              : { backgroundColor: 'rgba(255,255,255,0.05)' }
          }
        />
      );
    }
    return <div dangerouslySetInnerHTML={value} />;
  };

  const prepareYearlyValue = (monthly: number, hosts: number) => {
    const yearly = monthly * 12 * hosts;
    return formatter ? formatter.format(yearly) : `${yearly}`;
  };

  const plans: Array<any> = [
    {
      'Features included': {
        __html: `<h3>${BILLING_PLANS.STARTER.name}</h3><h5>${BILLING_PLANS.STARTER.description}</h5>`,
      },
      'Price / year': {
        __html: `<span class="value-bigger">\
            ${prepareYearlyValue(
    PRICES.STARTER,
    BILLING_PLANS.STARTER.minHostUsers,
  )} ${BILLING_PLANS.STARTER.currency}\
        </span><br />`,
      },
      Hosts: {
        __html: `<span class="value"> ${BILLING_PLANS.STARTER.minHostUsers}</span>`,
      },
      Storage: {
        __html: `<span class="value"> ${BILLING_PLANS.STARTER.storageSize}\
${BILLING_PLANS.STARTER.storageMetric}</span>`,
      },
      'Max participants per meeting': {
        __html: `<span class="value"> ${BILLING_PLANS.STARTER.participants}</span>`,
      },
      'Guest invitation': {
        __html: `<span class="value"> ${BILLING_PLANS.STARTER.guestInvitation} </span>`,
      },
      Solutions: false,
      'STAGE for Unity': BILLING_PLANS.STARTER.solutions.includes(
        'STAGE for Unity',
      ),
      'STAGE for Unreal': BILLING_PLANS.STARTER.solutions.includes(
        'STAGE for Unreal',
      ),
      'STAGE for Visual Components': BILLING_PLANS.STARTER.solutions.includes(
        'STAGE for Visual Components',
      ),
      'Customer Support': false,
      'Web helpdesk': BILLING_PLANS.STARTER.customerSupport.includes(
        'Web helpdesk',
      ),
      'Dedicated support': BILLING_PLANS.STARTER.customerSupport.includes(
        'Dedicated support',
      ),
    },
    {
      'Features included': {
        __html: `<h3>${BILLING_PLANS.PROFESSIONAL.name}</h3><h5>${BILLING_PLANS.PROFESSIONAL.description}</h5>`,
      },
      'Price / year': {
        __html: `<span class="value-bigger">\
            ${prepareYearlyValue(
    PRICES.PROFESSIONAL,
    BILLING_PLANS.PROFESSIONAL.minHostUsers,
  )} \
            ${BILLING_PLANS.PROFESSIONAL.currency}</span>`,
      },
      Hosts: {
        __html: `<span class="value"> ${BILLING_PLANS.PROFESSIONAL.minHostUsers}</span>`,
      },
      Storage: {
        __html: `<span class="value"> ${BILLING_PLANS.PROFESSIONAL.storageSize}\
${BILLING_PLANS.PROFESSIONAL.storageMetric}</span>`,
      },
      'Max participants per meeting': {
        __html: `<span class="value"> ${BILLING_PLANS.PROFESSIONAL.participants}</span>`,
      },
      'Guest invitation': {
        __html: `<span class="value"> ${BILLING_PLANS.PROFESSIONAL.guestInvitation} </span>`,
      },
      Solutions: false,
      'STAGE for Unity': BILLING_PLANS.PROFESSIONAL.solutions.includes(
        'STAGE for Unity',
      ),
      'STAGE for Unreal': BILLING_PLANS.PROFESSIONAL.solutions.includes(
        'STAGE for Unreal',
      ),
      'STAGE for Visual Components': BILLING_PLANS.PROFESSIONAL.solutions.includes(
        'STAGE for Visual Components',
      ),
      'Customer Support': false,
      'Web helpdesk': BILLING_PLANS.PROFESSIONAL.customerSupport.includes(
        'Web helpdesk',
      ),
      'Dedicated support': BILLING_PLANS.PROFESSIONAL.customerSupport.includes(
        'Dedicated support',
      ),
    },
    {
      'Features included': {
        __html: `<h3>${BILLING_PLANS.ENTERPRISE.name}</h3><h5>${BILLING_PLANS.ENTERPRISE.description}</h5>`,
      },
      'Price / year': {
        __html: `<span class="value-bigger">\
          ${prepareYearlyValue(
    PRICES.ENTERPRISE,
    BILLING_PLANS.ENTERPRISE.minHostUsers,
  )} \
          ${BILLING_PLANS.ENTERPRISE.currency}</span>`,
      },
      Hosts: {
        __html: `<span class="value"> ${BILLING_PLANS.ENTERPRISE.minHostUsers}</span>`,
      },
      Storage: {
        __html: `<span class="value"> ${BILLING_PLANS.ENTERPRISE.storageSize}\
${BILLING_PLANS.ENTERPRISE.storageMetric}</span>`,
      },
      'Max participants per meeting': {
        __html: `<span class="value"> ${BILLING_PLANS.ENTERPRISE.participants}</span>`,
      },
      'Guest invitation': {
        __html: `<span class="value"> ${BILLING_PLANS.ENTERPRISE.guestInvitation} </span>`,
      },
      Solutions: false,
      'STAGE for Unity': BILLING_PLANS.ENTERPRISE.solutions.includes(
        'STAGE for Unity',
      ),
      'STAGE for Unreal': BILLING_PLANS.ENTERPRISE.solutions.includes(
        'STAGE for Unreal',
      ),
      'STAGE for Visual Components': BILLING_PLANS.ENTERPRISE.solutions.includes(
        'STAGE for Visual Components',
      ),
      'Customer Support': false,
      'Web helpdesk': BILLING_PLANS.ENTERPRISE.customerSupport.includes(
        'Web helpdesk',
      ),
      'Dedicated support': BILLING_PLANS.ENTERPRISE.customerSupport.includes(
        'Dedicated support',
      ),
    },
  ];

  const renderPlans = () => {
    const keys = Object.keys(plans[0]);
    const keyValues: Array<JSX.Element> = keys.map((key: string) => (
      <div key={uuid()}>{key}</div>
    ));
    const plan0 = keys.map((key: string) => renderValue(plans[0][key]));
    const plan1 = keys.map((key: string) => renderValue(plans[1][key]));
    const plan2 = keys.map((key: string) => renderValue(plans[2][key]));

    return (
      <div className="table">
        {keys.map((key: string, i: number) => {
          const keyValue = key === 'Features included' ? '' : keyValues[i];

          return (
            <div className="row" key={uuid()} data-idx={i + 1}>
              <div className="cell">{keyValue}</div>
              <div className="cell">{plan0[i]}</div>
              <div className="cell">{plan1[i]}</div>
              <div className="cell">{plan2[i]}</div>
            </div>
          );
        })}
        <div className="row">
          <div className="cell" />
          <div className="cell">
            <Button
              type="primary"
              size="small"
              data-plan="STARTER"
              onClick={buyPlan}
            >
              Buy
            </Button>
          </div>
          <div className="cell">
            <Button
              type="primary"
              size="small"
              data-plan="PROFESSIONAL"
              onClick={buyPlan}
            >
              Buy
            </Button>
          </div>
          <div className="cell">
            <Button
              type="primary"
              size="small"
              data-plan="ENTERPRISE"
              onClick={buyPlan}
            >
              Buy
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SingleCenteredContentPage className={className}>
      <div className="title-and-switch-container">
        <h2>Purchase a STAGE license</h2>
        <div className="switch-container">
          {/* <span className={isYearlySelected ? '' : 'selected'}>Monthly</span> */}
          {/* <Switch defaultChecked onChange={onSwitchChange} /> */}
          {/* <span className={isYearlySelected ? 'selected' : ''}>Yearly</span> */}
        </div>
      </div>

      <Card>
        {renderPlans()}
        <div className="hint-sales">
          <p>Do you need a special plan?</p>
          <a href="mailto:sales@vr-on.com">Contact sales!</a>
        </div>
      </Card>
    </SingleCenteredContentPage>
  );
};

const StyledBillingPlan = styled(BillingPlans)`
  width: 100%;

  h2,
  h3,
  h5 {
    color: ${({ theme }) => theme.color.primary};
  }

  h3 {
    font-weight: 700;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  h5 {
    font-weight: 500;
  }

  h2 {
    align-self: baseline;
  }

  .ant-card {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    width: 100%;

    .hint-sales {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      margin-top: ${({ theme }) => theme.space.lg};

      a,
      p {
        display: inline-block;
      }

      p {
        color: ${({ theme }) => theme.color.grey};
        padding-right: ${({ theme }) => theme.space.sm};
      }
    }
  }

  .table {
    color: ${({ theme }) => theme.color.grey};
    padding: ${({ theme }) => theme.space.xl};
    padding-top: 0;

    display: flex;
    flex-direction: column;

    .row:first-child {
      color: ${({ theme }) => theme.color.primary};
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .cell:first-child {
        text-align: left;
      }
    }

    .cell {
      padding: ${({ theme }) => theme.space.xs} 0;
      width: 200px;
      text-align: center;
      align-self: center;
      flex: 1;
    }

    .cell:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.borderColor};
    }

    .row:nth-child(1),
    .row:nth-child(2),
    .row:nth-child(3),
    .row:nth-child(4),
    .row:nth-child(5) {
      .cell {
        padding: ${({ theme }) => theme.space.sm} 0;
      }
    }

    .row:nth-child(1),
    .row:nth-child(2),
    .row:nth-child(6),
    .row:nth-child(10) {
      border-bottom: 1px solid ${({ theme }) => theme.borderColor};
    }

    .row:nth-child(6),
    .row:nth-child(10) {
      .cell {
        padding-bottom: 15px;
      }
    }

    .row:nth-child(7),
    .row:nth-child(11) {
      .cell {
        padding-top: 15px;
      }
    }

    // intendend header
    .row:nth-child(7),
    .row:nth-child(11) {
      font-weight: 500;
      letter-spacing: 1px;
    }

    // intendet cells
    .row:nth-child(8),
    .row:nth-child(9),
    .row:nth-child(10),
    .row:nth-child(12),
    .row:nth-child(13),
    .row:nth-child(14) {
      .cell {
        padding-left: ${({ theme }) => theme.space.md};
      }
    }

    .ant-avatar {
      transform: scale(0.5);
    }

    button {
      margin-top: ${({ theme }) => theme.space.md};
      padding-right: 30px;
      padding-left: 30px;

      & > span {
        font-size: 11px;
        font-weight: 700;
      }
    }

    .subscript {
      font-size: 11px;
    }

    .value {
      font-size: 18px;
      font-weight: 600;
    }

    .value-bigger {
      font-size: 24px;
      font-weight: 600;
    }

    .invisible-text {
      visibility: hidden;
    }
  }

  .title-and-switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 ${({ theme }) => theme.space.md};

    .selected {
      color: ${({ theme }) => theme.color.primary};
    }

    h2 {
      display: inline-block;
      font-weight: 800;
    }

    span {
      font-size: 12px;
      font-weight: 800;
    }

    .switch-container {
      button {
        margin: 0 ${({ theme }) => theme.space.md};
      }
    }
  }
`;

export default withRouter(StyledBillingPlan);
