import React, { FormEvent, useState } from 'react';
import { Button, Input, Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { styled } from '../../theme/Theme';
import {
  passwordValidator,
  passwordRequirements,
} from '../../utils/PasswordUtils';
import { resetPassword } from '../../store/user/action';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import { LooseObj } from '../../custom-types';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface OwnProps extends FormComponentProps, RouteComponentProps {
  className?: string;
}

interface DispatchProps {
  user: UserState;
  onResetPassword: (resetData: LooseObj, fn: () => void) => any;
}

type Props = OwnProps & DispatchProps;

const ResetPassword: React.FC<Props> = ({
  className,
  form,
  history,
  user,
  onResetPassword,
}: Props) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
  } = form;
  const requirements: LooseObj = passwordRequirements;
  const [passwordValidation, setPasswordValidation] = useState<LooseObj>({});

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [password] = Object.values(getFieldsValue());

    const queryParams = new URLSearchParams(history.location.search);
    const email = queryParams.get('email');
    const KEY = 'confirmationCode='; // alternative implementation needed due to '#' in confirmationCode
    const { href } = (window as any).location;
    const confirmationCode = href.substr(href.indexOf(KEY) + KEY.length);
    const decodedConfirmationCode = decodeURIComponent(confirmationCode);

    onResetPassword(
      {
        email,
        confirmationCode: decodedConfirmationCode,
        newPassword: password,
      },
      (otherPath?: string) => {
        const inviteData = LocalStorageService.getInviteData();
        const wasInvited = !isEmpty(inviteData);

        if (otherPath) {
          history.push(`/${otherPath}`);
        } else if (wasInvited) {
          history.push('/join');
        } else {
          history.push('/account-setup');
        }
      },
    );
  };

  const updateRequirements = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setPasswordValidation(passwordValidator(null, value, true));
  };

  return (
    <div className={className}>
      <h1>Set your password</h1>
      <Form onSubmit={onSubmit} colon={false}>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            validateTrigger: ['onBlur', 'onChange'],
            rules: [{ validator: passwordValidator }],
          })(
            <Input.Password
              placeholder="**********"
              onChange={updateRequirements}
            />,
          )}
        </Form.Item>

        <Form.Item>
          <h4>Your password must contain:</h4>
          <ul className="requirements">
            {Object.keys(requirements).map((key) => {
              const clsName = !passwordValidation[key] ? 'missing' : 'achieved';
              return (
                <li key={key} className={clsName}>
                  {requirements[key]}
                </li>
              );
            })}
          </ul>

          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={user.processing}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const StyledResetPassword = styled(ResetPassword)`
  color: ${({ theme }) => theme.color.grey};

  & > h1 {
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.lg};
    font-weight: bold;
  }

  form {
    width: 300px;

    label::before {
      display: none;
    }

    p {
      line-height: 1.6;
      margin-bottom: ${({ theme }) => theme.space.xl};
    }
  }

  .ant-input-suffix {
    padding-right: 0 !important;
    width: 14px;
  }

  .requirements {
    list-style: none;
    padding-left: 15px;

    .missing {
      color: ${({ theme }) => theme.color.redLight};
    }

    .achieved {
      color: ${({ theme }) => theme.color.primaryDark};
    }
  }
`;

const mapDispatchToProps = {
  onResetPassword: resetPassword,
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter, // routing history
  Form.create({ name: 'create-password' }), // antd form HoC
)(StyledResetPassword) as React.ComponentType;
