import React, { FormEvent, useState } from 'react';
import { Auth } from 'aws-amplify';

import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Alert, Button, Form, Icon, Input,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { styled } from '../../theme/Theme';
import logo from '../../assets/images/logo.png';
import SingleCenteredContentPage from '../../containers/singleCenteredContentPage/SingleCenteredContentPage';

interface OwnProps extends FormComponentProps {
  className: string;
}

type Props = OwnProps & RouteComponentProps;

const ForgotPassword: React.FC<Props> = ({ history, className, form }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
  } = form;

  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setProcessing(true);
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [email] = Object.values(getFieldsValue());
    const lowercaseEmail = email.toLowerCase();

    Auth.forgotPassword(lowercaseEmail)
      .then((data) => {
        history.push('/');
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
        setProcessing(false);
      });
  };

  return (
    <SingleCenteredContentPage>
      <div className={className}>
        <div>
          <Form onSubmit={onSubmit} className="login-form" colon={false}>
            <div className="img-wrapper">
              <img src={logo} alt="logo" />
            </div>

            <Form.Item label="Email">
              {getFieldDecorator('email', {
                validateTrigger: ['onBlur', 'onChange'],
                rules: [
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please enter a valid Email!' },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                />,
              )}
            </Form.Item>
            <Form.Item>
              {error && <Alert message={error} type="error" />}
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={processing}
              >
                Reset password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </SingleCenteredContentPage>
  );
};

const StyledLogin = styled(ForgotPassword)`
  padding: ${({ theme }) => theme.space.xl} 0;
  display: flex;
  flex-direction: column;

  .img-wrapper {
    margin-top: ${({ theme }) => theme.space.md};
    margin-bottom: ${({ theme }) => theme.space.md};
    display: flex;
    justify-content: center;
  }

  form {
    width: 300px;

    .ant-input-affix-wrapper {
      display: flex;
    }

    .login-form-button {
      margin-bottom: ${({ theme }) => theme.space.xxl};
    }

    .form-forget {
      margin-bottom: ${({ theme }) => theme.space.xl};
    }

    .ant-form-item-required::before {
      display: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer0color} inset !important;
    }

    .ant-alert {
      font-size: 13px;
    }
  }
`;

export default compose(
  withRouter, // routing history
  Form.create({ name: 'login' }), // antd form HoC
)(StyledLogin) as React.ComponentType;
