import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import InitializedApp from '../containers/initialized-app/InitializedApp';
import { theme } from '../theme/Theme';
import store from '../store/Store';
import './App.css';
import AnalyticsListener from '../components/AnalyticsListener/AnalyticsListener';

const App: React.FC = () => (
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AnalyticsListener>
          <InitializedApp />
        </AnalyticsListener>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

export default App;
