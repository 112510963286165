import { Dispatch } from 'redux';

import { aggregate, AppState } from '../index';
import { BILLING } from './types';
import { ACCOUNT } from '../account/types';
import { setupUserProfiles } from '../account/action';
import BillingService from '../../services/billing/BillingService';
import { LooseObj } from '../../custom-types';

export const init = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(BILLING.PROCESSING));

    const token = await BillingService.getToken();

    if (token) {
      dispatch(aggregate(BILLING.SET_TOKEN, token));
    } else {
      dispatch(aggregate(BILLING.ERROR, token));
    }
  } catch (err) {
    console.error(err);
  }
};

export const setClient = (client: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(BILLING.SET_CLIENT, client));
  } catch (err) {
    console.error(err);
  }
};

export const clear = () => async (dispatch: Dispatch) => {
  try {
    dispatch(aggregate(BILLING.CLEAR));
  } catch (err) {
    console.error(err);
  }
};

export const buy = (cb: any) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  try {
    dispatch(aggregate(BILLING.PROCESSING));

    const customerId = getState().account.activeAccount.tenantInfo.tenant_id;
    const { state } = (window as any).history.state; // TODO decide if this is the best way
    const { billing, user } = getState();
    const { nonce } = await billing.client.requestPaymentMethod();

    const result = await BillingService.buy(nonce, {
      plan: state,
      buyer: {
        firstName: user.identityInfo.first_name,
        lastName: user.identityInfo.last_name,
        email: user.identityInfo.email || user.cognitoUser.attributes.email,
      },
      customerId,
    });

    if (result.success) {
      dispatch(aggregate(BILLING.BUY, result));
      dispatch(aggregate(ACCOUNT.UPDATE_DETAILS_SUCCESS, result.account));
      await setupUserProfiles()(dispatch);
    }

    cb(result);
  } catch (err) {
    console.error(err);
  }
};

export const notifyPlanChange = (
  operation:
    | 'upgrade'
    | 'cancel'
    | 'pay-with-invoice'
    | 'delete-identity-request',
  data: LooseObj,
  cb: Function,
) => async (dispatch: Dispatch, getState: () => AppState) => {
  try {
    dispatch(aggregate(BILLING.PROCESSING));

    const { oldPlan } = data;
    const { user } = getState();
    const account = getState().account.activeAccount;
    const accInfo = account.accountInfo;
    const tenant = account.tenantInfo;

    const address = {
      city: accInfo.billingCity,
      country: accInfo.billingCountry,
      postalCode: accInfo.billingPostalCode,
      street: accInfo.billingStreet,
      streetNr: accInfo.billingStreetNr,
    };

    const owner = tenant.users.filter(
      (userData: LooseObj) => userData.email === tenant.administrator_contact,
    )[0];
    const requester = user.identityInfo;

    if (operation === 'upgrade') {
      const { desiredPlan, totalPrice } = data;

      const result = await BillingService.sendEmail({
        operation,
        address,
        oldPlan,
        desiredPlan,
        requester: {
          fullName: `${requester.first_name} ${requester.last_name}`,
          email: user.cognitoUser.attributes.email,
        },
        owner: {
          fullName: owner.full_name,
          email: owner.email,
        },
        customerId: tenant.tenant_id,
        accountName: tenant.tenant_name,
        companyName: account.accountInfo.companyName,
        totalPrice,
      });

      dispatch(aggregate(BILLING.UPGRADE, result));
    } else if (operation === 'cancel') {
      const result = await BillingService.sendEmail({
        operation,
        address,
        requester: {
          fullName: `${requester.first_name} ${requester.last_name}`,
          email: user.cognitoUser.attributes.email,
        },
        owner: {
          fullName: owner.full_name,
          email: owner.email,
        },
        customerId: tenant.tenant_id,
        accountName: tenant.tenant_name,
        companyName: account.accountInfo.companyName,
        plan: oldPlan,
      });

      dispatch(aggregate(BILLING.CANCEL, result));
    } else if (operation === 'pay-with-invoice') {
      const { desiredPlan, totalPrice, billingAddress } = data;

      const result = await BillingService.sendEmail({
        operation,
        address: { ...billingAddress },
        oldPlan,
        desiredPlan,
        requester: {
          fullName: `${requester.first_name} ${requester.last_name}`,
          email: user.cognitoUser.attributes.email,
        },
        owner: {
          fullName: owner.full_name,
          email: owner.email,
        },
        customerId: tenant.tenant_id,
        accountName: tenant.tenant_name,
        companyName: account.accountInfo.companyName,
        totalPrice,
      });
      dispatch(aggregate(BILLING.UPGRADE, result));
    } else if (operation === 'delete-identity-request') {
      const { requesterEmail, requesterFullName } = data;

      const result = await BillingService.sendEmail({
        operation,
        requesterEmail,
        requesterFullName,
      });
      dispatch(aggregate(BILLING.DELETE_IDENTITY, result));
    }

    // await setupUserProfiles()(dispatch);
    cb();
  } catch (err) {
    console.error(err);
  }
};

export const notifyPaymentSuccess = (data: LooseObj) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  const { address, numberOfUsers, storageSize } = data;

  const {
    billingStreet,
    billingStreetNr,
    billingCity,
    billingCountry,
    billingPostalCode,
  } = address;

  const companyAddress = `${billingStreet} nr. ${billingStreetNr}, ${billingCity}, ${billingCountry}, ${billingPostalCode}`;
  const emailData: LooseObj = { ...data, companyAddress };
  delete emailData.address;

  const result = await BillingService.sendEmail({
    operation: 'payment-notification',
    ...emailData,
    storageSize: numberOfUsers * storageSize,
  });
  dispatch(aggregate(BILLING.PAYMENT_SUCCESS, result));
};

export const cancel = (customerId: string, cb: any) => async (
  dispatch: Dispatch,
  getState: () => AppState,
) => {
  try {
    dispatch(aggregate(BILLING.PROCESSING));

    const {
      account,
      // success,
    } = await BillingService.cancel(customerId);

    dispatch(aggregate(ACCOUNT.UPDATE_DETAILS_SUCCESS, account));

    await setupUserProfiles()(dispatch);

    cb();
  } catch (err) {
    console.error(err);
  }
};

// upgrade

// cancel
