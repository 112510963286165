import React, { FormEvent, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import {
  Form,
  Input,
  Popover,
  Icon,
  Button,
  Checkbox,
  message,
  Modal,
  notification,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEmpty, get } from 'lodash';
import { AppState } from '../../store';

import BackButton from '../../components/BackButton/BackButton';
import { styled } from '../../theme/Theme';
import { notifyPlanChange } from '../../store/billing/action';
import { modifyTenant, putAccountDetails } from '../../store/account/action';
import { LooseObj } from '../../custom-types';
import { accountNameValidator } from '../../utils/inputValidators';
import { AccountState } from '../../store/account/types';

const accountNameInfo = 'Account name';
const companyNameInfo = 'Company name';
const streetNumberInfo = 'Street Number';
const streetInfo = 'Street name';
const postalCodeInfo = 'Postal Code';
const cityInfo = 'City';
const countryInfo = 'Country';
const vatIdInfo = 'VAT ID';

interface DispatchProps {
  account: AccountState;
  onModifyTenant: (
    newTenantData: LooseObj,
    cb: (resp: LooseObj) => void
  ) => void;
  onUpdateAccount: Function;
  onNotifyPlanChange: (
    operation: 'cancel' | 'upgrade',
    data: LooseObj,
    cb: Function
  ) => void;
}

interface Props extends RouteComponentProps, FormComponentProps, DispatchProps {
  className?: string;
}

const inputs = (
  getFieldDecorator: Function,
  fieldName: string,
  account: LooseObj,
  disableFields = false,
) => (
  <>
    <Form.Item label="Street">
      {getFieldDecorator(`${fieldName}-street`, {
        rules: [
          {
            message: 'Please provide the Street',
          },
        ],
        initialValue: account[fieldName.concat('Street')],
      })(<Input placeholder="Street" disabled={disableFields} />)}

      <Popover content={streetInfo} placement="right" />
    </Form.Item>

    <Form.Item label="Street Number">
      {getFieldDecorator(`${fieldName}-street-number`, {
        rules: [
          {
            message: 'Please provide the Street Number',
          },
        ],
        initialValue: account[fieldName.concat('StreetNr')],
      })(<Input placeholder="Street Number" disabled={disableFields} />)}

      <Popover content={streetNumberInfo} placement="right" />
    </Form.Item>

    <Form.Item label="Postal Code">
      {getFieldDecorator(`${fieldName}-postal-code`, {
        rules: [
          {
            message: 'Please provide the Postal Code',
          },
        ],
        initialValue: account[fieldName.concat('PostalCode')],
      })(<Input placeholder="Postal Code" disabled={disableFields} />)}

      <Popover content={postalCodeInfo} placement="right" />
    </Form.Item>

    <Form.Item label="City">
      {getFieldDecorator(`${fieldName}-city`, {
        rules: [
          {
            message: 'Please provide the City',
          },
        ],
        initialValue: account[fieldName.concat('City')],
      })(<Input placeholder="City" disabled={disableFields} />)}

      <Popover content={cityInfo} placement="right" />
    </Form.Item>

    <Form.Item label="Country">
      {getFieldDecorator(`${fieldName}-country`, {
        rules: [
          {
            message: 'Please provide the Country',
          },
        ],
        initialValue: account[fieldName.concat('Country')],
      })(<Input placeholder="Country" disabled={disableFields} />)}

      <Popover content={countryInfo} placement="right" />
    </Form.Item>
  </>
);

const AccountSettings: React.FC<Props> = ({
  className,
  form,
  account,
  onModifyTenant,
  onUpdateAccount,
  onNotifyPlanChange,
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldValue,
    getFieldsValue,
    setFieldsValue,
  } = form;

  useEffect(() => {
    if (account.error) {
      message.error(account.error.message);
    }
  }, [account.error]);

  const accountInstance = account.activeAccount.accountInfo || {
    billingSameAsCompany: false,
  };

  const tenantName = get(account.activeAccount.tenantInfo, 'tenant_name');

  const [billingOrCompany, setBillingOrCompany] = useState<string>(
    accountInstance.billingSameAsCompany ? 'company' : 'billing',
  );

  const shouldShowCancelButton = accountInstance.braintreePlanName
    && accountInstance.braintreePlanName !== 'trial';

  useEffect(() => {
    setFieldsValue({
      'billing-street': accountInstance[billingOrCompany.concat('Street')],
      'billing-street-number':
        accountInstance[billingOrCompany.concat('StreetNr')],
      'billing-postal-code':
        accountInstance[billingOrCompany.concat('PostalCode')],
      'billing-city': accountInstance[billingOrCompany.concat('City')],
      'billing-country': accountInstance[billingOrCompany.concat('Country')],
    });
  }, [
    account.activeAccount.accountInfo,
    accountInstance,
    billingOrCompany,
    setFieldsValue,
  ]);

  const checkboxOnChange = (e: CheckboxChangeEvent) => {
    if (billingOrCompany === 'company') {
      setBillingOrCompany('billing');
    } else {
      setBillingOrCompany('company');
    }
  };

  const shouldSubmit = (fieldValue: string, fieldName: string) => {
    if (!fieldValue || fieldValue === accountInstance[fieldName]) {
      return false;
    }
    return true;
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();
    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );
    if (errorsCount.length > 0) {
      return;
    }
    const [
      accountName,
      companyName,
      vatId,
      companyStreet,
      companyStreetNr,
      companyPostalCode,
      companyCity,
      companyCountry,
      sameAsCompany,
      billingStreet,
      billingStreetNr,
      billingPostalCode,
      billingCity,
      billingCountry,
    ] = Object.values(getFieldsValue());

    if (
      accountName
      && accountName !== account.activeAccount.tenantInfo.tenant_name
    ) {
      onModifyTenant(
        {
          tenantId: account.activeAccount.tenantInfo.tenant_id,
          newName: accountName,
        },
        onAccountNameUpdateResponse,
      );
    }

    const data: LooseObj = {};
    if (sameAsCompany !== accountInstance.billingSameAsCompany) {
      data.billingSameAsCompany = sameAsCompany;
    }
    if (shouldSubmit(vatId, 'vatId')) {
      data.vatId = vatId;
    }
    if (shouldSubmit(companyName, 'companyName')) {
      data.companyName = companyName;
    }
    if (shouldSubmit(companyStreet, 'companyStreet')) {
      data.companyStreet = companyStreet;
    }
    if (shouldSubmit(companyStreetNr, 'companyStreetNr')) {
      data.companyStreetNr = companyStreetNr;
    }
    if (shouldSubmit(companyPostalCode, 'companyPostalCode')) {
      data.companyPostalCode = companyPostalCode;
    }
    if (shouldSubmit(companyCity, 'companyCity')) {
      data.companyCity = companyCity;
    }
    if (shouldSubmit(companyCountry, 'companyCountry')) {
      data.companyCountry = companyCountry;
    }
    if (shouldSubmit(billingStreet, 'billingStreet')) {
      data.billingStreet = billingStreet;
    }
    if (shouldSubmit(billingStreetNr, 'billingStreetNr')) {
      data.billingStreetNr = billingStreetNr;
    }
    if (shouldSubmit(billingPostalCode, 'billingPostalCode')) {
      data.billingPostalCode = billingPostalCode;
    }
    if (shouldSubmit(billingCity, 'billingCity')) {
      data.billingCity = billingCity;
    }
    if (shouldSubmit(billingCountry, 'billingCountry')) {
      data.billingCountry = billingCountry;
    }

    if (!isEmpty(data)) {
      data.id = account.activeAccount.tenantInfo.tenant_id;
      onUpdateAccount(data, { showFeedback: true });
    }
  };

  const onAccountNameUpdateResponse = ({ success }: LooseObj) => {
    if (success) {
      message.success('Name changed successfully');
    } else {
      message.error('Failed to update name');
    }
  };

  const doCancel = () => {
    Modal.confirm({
      title: 'Confirm',
      content: 'Are you sure you want to cancel the subscription?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        const { accountInfo, tenantInfo } = account.activeAccount;

        const data = {
          id: tenantInfo.tenant_id,
          companyName: accountInfo.companyName,
          vatId: accountInfo.vatId,
          vatPercent: 19,
        };

        onNotifyPlanChange(
          'cancel',
          {
            ...data,
            oldPlan: {
              name: accountInfo.braintreePlanName,
              nrHosts: tenantInfo.max_creators,
            },
          },
          () => {
            notification.open({
              message: 'Cancellation request sent',
              description:
                'A member of our team will contact you shortly in order to finalize the operation.',
              icon: <Icon type="info-circle" style={{ color: '76B82A' }} />,
              duration: 0,
            });
          },
        );
      },
    });
  };

  return (
    <div className={className}>
      <div className="horizontal-container space-between">
        <h2>Account settings</h2>
        <BackButton />
      </div>
      <div className="horizontal-container">
        <div className="form-container">
          <Form onSubmit={onSubmit} colon={false}>
            <div className="upper">
              <Form.Item label="Account Name">
                {getFieldDecorator('account-name', {
                  validateTrigger: ['onBlur'],
                  rules: [
                    {
                      required: true,
                      validator: accountNameValidator,
                    },
                  ],
                  initialValue: tenantName,
                })(<Input placeholder="Account Name" />)}

                <Popover content={accountNameInfo} placement="right" />
              </Form.Item>

              <h4>Billing information</h4>
              <div className="two-col">
                <Form.Item label="Company Name">
                  {getFieldDecorator('company-name', {
                    rules: [
                      {
                        message: 'Please provide the Company Name',
                      },
                    ],
                    initialValue: accountInstance.companyName,
                  })(<Input placeholder="Company Name" />)}

                  <Popover content={companyNameInfo} placement="right" />
                </Form.Item>

                <Form.Item label="VAT ID">
                  {getFieldDecorator('vat-id', {
                    rules: [
                      {
                        message: 'Please provide the VAT ID',
                      },
                    ],
                    initialValue: accountInstance.vatId,
                  })(<Input placeholder="VAT ID" />)}

                  <Popover content={vatIdInfo} placement="right" />
                </Form.Item>
              </div>
            </div>

            <div className="lower two-col">
              <div className="left">
                <div className="fixed-height">
                  <h4>Company Address</h4>
                </div>
                {inputs(getFieldDecorator, 'company', accountInstance)}
              </div>
              <div className="right">
                <div className="fixed-height">
                  <h4>Billing Address</h4>
                  <Form.Item>
                    {getFieldDecorator('billing-same-as-company', {
                      validateTrigger: ['onBlur', 'onChange'],
                      valuePropName: 'checked',
                      initialValue: accountInstance.billingSameAsCompany,
                    })(
                      <Checkbox onChange={checkboxOnChange}>
                        Same as company address
                      </Checkbox>,
                    )}
                  </Form.Item>
                </div>

                {inputs(
                  getFieldDecorator,
                  'billing',
                  account,
                  getFieldValue('billing-same-as-company'),
                )}
              </div>
            </div>

            <div className="buttons-container">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  loading={account.processing}
                >
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>

          {/* <div className="invoices"> */}
          {/*  <h3>Invoices</h3> */}
          {/*  <Table */}
          {/*    columns={invoiceTableColumns} */}
          {/*    dataSource={dummyInvoiceData} */}
          {/*    size="small" */}
          {/*    bordered */}
          {/*    rowSelection={rowSelection} */}
          {/*    pagination={{ pageSize: 4 }} */}
          {/*  /> */}
          {/*  <Button className="grey-button"> */}
          {/*    <Icon type="download" /> */}
          {/*    Download selected */}
          {/*  </Button> */}
          {/*  <Button className="grey-button delete-button"> */}
          {/*    <Icon type="delete" /> */}
          {/*    Delete account */}
          {/*  </Button> */}
          {/* </div> */}
        </div>

        <div className="subscription">
          <div>
            <h4>Subscription:</h4>

            <h4 style={{ fontWeight: 'bold' }}>
              {accountInstance.braintreePlanName || 'No active plan'}
            </h4>
            <Link to="/plans">
              <Button className="upgrade-button" htmlType="submit">
                Upgrade plan
              </Button>
            </Link>
            {shouldShowCancelButton && (
              <Button className="grey-button cancel-button" onClick={doCancel}>
                <Icon type="close" />
                Cancel subscription
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledAccountSettings = styled(AccountSettings)`
  .horizontal-container {
    display: flex;
    flex-direction: row;
  }

  .space-between {
    justify-content: space-between;
  }

  h2 {
    font-size: 1.4rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color.primary};
    margin-bottom: ${({ theme }) => theme.space.md};
  }

  h4 {
    font-size: 1.1rem;
  }

  .form-container {
    flex: 3;
  }

  .subscription {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 75px;

    h4::first-letter {
      text-transform: capitalize;
    }
  }

  .two-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .upper {
    & > div {
      margin-bottom: ${({ theme }) => theme.space.xxl};
    }
  }

  .ant-form-item {
    width: 300px;

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      align-items: center;

      .ant-input {
        margin-right: ${({ theme }) => theme.space.md};
        border: 1px solid ${({ theme }) => theme.color.lGrey};
        border-radius: 5px;
      }

      .hint {
        display: inline-block;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.dGrey};
        cursor: pointer;
      }
    }
  }

  .ant-form-item-required::before {
    display: none;
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .ant-form-item {
      width: auto;
      margin-right: 28px;

      button {
        width: 100px;
      }
    }
  }

  .upgrade-button,
  .grey-button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
  }

  .upgrade-button,
  .cancel-button {
    width: 180px;
    margin-top: ${({ theme }) => theme.space.xl};
  }

  .upgrade-button {
    height: 40px;
    font-size: 16px;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primary};

    :hover {
      border: none;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.primaryLight};
      transform: none;
    }
  }

  .grey-button {
    height: 25px;
    color: ${({ theme }) => theme.color.dGrey};
    background-color: ${({ theme }) => theme.borderColor};
    transition: none;
    border: none;

    :hover {
      border: none;
      color: ${({ theme }) => theme.color.dGreyLight};
      background-color: ${({ theme }) => theme.borderColor};
      transform: none;
    }
  }

  .delete-button {
    margin-top: ${({ theme }) => theme.space.xxl};
  }

  .submit-button {
    font-weight: bold;

    span {
      color: ${({ theme }) => theme.color.white};
    }
  }

  .fixed-height {
    height: 80px;
  }

  .invoices {
    margin-top: ${({ theme }) => theme.space.xxxl};

    .ant-table-wrapper {
      .ant-table {
        .ant-table-body {
          margin: 0 !important;
        }

        thead {
          background-color: ${({ theme }) => theme.layer0color};

          span {
            font-weight: 600;
          }
        }

        tbody {
          background-color: ${({ theme }) => theme.color.white};
        }
      }

      .ant-pagination-item {
        display: none;
      }
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

const mapDispatchToProps = {
  onModifyTenant: modifyTenant,
  onUpdateAccount: putAccountDetails,
  onNotifyPlanChange: notifyPlanChange,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  Form.create({ name: 'account-settings' }),
)(StyledAccountSettings) as React.ComponentType;

// const invoiceTableColumns = [
//   {
//     title: 'Invoice',
//     dataIndex: 'invoice',
//   },
//   {
//     title: 'Date',
//     dataIndex: 'date',
//   },
//   {
//     title: 'Action',
//     dataIndex: 'action',
//   },
// ];
//
// const dummyInvoiceData = [
//   {
//     key: 1,
//     invoice: 'January 2019',
//     date: '2019.02.01',
//     action: <a href="/downloads">Download</a>,
//   },
//   {
//     key: 2,
//     invoice: 'December 2018',
//     date: '2019.01.01',
//     action: <a href="/downloads">Download</a>,
//   },
//   {
//     key: 3,
//     invoice: 'November 2018',
//     date: '2018.12.01',
//     action: <a href="/downloads">Download</a>,
//   },
//   {
//     key: 4,
//     invoice: 'December 2018',
//     date: '2018.11.01',
//     action: <a href="/downloads">Download</a>,
//   },
// ];
//
// const rowSelection = {
//   onChange: (selectedRowKeys: any, selectedRows: any) => {
//     console.log(
//       `selectedRowKeys: ${selectedRowKeys}`,
//       'selectedRows: ',
//       selectedRows,
//     );
//   },
//   onSelect: (record: any, selected: any, selectedRows: any) => {
//     console.log(record, selected, selectedRows);
//   },
//   onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
//     console.log(selected, selectedRows, changeRows);
//   },
// };
