import React from 'react';
import { useSelector, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import SingleCenteredContentPage from '../singleCenteredContentPage/SingleCenteredContentPage';
import ResetPassword from '../../components/create-password/ResetPassword';
import BasicCardTitleOnly from '../../components/basic-card/BasicCardTitleOnly';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface DispatchProps {
  user: UserState;
}

const PasswordPage: React.FC<RouteComponentProps & DispatchProps> = () => {
  const userStore = useSelector((state: AppState) => state.user);
  const { tenantName, inviterName } = LocalStorageService.getInviteData();

  return (
    <SingleCenteredContentPage>
      {tenantName ? (
        <BasicCardTitleOnly
          title={`You were invited to join the ${tenantName} account${
            inviterName ? ` by ${inviterName}` : ''
          }`}
          marginBottom="30px"
        />
      ) : null}
      <BasicCardTitleOnly
        title={userStore.error && userStore.error.message}
        iconName="exclamation-circle"
        iconColor="red"
        marginBottom="30px"
        visible
      />
      <ResetPassword />
    </SingleCenteredContentPage>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(PasswordPage) as React.ComponentType;
