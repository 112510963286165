import { LooseObj } from '../../custom-types';

const ACTIVE_ACCOUNT_KEY = 'stage.activeAccountId';
const INVITE_DATA = 'stage.temporary.inviteData';

class LocalStorageService {
  static getActiveAccount(accountsProfiles: string[]): string {
    const tenantIds = Object.keys(accountsProfiles);
    let activeAccountId = localStorage.getItem(ACTIVE_ACCOUNT_KEY);

    if (activeAccountId && !tenantIds.includes(activeAccountId)) {
      activeAccountId = null;
    }

    if (!activeAccountId) {
      [activeAccountId] = tenantIds;
      LocalStorageService.setActiveAccount(activeAccountId);
    }

    return activeAccountId;
  }

  static setActiveAccount(tenantId: string): void {
    localStorage.setItem(ACTIVE_ACCOUNT_KEY, tenantId);
  }

  static clearActiveAccount() {
    localStorage.removeItem(ACTIVE_ACCOUNT_KEY);
  }

  static setInviteData(inviteData: LooseObj): void {
    localStorage.setItem(INVITE_DATA, JSON.stringify(inviteData));
  }

  static getInviteData(): LooseObj {
    const inviteDataString = localStorage.getItem(INVITE_DATA);
    if (inviteDataString) {
      const inviteData = JSON.parse(inviteDataString);
      return inviteData;
    }

    return {};
  }

  static clearInviteData(): void {
    localStorage.removeItem(INVITE_DATA);
  }
}

export default LocalStorageService;
