import React from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../store';
import { UserState } from '../../store/user/types';
import SingleCenteredContentPage from '../singleCenteredContentPage/SingleCenteredContentPage';
import DisplayNameSetup from '../../components/display-name-setup/DisplayNameSetup';
import BasicCardTitleOnly from '../../components/basic-card/BasicCardTitleOnly';
import { LooseObj } from '../../custom-types';
import LocalStorageService from '../../services/local-storge/LocalStorageService';

interface DispatchProps {
  user: UserState;
}

const getErrorMessage = (error?: LooseObj) => error && (error.message ? error.message : 'Something went wrong');

const DisplayNamePage: React.FC<RouteComponentProps & DispatchProps> = () => {
  const userStore = useSelector((state: AppState) => state.user);
  const { tenantName, inviterName } = LocalStorageService.getInviteData();

  return (
    <SingleCenteredContentPage>
      {tenantName ? (
        <BasicCardTitleOnly
          title={`You were invited to join the ${tenantName} account${
            inviterName ? ` by ${inviterName}` : ''
          }`}
          marginBottom="30px"
        />
      ) : null}
      <BasicCardTitleOnly
        title={getErrorMessage(userStore.error)}
        iconName="exclamation-circle"
        iconColor="red"
        marginBottom="30px"
        visible
      />
      <DisplayNameSetup />
    </SingleCenteredContentPage>
  );
};

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(DisplayNamePage);
