import React, { FormEvent, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Form,
  Input,
  Popover,
  Icon,
  Select,
  Button,
  notification,
  Spin,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { range } from 'lodash';
import uuid from 'uuid/v4';
import { styled } from '../../theme/Theme';
import { AppState } from '../../store';
import { putAccountDetails } from '../../store/account/action';
import { notifyPlanChange } from '../../store/billing/action';
import { LooseObj } from '../../custom-types';
import { BILLING_PLANS } from '../../app/constants';
import { AccountState } from '../../store/account/types';
import UserService from '../../services/user/UserService';

const vatPercent = 19;

const { Option, OptGroup } = Select;
const plansConstantKeys = ['STARTER', 'PROFESSIONAL', 'ENTERPRISE'];

const billingNameInfo = 'Billing name';
const streetNumberInfo = 'Street Number';
const streetInfo = 'Street name';
const postalCodeInfo = 'Postal Code';
const cityInfo = 'City';
const countryInfo = 'Country';
const vatIdInfo = 'VAT ID';

const getVat = (pricePerHost: number) => (pricePerHost * 19) / 100;

const getTotalPrice = (
  vat: number,
  pricePerHost: number,
  numberOfUsers: number,
) => (vat + pricePerHost) * numberOfUsers;

const updatePricingDetals = (
  commitment: string,
  numberOfUsers: number,
  planData: LooseObj,
  addVat: boolean,
  setPricingDetails: Function,
) => {
  const multiplyIfYearly = commitment === 'yearly' ? 12 : 1;
  const newPricePerHost = commitment === 'yearly'
    ? planData.priceWithYearly
    : planData.priceWithMonthly;
  const newVat = addVat ? getVat(newPricePerHost) : 0;
  const newTotalPrice = (
    getTotalPrice(newVat, newPricePerHost, numberOfUsers) * multiplyIfYearly
  ).toFixed(2);

  setPricingDetails({
    pricePerHost: newPricePerHost,
    vat: newVat,
    totalPrice: newTotalPrice,
  });
};

interface DispatchProps {
  account: AccountState;
  onUpdateAccount: Function;
  onNotifyPlanChange: (
    operation: 'cancel' | 'upgrade' | 'pay-with-invoice',
    data: LooseObj,
    cb: Function
  ) => void;
}

interface Props extends RouteComponentProps, FormComponentProps, DispatchProps {
  className?: string;
}

const BillingSummaryPage: React.FC<Props> = ({
  className,
  history,
  form,
  account,
  onUpdateAccount,
  onNotifyPlanChange,
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
    getFieldValue,
  } = form;

  const { accountInfo } = account.activeAccount;

  const [
    countriesNamesRequiringVatId,
    setCountriesNamesRequiringVatId,
  ] = useState<string[]>([]);
  const [
    countriesNamesRequiringVatAmount,
    setCountriesNamesRequiringVatAmount,
  ] = useState<string[]>([]);
  const [allCountries, setAllCountries] = useState<LooseObj[]>([]);

  const getCountries = async () => {
    const {
      success,
      countriesRequiringVatId,
      countriesRequiringVatAmount,
      allCountries: allCountriesSource,
    } = await UserService.getCountries();

    if (success) {
      setCountriesNamesRequiringVatId(
        countriesRequiringVatId.map(({ name }: LooseObj) => name),
      );
      setCountriesNamesRequiringVatAmount(
        countriesRequiringVatAmount.map(({ name }: LooseObj) => name),
      );
      setAllCountries(allCountriesSource);
    }
  };

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const numberOfUsers = getFieldValue('users');
    const companyOrBillingCountry = companyOrBilling.concat('Country');
    const doesRequireVatAmount = countriesNamesRequiringVatAmount.includes(
      accountInfo[companyOrBillingCountry],
    );

    setAddVatAmount(doesRequireVatAmount);
    setHideVatId(
      !countriesNamesRequiringVatId.includes(
        accountInfo[companyOrBillingCountry],
      ),
    );

    if (doesRequireVatAmount) {
      updatePricingDetals(
        commitment,
        numberOfUsers,
        plan,
        doesRequireVatAmount,
        setPricingDetails,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesNamesRequiringVatId, countriesNamesRequiringVatAmount]);

  const companyOrBilling = accountInfo.billingSameAsCompany
    ? 'company'
    : 'billing';
  const companyOrBillingCountry = companyOrBilling.concat('Country');

  const [plan, setPlan] = useState<LooseObj>(history.location.state.plan);
  const [commitment, setCommitment] = useState(plan.commitment);
  const [additionalNrOfUsers, setAdditionalNrOfUsers] = useState<number[]>([]);
  const multiplyIfYearly = commitment === 'yearly' ? 12 : 1;

  const [disablePayWithInvoice, setDisablePayWithInvoice] = useState(
    commitment === 'monthly',
  );
  const [hideVatId, setHideVatId] = useState(
    !countriesNamesRequiringVatId.includes(accountInfo[companyOrBillingCountry]),
  );

  const [addVatAmount, setAddVatAmount] = useState(
    countriesNamesRequiringVatAmount.includes(
      accountInfo[companyOrBillingCountry],
    ),
  );
  const features = [plan.solutions.join(', '), plan.customerSupport.join(', ')];
  const initialNumberOfUsers = history.location.state.plan.minHostUsers;
  /* commitment === 'yearly' ? plan.priceWithYearly : plan.priceWithMonthly */
  const initialPricePerHost = plan.priceWithYearly;
  const initialVat = 0;
  const initialTotalPrice = (
    (initialVat + initialPricePerHost)
    * initialNumberOfUsers
    * multiplyIfYearly
  ).toFixed(2);

  const [pricingDetails, setPricingDetails] = useState<LooseObj>({
    pricePerHost: initialPricePerHost,
    vat: initialVat,
    totalPrice: initialTotalPrice,
  });

  const handleCommitmentChange = (commitmentFieldValue: string) => {
    const numberOfUsers = getFieldValue('users');

    setCommitment(commitmentFieldValue);
    setDisablePayWithInvoice(commitmentFieldValue === 'monthly');
    updatePricingDetals(
      commitmentFieldValue,
      numberOfUsers,
      plan,
      addVatAmount,
      setPricingDetails,
    );
  };

  const handleNrUsersChange = (selectedNumberOfUsers: number) => {
    let newPlan;

    if (selectedNumberOfUsers <= BILLING_PLANS.STARTER.maxHostUsers) {
      newPlan = BILLING_PLANS.STARTER;
    } else if (
      selectedNumberOfUsers <= BILLING_PLANS.PROFESSIONAL.maxHostUsers
    ) {
      newPlan = BILLING_PLANS.PROFESSIONAL;
    } else {
      newPlan = BILLING_PLANS.ENTERPRISE;
    }

    setPlan(newPlan);
    updatePricingDetals(
      commitment,
      selectedNumberOfUsers,
      newPlan,
      addVatAmount,
      setPricingDetails,
    );
  };

  const onNrUsersSearch = (val: any) => {
    const convertedValue = Number(val);

    if (isNaN(convertedValue)) {
      return;
    }

    if (
      convertedValue > BILLING_PLANS.ENTERPRISE.minHostUsers
      && !additionalNrOfUsers.includes(convertedValue)
    ) {
      setAdditionalNrOfUsers(additionalNrOfUsers.concat([convertedValue]));
    }
  };

  const onCountryChange = (countryName: string) => {
    const numberOfUsers = getFieldValue('users');
    const newAddVatAmount = countriesNamesRequiringVatAmount.includes(
      countryName,
    );

    setAddVatAmount(newAddVatAmount);
    setHideVatId(!countriesNamesRequiringVatId.includes(countryName));
    updatePricingDetals(
      commitment,
      numberOfUsers,
      plan,
      newAddVatAmount,
      setPricingDetails,
    );
  };

  const onPayWithInvoice = (event: any) => {
    onSubmit(event, 'invoice');
  };

  const onSubmit = (
    event: FormEvent<HTMLFormElement>,
    paymentType: 'card' | 'invoice',
  ) => {
    event.preventDefault();
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [
      users,
      companyName,
      street,
      streetNr,
      postalCode,
      city,
      country,
      vatId,
    ] = Object.values(
      getFieldsValue([
        'users',
        'company-name',
        'street',
        'street-number',
        'postal-code',
        'city',
        'country',
        'vat-id',
      ]),
    );

    const planName = commitment === 'yearly' ? plan.name.concat('_yearly') : plan.name;
    const data = {
      id: account.activeAccount.tenantInfo.tenant_id,
      companyName,
      billingCity: city,
      billingCountry: country,
      billingPostalCode: postalCode,
      billingStreet: street,
      billingStreetNr: streetNr,
      vatId,
      vatPercent: 19,
    };

    if (paymentType === 'card') {
      history.push('/billing', {
        ...data,
        numberOfUsers: users,
        totalPrice: pricingDetails.totalPrice,
        hideVatId,
        hideVatPercent: !addVatAmount,
        planName,
        commitment,
        planDetails: { ...plan },
      });
    } else {
      onNotifyPlanChange(
        'pay-with-invoice',
        {
          billingAddress: {
            street,
            streetNr,
            postalCode,
            city,
            country,
          },
          desiredPlan: {
            name: planName,
            nrHosts: users,
          },
          totalPrice: pricingDetails.totalPrice,
        },
        () => {
          history.push('/');
          notification.open({
            message: 'Thanks for your interest!',
            description:
              "We will process your request within one working day.\nYou will receive a notification when it's done",
            icon: <Icon type="info-circle" style={{ color: '76B82A' }} />,
            duration: 0,
          });
        },
      );
    }

    onUpdateAccount(data, { showFeedback: false });
  };

  return (
    <div className={className}>
      <div className="intro-text">
        <h2>Purchase a STAGE license</h2>
        <p>
          The trial period for this account has ended.
          <br />
          You must activate a license plan to continue using STAGE.
        </p>
      </div>

      <div className="order">
        <div className="order-header">
          <h4>Your order</h4>
          <h3>{`${plan.name} STAGE license`}</h3>
          <p style={{ fontWeight: 'bold' }}>
            {`up to ${plan.participants} participants per session; 
           ${plan.storageSize / plan.minHostUsers}${
              plan.storageMetric
            } per host user; 
           ${pricingDetails.pricePerHost} ${plan.currency} per host user`}
          </p>
          <p>{`${features}`}</p>
        </div>

        <Form onSubmit={(e) => onSubmit(e, 'card')} colon={false}>
          <div className="order-content">
            <div className="col-1">
              <h4>Summary</h4>
              <div className="space-between">
                <div>
                  <Form.Item label="Number of Hosts" className="users-input">
                    {getFieldDecorator('users', {
                      rules: [
                        {
                          required: true,
                          type: 'number',
                        },
                      ],
                      initialValue: plan.minHostUsers,
                    })(
                      <Select
                        showSearch
                        onSearch={onNrUsersSearch}
                        onChange={handleNrUsersChange}
                      >
                        {plansConstantKeys.map((planName: string) => {
                          const {
                            name,
                            minHostUsers,
                            maxHostUsers,
                          } = BILLING_PLANS[planName];
                          const upperUserLimit = maxHostUsers
                            ? maxHostUsers + 1
                            : minHostUsers + 1;
                          let numbersToDisplay;

                          if (name === BILLING_PLANS.ENTERPRISE.name) {
                            numbersToDisplay = [
                              ...range(minHostUsers, upperUserLimit),
                              ...additionalNrOfUsers,
                            ].sort();
                          } else {
                            numbersToDisplay = range(
                              minHostUsers,
                              upperUserLimit,
                            );
                          }

                          return (
                            <OptGroup key={uuid()} label={name}>
                              {numbersToDisplay.map((nr) => (
                                <Option key={uuid()} value={nr}>
                                  {nr}
                                </Option>
                              ))}
                            </OptGroup>
                          );
                        })}
                      </Select>,
                    )}
                  </Form.Item>

                  <Form.Item label="Commitment">
                    {getFieldDecorator('commitment', {
                      initialValue: commitment,
                    })(
                      <Select onChange={handleCommitmentChange}>
                        <Select.Option value="monthly">
                          Monthly payment
                        </Select.Option>
                        <Select.Option value="yearly">
                          Yearly payment
                        </Select.Option>
                      </Select>,
                    )}
                  </Form.Item>
                </div>

                <Spin spinning={countriesNamesRequiringVatAmount.length === 0}>
                  <div>
                    <div className="price-calc">
                      <div className="left">Subtotal</div>
                      <div className="right">
                        <span>
                          {[pricingDetails.pricePerHost, plan.currency].join(
                            ' ',
                          )}
                        </span>
                        <span>/ host / mo.</span>
                      </div>
                    </div>

                    <div className="price-calc" hidden={!addVatAmount}>
                      <div className="left">
                        VAT (
                        {vatPercent}
                        %)
                      </div>
                      <div className="right">
                        <span>
                          {[pricingDetails.vat, plan.currency].join(' ')}
                        </span>
                        <span>/ host / mo.</span>
                      </div>
                    </div>

                    <hr />

                    <div className="price-calc-big">
                      <span>Total</span>
                      <span>
                        {[pricingDetails.totalPrice, plan.currency].join(' ')}
                      </span>
                    </div>
                    <span className="price-calc-big-to-right">
                      {commitment}
                    </span>
                  </div>
                </Spin>
              </div>
            </div>

            <div className="col-2">
              <div className="upper">
                <h4>Billing information</h4>

                <Form.Item label="Company Name">
                  {getFieldDecorator('company-name', {
                    rules: [
                      {
                        required: true,
                        message: 'Please provide the Company Name',
                      },
                    ],
                    initialValue: accountInfo.companyName,
                  })(<Input placeholder="Company Name" />)}

                  <Popover content={billingNameInfo} placement="right">
                    <span className="hint">
                      <Icon type="info-circle" />
                    </span>
                  </Popover>
                </Form.Item>

                <h4>Billing Address</h4>
              </div>

              <div className="lower">
                <div>
                  <Form.Item label="Street">
                    {getFieldDecorator('street', {
                      rules: [
                        {
                          required: true,
                          message: 'Please provide the Street',
                        },
                      ],
                      initialValue:
                        accountInfo[companyOrBilling.concat('Street')],
                    })(<Input placeholder="Street" />)}

                    <Popover content={streetInfo} placement="right">
                      <span className="hint">
                        <Icon type="info-circle" />
                      </span>
                    </Popover>
                  </Form.Item>

                  <Form.Item label="Street Number">
                    {getFieldDecorator('street-number', {
                      rules: [
                        {
                          required: true,
                          message: 'Please provide the Street Number',
                        },
                      ],
                      initialValue:
                        accountInfo[companyOrBilling.concat('StreetNr')],
                    })(<Input placeholder="Street Number" />)}

                    <Popover content={streetNumberInfo} placement="right">
                      <span className="hint">
                        <Icon type="info-circle" />
                      </span>
                    </Popover>
                  </Form.Item>

                  <Form.Item label="Postal Code">
                    {getFieldDecorator('postal-code', {
                      rules: [
                        {
                          required: true,
                          message: 'Please provide the Postal Code',
                        },
                      ],
                      initialValue:
                        accountInfo[companyOrBilling.concat('PostalCode')],
                    })(<Input placeholder="Postal Code" />)}

                    <Popover content={postalCodeInfo} placement="right">
                      <span className="hint">
                        <Icon type="info-circle" />
                      </span>
                    </Popover>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item label="City">
                    {getFieldDecorator('city', {
                      rules: [
                        {
                          required: true,
                          message: 'Please provide the City',
                        },
                      ],
                      initialValue: accountInfo[companyOrBilling.concat('City')],
                    })(<Input placeholder="City" />)}

                    <Popover content={cityInfo} placement="right">
                      <span className="hint">
                        <Icon type="info-circle" />
                      </span>
                    </Popover>
                  </Form.Item>

                  <Form.Item label="Country">
                    {getFieldDecorator('country', {
                      rules: [
                        {
                          required: true,
                          message: 'Please provide the Country',
                        },
                      ],
                      initialValue: accountInfo[companyOrBillingCountry],
                    })(
                      <Select
                        showSearch
                        placeholder="Country"
                        optionFilterProp="children"
                        onChange={onCountryChange}
                      >
                        {allCountries.map(({ code, name }) => (
                          <Option key={code} value={name}>
                            {name}
                          </Option>
                        ))}
                      </Select>,
                    )}

                    <Popover content={countryInfo} placement="right">
                      <span className="hint">
                        <Icon type="info-circle" />
                      </span>
                    </Popover>
                  </Form.Item>

                  {hideVatId ? null : (
                    <Form.Item label="VAT ID">
                      {getFieldDecorator('vat-id', {
                        rules: [
                          {
                            required: true,
                            message: 'Please provide the VAT ID',
                          },
                        ],
                        initialValue: accountInfo.vatId,
                      })(<Input placeholder="VAT ID" />)}

                      <Popover content={vatIdInfo} placement="right">
                        <span className="hint">
                          <Icon type="info-circle" />
                        </span>
                      </Popover>
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="buttons-container">
            <Button className="back-button" onClick={() => history.goBack()}>
              Back
            </Button>
            <div className="pay-buttons">
              <Button
                type="primary"
                className="submit-button little-margin-right pay-with-invoice-button"
                onClick={onPayWithInvoice}
                disabled={disablePayWithInvoice}
              >
                Pay with invoice *
              </Button>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                >
                  Pay with card
                </Button>
              </Form.Item>
            </div>
          </div>

          <p className="invoice-help-text">
            * Invoice payment available only for yearly plans
          </p>
        </Form>
      </div>
    </div>
  );
};

const StyledBillingSummaryPage = styled(BillingSummaryPage)`
  .intro-text {
    h2 {
      color: ${({ theme }) => theme.color.primary};
      font-size: 24px;
      font-weight: bold;
      margin-bottom: ${({ theme }) => theme.space.lg};
    }

    p {
      font-size: 14px;
    }
  }

  .order {
    &-header {
      padding: ${({ theme }) => theme.space.lg} ${({ theme }) => theme.space.xl};
      background-color: ${({ theme }) => theme.borderColor};
      border-radius: 5px;
      ${({ theme }) => theme.shadow};

      h4 {
        font-weight: bold;
        font-size: 1.2rem;
      }

      h3 {
        font-size: ${({ theme }) => theme.font.size.xl};
        &::first-letter {
          text-transform: capitalize;
        }
      }

      p {
        margin: 0;
      }
    }

    .ant-form {
      padding: 40px;
      background-color: ${({ theme }) => theme.color.white};
      ${({ theme }) => theme.shadow};

      .ant-form-item-required::before {
        display: none;
      }

      .order-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h4 {
          font-size: 20px;
          font-weight: bold;
        }

        .col-1 {
          width: 30%;

          .space-between {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 80%;
          }

          .ant-input,
          .ant-select {
            width: 225px;
          }

          .users-input {
            height: 120px;
            margin-bottom: 5px;
          }

          .price-calc {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
              color: ${({ theme }) => theme.color.grey};
            }

            .right {
              span:first-of-type {
                font-size: 1.2rem;
                text-align: right;
                margin-right: 10px;
              }

              span:nth-of-type(2) {
                color: ${({ theme }) => theme.color.grey};
              }
            }
          }

          .price-calc-big {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            span {
              display: inline-block;

              &:nth-of-type(2) {
                font-size: 1.6rem;
              }
            }
          }

          .price-calc-big-to-right {
            display: block;
            width: 100px;
            text-align: right;
            margin-left: auto;
          }
        }

        .col-2 {
          width: 60%;

          .ant-form-item-children {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 300px;

            .ant-input,
            .ant-select {
              margin-right: ${({ theme }) => theme.space.md};
              border-radius: 5px;
            }

            .hint {
              display: inline-block;
              font-size: 1.1rem;
              color: ${({ theme }) => theme.color.dGrey};
              cursor: pointer;
            }
          }

          .lower {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 40px;
        margin-top: ${({ theme }) => theme.space.xl};

        button {
          height: 40px;
          font-weight: bold;
        }

        .submit-button {
          width: 180px;

          span {
            color: ${({ theme }) => theme.color.white};
          }
        }

        .back-button {
          background-color: transparent;
          width: 80px;
          border: 3px solid ${({ theme }) => theme.color.primary};
        }

        span {
          color: ${({ theme }) => theme.color.primary};
        }
      }
    }
  }

  .pay-buttons {
    display: flex;
    flex-direction: row;

    .pay-with-invoice-button:disabled {
      & > span {
        color: ${({ theme }) => theme.color.lGreyDark} !important;
      }
    }
  }

  .little-margin-right {
    margin-right: 16px;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.color.lGrey};
    margin: 5px;
    padding: 0;
  }

  .invoice-help-text {
    display: block;
    text-align: right;
    margin-top: ${({ theme }) => theme.space.md};
    color: ${({ theme }) => theme.color.dGreyLight};
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

const mapDispatchToProps = {
  onUpdateAccount: putAccountDetails,
  onNotifyPlanChange: notifyPlanChange,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouter,
  Form.create({ name: 'billing-info' }),
)(StyledBillingSummaryPage) as React.ComponentType;
