import React, { FormEvent, useState } from 'react';
import {
  Form, Select, InputNumber, DatePicker, Button, message,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import uuid from 'uuid/v4';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { BILLING_PLANS } from '../../app/constants';
import SingleCenteredContentPage from '../../containers/singleCenteredContentPage/SingleCenteredContentPage';
import { styled } from '../../theme/Theme';
import TenantService from '../../services/tenant/TenantService';
import BillingService from '../../services/billing/BillingService';
import { LooseObj } from '../../custom-types';

interface Props extends FormComponentProps {
  className?: string;
  tenantInfo: LooseObj;
  updateMaxCreators?: (newMaxCreators: number) => void;
}

const LICENSES = {
  CUSTOM: { name: 'custom' },
  TRIAL: { ...BILLING_PLANS.STARTER, name: 'trial' },
  ...BILLING_PLANS,
};

const dateFormat = 'YYYY-MM-DD';

const EditAccountForm: React.FC<Props> = ({
  className,
  form,
  tenantInfo,
  updateMaxCreators,
}) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
  } = form;

  const [dbProcessing, setDbProcessing] = useState(false);

  const tenantId = tenantInfo && tenantInfo.tenant_id;
  const initialNrHosts = tenantInfo && tenantInfo.max_creators;
  const initialMaxStorage = tenantInfo && tenantInfo.max_storage;
  const initialMaxUsersPerSession = tenantInfo && tenantInfo.max_users_per_session;
  const initialLicensedUntil = tenantInfo
    && tenantInfo.licensed_until > 0
    && moment(tenantInfo.licensed_until);
  const initialLicense = tenantInfo && tenantInfo.braintreePlanName;

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );
    if (errorsCount.length > 0) {
      return;
    }
    const [
      license,
      newMaxCreators,
      newMaxStorage,
      newMaxUsersPerSession,
      expirationDate,
    ] = Object.values(
      getFieldsValue([
        'license',
        'nr-hosts',
        'storage',
        'users-per-session',
        'expiration-date',
      ]),
    );

    const braintreeSubscriptionEnd = expirationDate
      ? expirationDate.format(dateFormat)
      : undefined;
    const newLicensedUntil = expirationDate
      ? expirationDate.valueOf()
      : undefined;

    const formattedInitialLicensedUntil = initialLicensedUntil
      ? initialLicensedUntil.format(dateFormat)
      : undefined;
    if (
      initialLicense !== license
      || braintreeSubscriptionEnd !== formattedInitialLicensedUntil
    ) {
      setDbProcessing(true);
      BillingService.putAccountDetails({
        id: tenantId,
        braintreePlanName: license,
        braintreeSubscriptionEnd,
      }).then((resp) => {
        if (resp) {
          message.success(
            'Succesfully updated license name and subscription end date.',
          );
        } else {
          message.error(
            'Error updating license name and subscription end date.',
          );
        }
        setDbProcessing(false);
      });
    }

    const changedValues: LooseObj = {};
    if (newMaxCreators !== initialNrHosts) {
      changedValues.newMaxCreators = newMaxCreators;
    }
    if (newMaxStorage !== initialMaxStorage) {
      changedValues.newMaxStorage = newMaxStorage;
    }
    if (newMaxUsersPerSession !== initialMaxUsersPerSession) {
      changedValues.newMaxUsersPerSession = newMaxUsersPerSession;
    }
    if (newLicensedUntil !== initialLicensedUntil.valueOf()) {
      changedValues.newLicensedUntil = newLicensedUntil;
    }

    if (!isEmpty(changedValues)) {
      TenantService.modifyTenant({
        tenantId,
        ...changedValues,
      }).then((resp) => {
        if (resp) {
          message.success('Succesfully updated license details.');
          updateMaxCreators && updateMaxCreators(newMaxCreators);
        } else {
          message.error('Error updating license details.');
        }
      });
    }
  };

  return (
    <SingleCenteredContentPage>
      <div className={className}>
        <Form onSubmit={onSubmit}>
          <Form.Item label="License">
            {getFieldDecorator('license', {
              initialValue: initialLicense,
            })(
              <Select>
                {Object.values(LICENSES).map((type) => (
                  <Select.Option value={type.name} key={uuid()}>
                    {type.name}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label="Max number of hosts">
            {getFieldDecorator('nr-hosts', {
              initialValue: initialNrHosts,
            })(<InputNumber min={1} />)}
          </Form.Item>

          <Form.Item label="Storage limit (GB)">
            {getFieldDecorator('storage', {
              initialValue: initialMaxStorage,
            })(<InputNumber min={1} />)}
          </Form.Item>

          <Form.Item label="Max users per session">
            {getFieldDecorator('users-per-session', {
              initialValue: initialMaxUsersPerSession,
            })(<InputNumber min={1} />)}
          </Form.Item>

          <Form.Item label="License expiration date">
            {getFieldDecorator('expiration-date', {
              initialValue: initialLicensedUntil,
            })(<DatePicker format={dateFormat} />)}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              loading={dbProcessing}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </SingleCenteredContentPage>
  );
};

const StyledEditAccountForm = styled(EditAccountForm)`
  width: 100%;

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

export default Form.create({ name: 'edit-account' })(
  StyledEditAccountForm,
) as React.ElementType;
