import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { Card, Form, Icon } from 'antd';
import { connect } from 'react-redux';
import { styled } from '../../theme/Theme';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import { UserState } from '../../store/user/types';

interface DispatchProps {
  accountState: AccountState;
  user: UserState;
}

interface Props extends DispatchProps {
  className?: string;
}

const Meeting: React.FC<Props> = ({ className, accountState, user }) => {
  const title = 'Meetings';
  const body = `Join directly from your browser for an online 
  meeting or you can download the STAGE client from`;

  const [streamingUrl, setStreamingUrl] = useState('');
  const [loadingStreamingUrl, setLoadingStreamingUrl] = useState(true);
  const [streamingUrlMsg, setStreamingUrlMsg] = useState('');

  const { email } = user.identityInfo;
  const { companyName } = accountState.activeAccount.accountInfo;

  const params = {
    email,
    account: companyName,
  };

  useEffect(() => {
    axios
      .post(
        'https://b6vlacsrw7.execute-api.eu-central-1.amazonaws.com/prod/api/user/getStreamingUrl',
        null,
        { params },
      )
      .then((resp) => {
        const data = JSON.parse(resp.data);
        if (data.success) {
          setStreamingUrl(data.streaming_url);
          setStreamingUrlMsg('');
        } else {
          setStreamingUrlMsg(data.message);
          setStreamingUrl('');
        }
      })
      .finally(() => {
        setLoadingStreamingUrl(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.account]);

  const meetingUrlClickHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.open(streamingUrl, '_blank').focus();
  };

  return (
    <Card
      className={className}
      bordered={false}
      title={(
        <span className="icon-and-text">
          <Icon type="info-circle" className="positive" />
          {' '}
          {title}
        </span>
      )}
    >
      <span className="card-body-text">
        {body}
        &nbsp;
        <Link
          to={{ pathname: 'https://www.vr-on.com/download-client.html' }}
          target="_blank"
        >
          here
        </Link>
        .
      </span>
      {loadingStreamingUrl ? null : (
        <span>
          <button
            type="button"
            disabled={streamingUrl === ''}
            onClick={meetingUrlClickHandler}
            className="btn-meeting"
          >
            Online Meetings
          </button>
          {streamingUrlMsg !== '' ? <p style={{ marginTop: '10px' }}>{streamingUrlMsg}</p> : null}
        </span>
      )}
    </Card>
  );
};

const StyledMeeting = styled(Meeting)`
  .ant-card-head {
    border: none;

    .ant-card-head-title {
      display: flex;
      align-items: center;

      .icon-and-text {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;

        svg {
          font-size: ${({ theme }) => theme.font.size.lg};
          margin-right: ${({ theme }) => theme.space.lg};
        }
      }

      .positive {
        svg {
          fill: ${({ theme }) => theme.color.primary};
        }
      }

      .negative {
        svg {
          fill: ${({ theme }) => theme.color.red};
        }
      }
    }
  }

  .ant-card-body {
    padding-top: 0;
    margin-left: 44px;
    display: flex;
    flex-direction: column;

    .card-body-text {
      display: inline-block;
    }

    .btn-meeting {
      display: inline-block;
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.primary};
      padding: ${({ theme }) => theme.space.md} ${({ theme }) => theme.space.lg};
      border-radius: ${({ theme }) => theme.space.sm};
      font-weight: bold;
      font-size: 1rem;
      border: 0;
      cursor: pointer;
      margin-top: ${({ theme }) => theme.space.xl};
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  accountState: state.account,
  user: state.user,
});

export default connect(mapStateToProps)(StyledMeeting);
