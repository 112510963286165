import React, { FormEvent, useState } from 'react';
import {
  Form, Input, Button, message,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { changePassword } from '../../store/user/action';
import {
  passwordValidator,
  passwordRequirements,
} from '../../utils/PasswordUtils';
import { LooseObj } from '../../custom-types';

interface DispatchProps {
  onChangePassword: (
    oldPassword: string,
    newPassword: string,
    cb: (resp: any) => void
  ) => any;
}

type Props = FormComponentProps & DispatchProps;

const ChangePasswordForm: React.FC<Props> = ({ form, onChangePassword }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
    getFieldsValue,
    resetFields,
  } = form;
  const requirements: LooseObj = passwordRequirements;
  const [passwordValidation, setPasswordValidation] = useState<LooseObj>({});

  const updateRequirements = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setPasswordValidation(passwordValidator(null, value, true));
  };

  const onSubmitPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    validateFields();

    const errorsCount = Object.entries(getFieldsError()).filter(
      (pair: Array<any>) => pair[1] !== undefined,
    );

    if (errorsCount.length > 0) {
      return;
    }

    const [oldPassword, newPassword] = Object.values(getFieldsValue());

    if (oldPassword === newPassword) {
      message.error('The new password must not be the same as the old one!');
    } else {
      onChangePassword(oldPassword, newPassword, onSuccess);
    }
  };

  const onSuccess = (resp: any) => {
    if (resp.success) {
      message.success('Update successful');
      resetFields();
    } else {
      const errorMessage = resp.message || 'Update failed';
      message.error(errorMessage);
    }
  };

  return (
    <Form onSubmit={onSubmitPassword} colon={false}>
      <Form.Item label="Current password">
        {getFieldDecorator('currentPassword', {
          rules: [
            {
              required: true,
              message: 'Please input the old password before submitting!',
            },
          ],
        })(<Input.Password placeholder="**********" />)}
      </Form.Item>

      <Form.Item label="New password">
        {getFieldDecorator('newPassword', {
          rules: [
            {
              required: true,
              message: 'Please input the new password before submitting!',
            },
          ],
        })(
          <Input.Password
            placeholder="**********"
            onChange={updateRequirements}
          />,
        )}
      </Form.Item>

      <div className="requirements">
        <h4>Your new password must contain:</h4>
        <ul>
          {Object.keys(requirements).map((key) => {
            const clsName = !passwordValidation[key] ? 'missing' : 'achieved';
            return (
              <li key={key} className={clsName}>
                {requirements[key]}
              </li>
            );
          })}
        </ul>
      </div>

      <Button type="primary" htmlType="submit" className="login-form-button">
        Change Password
      </Button>
    </Form>
  );
};

const mapDispatchToProps = {
  onChangePassword: changePassword,
};

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  Form.create({ name: 'change-password' }),
)(ChangePasswordForm) as React.ComponentType;
