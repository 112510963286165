import React from 'react';
import { Card, Icon } from 'antd';
import { styled } from '../../theme/Theme';

interface Props {
  className?: string;
  title: string | JSX.Element;
  iconName?: string;
  iconColor?: string;
  height?: string;
  marginBottom?: string;
  visible?: boolean;
}

const BasicCardTitleOnly: React.FC<Props> = ({
  className,
  title,
  iconName = 'info-circle',
}: Props) => (
  <Card
    className={className}
    title={(
      <span className="icon-and-text">
        <Icon type={iconName} />
        {title}
      </span>
    )}
  />
);

export default styled(BasicCardTitleOnly)`
  padding-right: 10px;

  && {
    ${({ height }) => height && `height: ${height};`}
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  }

  ${({ visible: dynamicVisibility, title }) => dynamicVisibility && !title && 'visibility: hidden;'}

  .ant-card-head {
    border: none;

    .ant-card-head-title {
      display: flex;
      align-items: center;

      .icon-and-text {
        display: inline-flex;
        align-items: center;
        font-size: 1rem;

        svg {
          fill: ${({ iconColor, theme }) => iconColor || theme.color.primary};
          font-size: ${({ theme }) => theme.font.size.lg};
          margin-right: ${({ theme }) => theme.space.lg};
        }
      }
    }
  }

  .ant-card-body {
    display: none;
  }
`;
