import { USER, UserState } from './types';
import { ActionResponse } from '../index';
import { LooseObj } from '../../custom-types';

const DEFAULT_STATE: UserState = {
  identityInfo: {},
  cognitoUser: undefined,

  processing: false,
  success: false,
  error: undefined,
  bootstrapping: true,
};

export default (state = DEFAULT_STATE, { type, payload }: ActionResponse) => {
  switch (type) {
    case USER.GET:
      return state;
    case USER.UPDATE:
      return state;
    case USER.PROCESSING:
      return {
        ...state,
        processing: true,
        error: undefined,
      };
    case USER.LOGIN:
      return {
        ...state,
        ...payload,
        error: undefined,
        processing: false,
        bootstrapping: false,
      };
    case USER.REGISTER:
      return {
        ...state,
        error: undefined,
        processing: false,
      };
    case USER.LOGOUT:
      return state;
    case USER.INVITE:
      return { ...state, processing: false, error: undefined };
    case USER.SET_DISPLAY_NAME:
      return {
        ...state,
        identityInfo: {
          ...state.identityInfo,
          invites: (state.identityInfo.invites || []).filter(
            (invite: LooseObj) => invite.tenantId !== payload,
          ),
        },
        processing: false,
        error: undefined,
      };
    case USER.UPDATE_IDENTITY:
      return {
        ...state,
        processing: false,
        error: undefined,
        identityInfo: {
          ...state.identityInfo,
          ...payload,
        },
      };
    case USER.UPDATE_USER_PROFILES:
      return {
        ...state,
        identityInfo: {
          ...state.identityInfo,
          user_profiles: [...state.identityInfo.user_profiles, payload],
        },
      };
    case USER.PUT_ID:
      return { ...state, processing: true };
    case USER.ERROR:
      return {
        ...state,
        processing: false,
        error: payload,
      };
    case USER.DECLINE_INVITE:
      return {
        ...state,
        identityInfo: {
          ...state.identityInfo,
          invites: (state.identityInfo.invites || []).filter(
            (invite: LooseObj) => invite.tenantId !== payload,
          ),
        },
        processing: false,
        error: undefined,
      };
    case USER.CLEAR:
      return {
        ...state,
        processing: false,
        error: undefined,
        bootstrapping: false,
      };
    default:
      return state;
  }
};
