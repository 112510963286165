import { ERROR_TYPES, LooseObj } from '../../custom-types';

export enum ACCOUNT {
  PROCESSING = 'ACCOUNT_PROCESSING',
  ERROR = 'ACCOUNT_ERROR',
  UPDATE_ACTIVE = 'ACCOUNT_UPDATE_ACTIVE',
  SETUP_PROFILES = 'ACCOUNT_SETUP_PROFILES',
  UPDATE_DETAILS = 'ACCOUNT_UPDATE_DETAILS',
  UPDATE_DETAILS_SUCCESS = 'ACCOUNT_UPDATE_DETAILS_SUCCESS',
  MODIFY_TENANT_NAME = 'ACCOUNT_MODIFY_TENANT_NAME',
  MODIFY_ACCOUNT = 'ACCOUNT_MODIFY_ACCOUNT',
  DELETE_USER = 'ACCOUNT_DELETE_USER',
  LEAVE_ACCOUNT = 'ACCOUNT_LEAVE_ACCOUNT',
  MODIFY_USER_ROLE = 'ACCOUNT_MODIFY_USER_ROLE',
  CLEAR = 'ACCOUNT_CLEAR'
}

export interface AccountState {
  activeAccount: LooseObj;
  accounts: LooseObj;

  processing: boolean;
  success: false;
  error:
    | {
        message: string;
        code: ERROR_TYPES.unknown;
      }
    | undefined;
  bootstrapping: boolean;
}
