import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Button, Modal, Icon, notification,
} from 'antd';
import { styled } from '../../theme/Theme';
import ProfileSettingsForm from './ProfileSettingsForm';
import ChangePasswordForm from './ChangePasswordForm';
import { AppState } from '../../store';
import { LooseObj } from '../../custom-types';
import BackButton from '../BackButton/BackButton';
import { notifyPlanChange } from '../../store/billing/action';

interface DispatchProps {
  user: LooseObj;
  onNotifyPlanChange: (
    operation: 'delete-identity-request',
    data: LooseObj,
    cb: Function
  ) => void;
}

interface Props extends DispatchProps {
  className?: string;
}

const ProfileSettings: React.FC<Props> = ({
  className,
  user,
  onNotifyPlanChange,
}) => {
  const { email } = user.identityInfo;

  const onDeleteIdentityClicked = () => {
    const { first_name: firstName, last_name: lastName } = user.identityInfo;
    const data = {
      requesterEmail: email,
      requesterFullName: `${firstName} ${lastName}`,
    };

    onNotifyPlanChange('delete-identity-request', data, () => {
      notification.open({
        message: 'Identity deletion request sent',
        description:
          'A member of our team will contact you shortly in order to finalize the operation.',
        icon: <Icon type="info-circle" style={{ color: '76B82A' }} />,
        duration: 0,
      });
    });
  };

  const showModal = () => {
    Modal.confirm({
      title: 'Are you sure?',
      content: (
        <p>
          This will delete all your user profiles, all the accounts that you are
          the owner of and all the users in said accounts.
        </p>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        onDeleteIdentityClicked();
      },
    });
  };

  return (
    <div className={className}>
      <div className="profile-settings">
        <div className="horizontal-container">
          <h2>Profile settings</h2>
        </div>
        <hr />
        <ProfileSettingsForm email={email} />
      </div>
      <div className="change-password">
        <h2>Change password</h2>
        <hr />
        <ChangePasswordForm />
      </div>
      <div className="delete-identity">
        <h2>Delete identity</h2>
        <hr />
        <div className="delete-identity-buttons">
          <Button onClick={() => showModal()} type="danger">
            Delete identity
          </Button>
          <BackButton />
        </div>
      </div>
    </div>
  );
};

const StyledProfileSettings = styled(ProfileSettings)`
  .ant-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 70%;

    .ant-form-item-children {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 350px;

      .ant-input {
        width: 300px;
        margin-right: ${({ theme }) => theme.space.md};
        border: 1px solid ${({ theme }) => theme.color.lGrey};
        border-radius: 5px;

        &-suffix {
          margin-right: 50px;
        }
      }

      .hint {
        display: inline-block;
        font-size: 1.1rem;
        color: ${({ theme }) => theme.color.dGrey};
        cursor: pointer;
      }
    }

    .ant-form-item-required::before {
      display: none;
    }

    button {
      font-weight: bold;
      margin: 0 50px 24px auto;
    }
  }

  .requirements {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    margin: 0 145px 0 0;

    & > ul {
      list-style: none;
      padding-left: 15px;

      .missing {
        color: ${({ theme }) => theme.color.redLight};
      }

      .achieved {
        color: ${({ theme }) => theme.color.primaryDark};
      }
    }
  }

  .button-and-suggestion {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 350px;

    & > p {
      margin-right: 50px;
      color: ${({ theme }) => theme.color.lGreyDark};
    }
  }

  .horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .delete-identity-buttons {
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.space.md};

    .ant-btn {
      width: 150px;
      margin-bottom: ${({ theme }) => theme.space.md};
    }
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.space.xs};
  }

  hr {
    border: none;
    border-top: 1px solid ${({ theme }) => theme.color.lGreyLight};
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.space.md};
  }

  .ant-modal-close-x {
    position: relative;

    .ant-modal-close-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const mapStateToProps = (state: AppState) => ({
  user: state.user,
});

const mapDispatchToProps = {
  onNotifyPlanChange: notifyPlanChange,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StyledProfileSettings) as React.ComponentType;
