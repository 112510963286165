import React from 'react';
import { Popover, Icon, message } from 'antd';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { styled } from '../../theme/Theme';
import { EXTERNAL_LINKS, ROLE, EMAIL } from '../../app/constants';
import { LooseObj } from '../../custom-types';
import { AppState } from '../../store';
import { AccountState } from '../../store/account/types';
import { inviteUsers } from '../../store/user/action';

const MyPopover = ({ className, ...props }: LooseObj) => (
  <Popover {...props} overlayClassName={className} />
);

interface DispatchProps {
  account: AccountState;
  onInviteUsers: Function;
}

interface Props extends DispatchProps {
  className?: string;
}

const Footer: React.FC<Props> = ({ className, account, onInviteUsers }) => {
  const inviteResponseHandler = (resp: LooseObj) => {
    if (resp.success) {
      message.success('Support has been invited into your account.');
    } else {
      const errorMessage = resp.message || 'Sending the invitation failed.';
      message.error(errorMessage);
    }
  };

  const onInviteSupportClicked = (e: React.MouseEvent) => {
    e.preventDefault();

    const { userInfo, tenantInfo } = account.activeAccount;
    const { tenant_id: accountId, tenant_name: accountName } = tenantInfo;
    const { full_name: fullName } = userInfo;

    const data = {
      accountId,
      accountName,
      fullName,
      invitations: [{ role: ROLE.ADMIN, email: EMAIL.SUPPORT }],
    };

    onInviteUsers(data, inviteResponseHandler);
  };

  return (
    <div className={className}>
      <div>
        <MyPopover
          className={className}
          placement="rightBottom"
          content={(
            <div className="help-links">
              <Link to="/onboarding">Onboarding</Link>
              <a
                href={EXTERNAL_LINKS.FAQ}
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </div>
          )}
        >
          <Icon
            type="question-circle"
            style={{ color: '#76B82A', fontSize: '30px' }}
          />
        </MyPopover>
      </div>

      <div className="horizontal-container">
        <a
          href={EXTERNAL_LINKS.PRIVACY_POLICY}
          target="_blank"
          rel="noopener noreferrer"
          className="grey-link"
        >
          Privacy policy
        </a>
        <span className="vertical-line" />
        <a
          href={EXTERNAL_LINKS.TERMS_OF_SERVICE}
          target="_blank"
          rel="noopener noreferrer"
          className="grey-link"
        >
          Terms of service
        </a>
        <span className="vertical-line" />
        <a
          href={EXTERNAL_LINKS.IMPRINT}
          target="_blank"
          rel="noopener noreferrer"
          className="grey-link"
        >
          Imprint
        </a>
        <span className="vertical-line" />
        <a
          href={EXTERNAL_LINKS.DATA_PROCESSING_AGREEMENT}
          target="_blank"
          rel="noopener noreferrer"
          className="grey-link"
        >
          Data processing agreement
        </a>
      </div>

      <MyPopover
        className={className}
        placement="leftBottom"
        content={(
          <div className="support-links">
            <a href="mailto:support@vr-on.com">Contact support via Email</a>
            <button type="button" onClick={onInviteSupportClicked}>
              Invite support to account
            </button>
          </div>
        )}
      >
        <p className="support-button">Support</p>
      </MyPopover>
    </div>
  );
};

const StyledFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    a {
      display: inline-block;
      color: ${({ theme }) => theme.color.grey};
    }

    .vertical-line {
      margin: 0 ${({ theme }) => theme.space.md};
      width: 1px;
      background-color: ${({ theme }) => theme.color.grey};
      height: 15px;
    }
  }

  .help-links {
    display: flex;
    flex-direction: column;

    a {
      text-transform: uppercase;
      padding: 5px 0;
    }
  }

  .support-links {
    display: flex;
    flex-direction: column;

    * {
      text-transform: uppercase;
      padding: 5px 0;
    }

    button {
      color: ${({ theme }) => theme.color.primary};
      text-decoration: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      border: none;

      &:hover {
        color: ${({ theme }) => lighten(0.03, theme.color.primary)};
      }
    }
  }

  i {
    display: inline-block;
  }

  svg {
    cursor: pointer;
  }

  .support-button {
    display: inline-block;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.color.primary};
    border-radius: 5px;
    color: ${({ theme }) => theme.color.primary};
    padding: 4px 8px;
    cursor: pointer;
  }
`;

const mapStateToProps = (state: AppState) => ({
  account: state.account,
});

const mapDispatchToProps = {
  onInviteUsers: inviteUsers,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(StyledFooter) as React.ComponentType;
